import { Checkbox, FormControlLabel, InputLabel, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { CustomCheckboxField, CustomListFieldV2, CustomTextField } from "../components/CustomTextField";
import FacilityTableSelector from "../components/fields/FacilityTableSelector";
import TestAutoComplete from "../components/fields/Tests";
import TestTableSelector from "../components/fields/TestTableSelector";
import PanelForm from "../components/PanelForm";
import { ColumnStack, RowStack } from "../components/Stacks";
import { Reflex, ReflexMethodAbnormal, ReflexMethodInconsistent, ReflexMethodNormal, Test } from "../constants/go_types";
import { useReflex, useCreateReflex, useUpdateReflex, useTests, useTestSubTests } from "../services/queries";
import reflexValidation, { reflexInitialValues } from "../validations/reflexes";

type ReflexPanelFormProps = {
	onBack: () => void;
	onSave: () => void;
	id?: number;
}

export function ReflexPanelForm({ id, onBack, onSave }: ReflexPanelFormProps) {
	const query = useReflex(id)

	return (
		<PanelForm
			validationSchema={reflexValidation}
			form={<ReflexForm />}
			createMutation={useCreateReflex()}
			updateMutation={useUpdateReflex()}
			initialValues={query.isSuccess ? query.data : reflexInitialValues}
			onBack={onBack}
			onSave={onSave}
		/>
	)
}

export default function ReflexForm() {
	const [forAllFacilities, setForAllFacilities] = useState(true)
	const { values, errors, setFieldValue } = useFormikContext<Reflex>()
	const tests = useTests()
	console.log('errors', errors)

	useEffect(() => {
		if (values?.facilities && values.facilities.length > 0) {
			setForAllFacilities(false)
		}
	}, [values.facilities])

	const is_panel = tests.data?.find((t: Test) => t.id === values?.source_test_id && t.is_panel)
	const subTests = useTestSubTests(values?.source_test_id)

	return (
		<ColumnStack>
			<CustomTextField name="description" label="Description" required />
			<RowStack>
				<TestAutoComplete
					required
					name="source_test_id"
					label="Source Test"
					field="id"
					labelField="name"
					sx={{ width: "100%" }}
				/>
				<TestAutoComplete
					required
					name="dest_test_id"
					label="Reflexes To"
					field="id"
					labelField="name"
					sx={{ width: "100%" }}
				/>
				<CustomListFieldV2
					options={[ReflexMethodNormal, ReflexMethodAbnormal, ReflexMethodInconsistent]}
					name="reflex_range_type"
					label="Reflex When"
				/>
			</RowStack>
			<InputLabel>Order Options</InputLabel>
			<RowStack>
				<CustomCheckboxField
					name="auto_order"
					label="Auto Order"
				/>
				<CustomCheckboxField
					name="force_order"
					label="Force Order"
				/>
				<FormControlLabel
					control={
						<Checkbox
							name={"for_all_facilities"} // not stored in the form
							checked={forAllFacilities}
							onChange={(_, v) => {
								if (!v) {
									setFieldValue("facilities", [])
								}
								setForAllFacilities(v)
							}}
						/>
					}
					label={"For All Facilities"}
				/>
			</RowStack>
			{is_panel && (
				<>
					<InputLabel>Select Source Panel Subtests <i>(Optional)</i></InputLabel>
					<Typography variant="caption">Selected tests will be used to trigger the reflex. If no tests are selected, all subtests will be used.</Typography>
					<TestTableSelector name="source_subtests" query={subTests} />
				</>
			)}
			{!forAllFacilities && (
				<>
					<InputLabel>Facilities</InputLabel>
					<FacilityTableSelector name="facilities" />
				</>

			)}

		</ColumnStack>
	)
}
