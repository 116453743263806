import { matchSorter } from "match-sorter";
import React from "react";
import { useCollectors } from "../../services/queries";
import ReactQueryAutocomplete, { ReactQueryAutocompleteProps } from "../ReactQueryAutocomplete";

type CollectorAutoCompleteProps = Omit<ReactQueryAutocompleteProps, "query">;

export default function CollectorAutoComplete(props: CollectorAutoCompleteProps) {

    const matchKeys = ["first_name", "last_name"]

    const filterOptions = (options: any[], { inputValue }: { inputValue: any }) => matchSorter(options, inputValue, { keys: matchKeys });
    return (
        <ReactQueryAutocomplete {...{ ...props, query: useCollectors }}
            getOptionLabel={(p) => p.first_name + " " + p.last_name}
            filterOptions={filterOptions}
        />
    )
}
