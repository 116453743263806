import * as Yup from 'yup'

export const insuranceMappingInitialValues = {
	"insurance_id": 0,
	"external_insurance": "",
	"instance_id": 0,
}

const insuranceMappingValidation = Yup.object().shape({
	"insurance_id": Yup.number().required(),
	"external_insurance": Yup.string(),
	"instance_id": Yup.number().required(),
})

export default insuranceMappingValidation;
