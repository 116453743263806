import React, { useContext, useState } from 'react'
import { useDeleteOrderNote, useOrderNotes, useUsers } from '../../services/queries'
import { FormatDateTime } from "../../services/util";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { NoteTypes } from '../../constants/constants';
import CloseIcon from "@mui/icons-material/Close";
import { SessionContext } from "../../hooks/getContext";
import { OrderNoteDialog } from '../OrderNoteDialog';
import { Add } from '@mui/icons-material';
import { RowStack } from '../Stacks';
import Title from '../Title';
import { NoteTypeInternal, OrderNote } from '../../constants/go_types';
import { usePermission } from '../../hooks/usePermission';

export default function OrderNotes({ orderId }: { orderId: number }) {
    const [open, setOpen] = useState(false)
    const session = useContext(SessionContext);
    console.log('order id', orderId)
    const notes = useOrderNotes(orderId)
    const deleteNote = useDeleteOrderNote(orderId)
    const users = useUsers()
    const canEdit = usePermission("orders:write")

    const isFacilityUser = session.user.Type === "Facility"

    const getUserEmail = (userId: number) => {
        if (!users.data) {
            return ""
        }

        return users.data!.find((u) => u?.id === userId)?.email || ""
    }

    const handleDeleteNote = (noteId: number) => {
        deleteNote.mutate({ orderId: orderId, noteId: noteId })
    }


    // we will filter internal notes from facility users
    const noteFilter = (n: OrderNote) => {
        if (isFacilityUser) {
            return n.note_type !== NoteTypeInternal
        } else {
            return true
        }
    }

    return (
        <>
            <OrderNoteDialog orderId={orderId}
                open={open}
                onClose={() => setOpen(false)}
                onCancel={() => setOpen(false)}
            />
            <RowStack justifyContent="space-between">
                <Title>Notes</Title>
                <Button
                    disabled={!canEdit}
                    variant="outlined"
                    onClick={() => setOpen((o) => !o)}
                    startIcon={<Add />}
                >Add Note</Button>
            </RowStack>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {!isFacilityUser && <TableCell></TableCell>}
                            <TableCell>Note Type</TableCell>
                            <TableCell>Note</TableCell>
                            <TableCell>Date Added</TableCell>
                            <TableCell>Added By</TableCell>
                        </TableRow>
                    </TableHead>
                    {notes.isLoading && <TableBody><TableRow><TableCell colSpan={5}>Loading...</TableCell></TableRow></TableBody>}
                    {notes.isError && <TableBody><TableRow><TableCell colSpan={5}>Error loading notes</TableCell></TableRow></TableBody>}
                    <TableBody>
                        {(!notes.data || notes.data.length === 0) && <TableRow><TableCell align="center" colSpan={5}>No notes for this order.</TableCell></TableRow>}
                        {notes.data && notes.data!.filter(noteFilter).map((row) => (
                            <TableRow key={row.note_id}>
                                {!isFacilityUser &&
                                    <TableCell size="small" sx={{ padding: "0" }}>
                                        <Button onClick={() => handleDeleteNote(row.note_id!)}
                                            size="small"
                                            sx={{ minWidth: "12px" }}>
                                            <CloseIcon color="error" />
                                        </Button>
                                    </TableCell>}
                                <TableCell>{NoteTypes[row.note_type]}</TableCell>
                                <TableCell>{row.note.note}</TableCell>
                                <TableCell>{FormatDateTime(row.note.created_at)}</TableCell>
                                <TableCell>{getUserEmail(row.note.added_by_id)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )

}
