import React, { useState } from "react";

import ErrorAlert from "./ErrorAlert";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
} from "@mui/material";

import DiagnosisCodeSearch from "./DiagnosisCodeSelect";
import { LoadingButton } from "@mui/lab";
import { useOrderDiagnosisCodes } from "../services/queries";
import { useUpdateOrderDiagnosisCodes } from "../services/queries";

function ChangeDiagnosisCodesDialog({ orderId, open, setOpen }) {
    const diagnosisCodes = useOrderDiagnosisCodes(orderId)
    const updateDiagnosisCodes = useUpdateOrderDiagnosisCodes(orderId)

    const prepareRequest = (oldCodes) => {
        if (!oldCodes) {
            return []
        }
        let nc = [];
        for (let code of oldCodes) {
            nc.push(code.Code)
        }
        return nc
    }

    const transformFromNew = (newCodes) => {
        if (!newCodes) {
            return []
        }
        let nc = [];
        for (let code of newCodes) {
            nc.push({
                Code: code.code,
                Description: code.description,
                OrderNumber: code.order_number,
            })
        }
        return nc
    }

    const [newCodes, setNewCodes] = useState(transformFromNew(diagnosisCodes.data))

    const handleSave = () => {
        updateDiagnosisCodes.mutate({ diagnosis_codes: prepareRequest(newCodes) }, { onSuccess: () => setOpen(false) })

    }

    return (
        <Dialog open={open} >
            <DialogTitle>Change Diagnosis Codes</DialogTitle>
            <DialogContent>
                <>
                    <DiagnosisCodeSearch diagnosisCodes={newCodes}
                        setDiagnosis={(_, v) => setNewCodes(v)}
                    />
                </>

                <ErrorAlert error={updateDiagnosisCodes.isError && updateDiagnosisCodes.error.message} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <LoadingButton
                    loading={updateDiagnosisCodes.isPending}
                    loadingIndicator="Updating..."
                    disabled={diagnosisCodes.length < 1}
                    onClick={handleSave}>
                    Change

                </LoadingButton>
            </DialogActions>
        </Dialog>
    )


}

export default ChangeDiagnosisCodesDialog;
