import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import * as Yup from 'yup';
import { TextField, FormControlLabel, Checkbox, RadioGroup, FormLabel, Radio, MenuItem, TextFieldProps } from "@mui/material";

const fieldIsRequired = (validationSchema: any, name: string): boolean => {
    if (!validationSchema) return false; // If no schema, return false
    const field = validationSchema.fields[name]; // Access field by key
    if (!field) return false; // If field doesn't exist, return false

    return !field?.spec?.optional; // Check if it has a required test
};


interface CustomTextFieldProps<T> {
    name: keyof T;
    label?: string;
    required?: boolean;
    [key: string]: any; // For additional props
}

const CustomTextField = <T extends {}>({ name, label, required, ...props }: CustomTextFieldProps<T>) => {
    const { handleBlur } = useFormikContext()
    // @ts-ignore
    const [field, meta, helper] = useField(name);

    return (
        <TextField
            required={required}
            name={String(name)}
            label={label || String(name)}
            helperText={meta.touched && String(meta.error || "")}
            error={meta.touched && Boolean(meta.error)}
            size="small"
            onBlur={handleBlur}
            onChange={(e) => helper.setValue(e.target.value)}
            value={field.value}
            InputLabelProps={{
                shrink: field.value !== null && field.value !== undefined && field.value !== "",
            }}
            {...props}
        />
    );
};

interface CustomCheckboxProps<T> {
    name: keyof T;
    label?: string;
    [key: string]: any; // For additional props
}


const CustomCheckboxField = <T extends {}>({ name, label, ...props }: CustomCheckboxProps<T>) => {
    const { handleChange, values } = useFormikContext<T>();
    return (
        <FormControlLabel
            control={
                <Checkbox
                    name={String(name)}
                    checked={Boolean(values?.[name])}
                    onChange={handleChange}
                    {...props}
                />
            }
            label={label || String(name)}
        />
    );
};

interface CustomRadioProps<T> {
    name: keyof T;
    label?: string;
    required?: boolean;
    options: any;
    [key: string]: any; // For additional props
}

interface RadioGroupOption {
    label: string;
    value: any;
}

const CustomRadioGroup = <T extends {}>({ name, label, required, options, ...props }: CustomRadioProps<T>) => {
    const { setFieldValue, values } = useFormikContext<T>();

    return (
        <>
            <FormLabel>{label}</FormLabel>
            <RadioGroup
                row={true}
                name={String(name)}
                value={values?.[name] || false}
                onChange={(_, value) => setFieldValue(String(name), value === "true")}
                {...props}
            >
                {options.map((option: RadioGroupOption, index: number) => (
                    <FormControlLabel
                        key={index}
                        value={option?.value}
                        control={<Radio

                        />}
                        label={option?.label}
                    />
                ))}
            </RadioGroup>
        </>
    );
};

type option = {
    ID: number;
    Name: string;
}

type CustomListFieldProps = TextFieldProps & {
    name: string; // Ensure `name` is explicitly defined as a string
    label?: string;
    required?: boolean;
    options: option[];
};

const CustomListField: React.FC<CustomListFieldProps> = ({ name, label, options, required, ...props }) => {
    const [field, meta, helper] = useField(name)

    useEffect(() => {
        if (options.length === 1 && !field.value) {
            helper.setValue(options[0].ID)
        }
    }, [field.value, options, helper])

    return (
        <TextField
            required={required}
            select
            name={name}
            label={label}
            helperText={meta.touched && String(meta.error || "")}
            error={meta.touched && Boolean(meta.error)}
            variant="outlined"
            size="small"
            sx={{ minWidth: 200, width: "100%" }}
            onChange={(e) => helper.setValue(e.target.value)}
            value={field.value ?? ""}
            {...props}
        >
            {options.map((option: option, index: number) => (
                <MenuItem key={index} value={option.ID}>{option.Name}</MenuItem>
            ))}
        </TextField>
    )
}

type CustomListFieldV2Props = TextFieldProps & {
    name: string; // Ensure `name` is explicitly defined as a string
    label?: string;
    required?: boolean;
    options: string[];
};
// this one doesn't require options to have an id
const CustomListFieldV2: React.FC<CustomListFieldV2Props> = ({ name, label, options, required, ...props }) => {
    const { setFieldValue, errors, touched, values } = useFormikContext<any>();

    return (
        <TextField
            required={required}
            select
            name={name}
            label={label}
            helperText={touched?.[name] && String(errors?.[name] || "")}
            error={touched?.[name] && Boolean(errors?.[name])}
            variant="outlined"
            size="small"
            sx={{ minWidth: 200, width: "100%" }}
            onChange={(e) => setFieldValue(name, e.target.value)}
            value={values?.[name] || ""}
            {...props}
        >
            {options.map((option: string, index: number) => (
                <MenuItem key={index} value={option}>{option}</MenuItem>
            ))}
        </TextField>
    )
}

export { CustomTextField, CustomCheckboxField, CustomRadioGroup, CustomListField, CustomListFieldV2 };
