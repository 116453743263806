import React from "react";
import { useInsurances } from "../../services/queries";
import ReactQueryAutocomplete, { ReactQueryAutocompleteProps } from "../ReactQueryAutocomplete";

type InsuranceAutoCompleteProps = Omit<ReactQueryAutocompleteProps, "query">;

export default function InsuranceAutoComplete(props: InsuranceAutoCompleteProps) {
    return (
        <ReactQueryAutocomplete {...{ ...props, query: useInsurances }} />
    )
}
