import { Box, InputLabel } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useFormikContext } from "formik";
import React from "react";
import { CustomCheckboxField, CustomListFieldV2, CustomTextField } from "../components/CustomTextField";
import GenericTableSelector from "../components/fields/GenericTableSelector";
import PanelForm from "../components/PanelForm";
import { ColumnStack, RowStack } from "../components/Stacks";
import { Contact } from "../constants/go_types";
import { useContact, useCreateContact, useFacilitiesDeprecated, useProvidersDeprecated, useUpdateContact } from "../services/queries";
import contactValidation, { contactInitialValues } from "../validations/contacts";

type ContactPanelFormProps = {
	onBack: () => void;
	onSave: () => void;
	id?: number;
}

export function ContactPanelForm({ id, onBack, onSave }: ContactPanelFormProps) {
	const contact = useContact(id)

	return (
		<PanelForm
			validationSchema={contactValidation}
			form={<ContactForm />}
			createMutation={useCreateContact()}
			updateMutation={useUpdateContact()}
			initialValues={contact.isSuccess ? contact.data : contactInitialValues}
			onBack={onBack}
			onSave={onSave}
		/>
	)
}

const facilityColumns: GridColDef[] = [
	{ field: "ID", headerName: "ID", width: 45 },
	{ field: "Name", headerName: "Name", width: 145 },
]

const providerColumns: GridColDef[] = [
	{ field: "ID", headerName: "ID", width: 45 },
	{ field: "FirstName", headerName: "First Name", width: 145 },
	{ field: "LastName", headerName: "Last Name", width: 145 },
	{ field: "NPI", headerName: "NPI", width: 145 },
]
export default function ContactForm() {
	const { values, errors } = useFormikContext<Contact>();
	console.log('values', values)
	console.log('errors', errors)

	return (
		<ColumnStack>
			<RowStack>
				<CustomListFieldV2
					name="type"
					label="Type"
					required
					variant="outlined"
					options={["Doctor", "Nurse", "Other"]}
					sx={{ width: "100%" }}
				/>
				<CustomTextField name="first_name" label="First Name" required
					sx={{ width: "100%" }}
				/>
				<CustomTextField name="last_name" label="Last Name" required
					sx={{ width: "100%" }}
				/>
			</RowStack>
			<RowStack>
				<CustomTextField name="phone_1" label="Phone 1" />
				<CustomTextField name="phone_2" label="Phone 2" />
				<CustomTextField name="email" label="Email" />
				<CustomTextField name="fax" label="Fax" />
			</RowStack>
			<CustomTextField name="notes" label="Notes" multiline rows={3} />
			<CustomCheckboxField name="critical" label="Critical Contact" />
			<InputLabel>Notification Methods</InputLabel>
			<InputLabel>Results</InputLabel>
			<RowStack>
				<CustomCheckboxField
					name="email_results"
					disabled={values.email === "" || Boolean(errors.email)}
					label="Email" />
				<CustomCheckboxField
					name="text_results"
					disabled={values.phone_1 === "" || Boolean(errors.phone_1)}
					label="Text" />
				<CustomCheckboxField
					name="fax_results"
					disabled={values.fax === "" || Boolean(errors.fax)}
					label="Fax" />
			</RowStack>
			<InputLabel>Orders</InputLabel>
			<RowStack>
				<CustomCheckboxField
					name="email_orders"
					disabled={values.email === "" || Boolean(errors.email)}
					label="Email" />
				<CustomCheckboxField
					name="text_orders"
					disabled={true} // intentionally disabled
					label="Text" />
				<CustomCheckboxField
					name="fax_orders"
					disabled={true} // intentionally disabled
					label="Fax" />
			</RowStack>
			<InputLabel>Facilities</InputLabel>
			<Box height="500px">
				<GenericTableSelector name="facilities" columns={facilityColumns} queryResult={useFacilitiesDeprecated()} />
			</Box>
			<InputLabel>Providers</InputLabel>
			<Box height="500px">
				<GenericTableSelector name="providers" columns={providerColumns} queryResult={useProvidersDeprecated()} />
			</Box>
		</ColumnStack>
	)
}
