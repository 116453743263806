import DataTable from "../components/DataTable";
import { useState, useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { apiFetch } from "../services/fetch";
import PatientOrderLinkForm from "./PatientOrderLinkForm";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import Title from "./Title";
import ButtonTray from "./ButtonTray";
import { Button } from "@mui/material";
import ProviderNoteTriggerForm from "./ProviderNoteTriggerForm";
import { usePermission } from "../hooks/usePermission";

function ProviderNoteTriggerTable({ triggers, setSelected, error }) {
    const host = window.location.host;
    const protocol = window.location.protocol;
    console.log(protocol);

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 50 },
        { field: "Description", headerName: "Description", width: 200 },
        { field: "Enabled", headerName: "Enabled", width: 100, renderCell: getCheckbox },
        { field: "Providers", headerName: "Providers", width: 200, valueGetter: (params) => params?.row?.Providers.length },
        { field: "TestCodes", headerName: "TestCodes", width: 200, valueGetter: (params) => (params?.row?.TestCodes.length) },
        { field: "Insurances", headerName: "Insurances", width: 150, valueGetter: (params) => (params?.row?.Insurances.length) },
    ]

    return <DataTable
        data={triggers}
        error={error}
        setSelected={setSelected}
        columns={columns}

    />

}

function ProviderNoteTriggerSetup() {
    const [triggers, setTriggers] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [create, setCreate] = useState(false)
    const [edit, setEdit] = useState(false)
    const [error, setError] = useState(null)
    const [selected, setSelected] = useState([])
    const canEdit = usePermission("facilities:write")

    useEffect(() => {
        const getTriggers = async () => {
            try {
                const response = await apiFetch("/providers/noteTriggers", "GET")
                setTriggers(response)
            } catch (e) {
                setError("failed to load provider note triggers")
            }
        }

        getTriggers()

    }, [refresh])


    return (
        <>
            {!edit && !create ?
                <TableFrame>
                    <DataTableContainer>
                        <Title></Title>
                        <ProviderNoteTriggerTable triggers={triggers} error={error} setSelected={setSelected} />
                    </DataTableContainer>
                    <ButtonTray>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={() => setCreate(true)}
                            disabled={!canEdit}
                        >
                            Create
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!canEdit || selected.length === 0}
                            onClick={() => setEdit(true)}
                        >
                            Edit
                        </Button>
                    </ButtonTray></TableFrame>
                :
                edit ? <ProviderNoteTriggerForm id={selected[0]} callback={() => { setEdit(false); setRefresh(!refresh) }} />
                    : <ProviderNoteTriggerForm callback={() => { setCreate(false); setRefresh(!refresh) }} />
            }
        </>
    )

}

export default ProviderNoteTriggerSetup;
