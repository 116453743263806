import React, { useEffect } from "react";
import { DataGridPro, DataGridProProps, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import debounce from "lodash/debounce"; // Install lodash for debouncing

type PanelDataGridProps = DataGridProProps & {
    storageName: string; // Unique key for this table instance
};

function PanelDataGrid({ storageName, ...props }: PanelDataGridProps) {
    const apiRef = useGridApiRef();

    // Load initial state from localStorage on mount
    useEffect(() => {
        const savedState = localStorage.getItem(`dataGridState-${storageName}`);
        if (savedState && apiRef.current) {
            const parsedState = JSON.parse(savedState);
            apiRef.current.restoreState(parsedState); // Apply saved state immediately
        }
    }, [apiRef, storageName]);

    // Debounced function to save state
    const saveState = debounce(() => {
        if (apiRef.current && apiRef.current.exportState) {
            const currentState = apiRef.current.exportState();
            localStorage.setItem(`dataGridState-${storageName}`, JSON.stringify(currentState));
        }
    }, 500);

    // Handle state changes via onStateChange prop
    const handleStateChange = () => {
        saveState();
    };

    return (
        <DataGridPro
            hideFooterRowCount
            density="compact"
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                    display: "none",
                },
            }}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            onStateChange={handleStateChange} // Listen to state changes
            {...props}
            apiRef={apiRef} // Attach apiRef for state management
        />
    );
}

export default PanelDataGrid;
