import React from "react";
import { useLaboratories } from "../../services/queries";
import ReactQueryAutocomplete, { ReactQueryAutocompleteProps } from "../ReactQueryAutocomplete";

type LaboratoriesAutoCompleteProps = Omit<ReactQueryAutocompleteProps, "query">;

export default function LaboratoriesAutoComplete(props: LaboratoriesAutoCompleteProps) {
    return (
        <ReactQueryAutocomplete {...{ ...props, query: useLaboratories }} />
    )
}
