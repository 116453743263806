import React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ScienceIcon from "@mui/icons-material/Science";
import { Link } from "react-router-dom";
import { usePermission } from "../hooks/usePermission";

const withLink = (to, children) => (
    <Link style={{ color: "rgba(0, 0, 0, 0.54)" }} to={to}>
        {children}
    </Link>
);

const actions = [
    {
        icon: withLink(
            "/newshipment",
            <LocalShippingIcon sx={{ display: "block" }} />
        ),
        name: "Shipment",
        permission: "shipments:write"
    },
    {
        icon: withLink(
            "/newpatient",
            <PersonAddIcon sx={{ display: "block" }} />
        ),
        name: "Patient",
        permission: "patients:write"
    },
    {
        icon: withLink("/newsample", <ScienceIcon sx={{ display: "block" }} />),
        name: "Sample",
        permission: "samples:write"
    },
    {
        icon: withLink("/neworder", <ReceiptIcon sx={{ display: "block" }} />),
        name: "Order",
        permission: "orders:write"
    },
];

export default function AddSpeedDial() {
    const canOrder = usePermission("orders:write")
    const canEditPatient = usePermission("patients:write")
    const canEditSample = usePermission("samples:write")
    const canEditShipment = usePermission("shipments:write")

    const validActions = [];
    if (canOrder) {
        validActions.push(actions[3]);
    }
    if (canEditPatient) {
        validActions.push(actions[1]);
    }
    if (canEditSample) {
        validActions.push(actions[2]);
    }
    if (canEditShipment) {
        validActions.push(actions[0])
    }

    if (validActions.length === 0) {
        return null
    }

    return (
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "absolute", bottom: 32, right: 32 }}
            icon={<SpeedDialIcon />}
        >
            {validActions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                />
            ))}
        </SpeedDial>
    );
}
