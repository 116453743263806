import { SxProps, Theme } from "@mui/material";
import Paper from "@mui/material/Paper";
import React, { ReactNode } from "react";

export default function StyledPaper({ children, sx }: { children: ReactNode, sx?: SxProps<Theme> }) {
    return (
        <Paper
            elevation={5}
            sx={{
                p: { xs: 1, md: 2 },
                // m: { xs: 1, sm: 1, md: 2 },
                height: "100%",
                ...sx
            }}
        >
            {children}
        </Paper>
    );
}

