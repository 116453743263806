import * as Yup from 'yup'

export const userInitialValues = {
	id: null, /* uint */
	first_name: "",
	last_name: "",
	email: "",
	facilities: /* uint */[],
	organizations: /* uint */[],
	laboratory_id: null, /* uint */ // nullable bc admin user cant be tied to lab that doesnt exist yet
	provider_id: null, /* uint */
	collector_id: null, /* uint */
	roles: /* uint */[],
	type: "Facility", // Lab or Facility
	is_active: true,
	create_collector: false,
	create_contact: false,
}

export const userValidation = Yup.object().shape({
	"id": Yup.number().nullable(),
	"first_name": Yup.string().required(),
	"last_name": Yup.string().required(),
	"email": Yup.string().email().required(),
	"facilities": Yup.array().of(Yup.number()),
	"organizations": Yup.array().of(Yup.number()),
	"laboratory_id": Yup.number().required(),
	"provider_id": Yup.number().nullable(),
	"collector_id": Yup.number().nullable(),
	"roles": Yup.array().of(Yup.number()).min(1, "a user must have at least 1 role").required(),
	"type": Yup.string().required(),
	"create_collector": Yup.boolean(),
	"create_contact": Yup.boolean(),
}) 
