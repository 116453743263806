import { Button, IconButton } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"
import React from "react"
import { useMemo } from "react"
import EntityPanelTable, { EntityPanelTableProps } from "../components/EntityPanelTable"
import { User, UserTableView } from "../constants/go_types"
import { useActivateUser, useSendUserActivationEmail, useUsers, useUsersTableView } from "../services/queries"
import LockResetIcon from "@mui/icons-material/LockReset";
import { CopyAll } from "@mui/icons-material"
import DisableUserDialog from "../components/Dialogs/DisableUserDialog"
import { UseQueryResult } from "@tanstack/react-query"

type UserPanelTableProps = Omit<EntityPanelTableProps, "columns" | "query">

const ResetButton = ({ params }: { params: GridRenderCellParams }) => {
	const sendEmail = useSendUserActivationEmail()

	return (
		<>
			<Button
				size="small"
				variant="contained"
				startIcon={<LockResetIcon />}
				onClick={() => sendEmail.mutate(Number(params.id))}
			>
				Reset Password
			</Button>
			{params.row.pending_invite && (
				<IconButton
					size="small"
					onClick={() => {
						navigator.clipboard.writeText(`${window.location.origin}/activate?invite=${params.row.pending_invite}`);
					}}
				>
					<CopyAll />
				</IconButton>
			)}
		</>
	);
};


export default function UserPanelTable(props: UserPanelTableProps) {
	const users = useUsersTableView()
	const activateUser = useActivateUser()
	const [disableDialogId, setDisableDialogId] = React.useState<number | null>(null)

	const handleDisable = (id: number) => {
		setDisableDialogId(id)
	}

	const disableButton = ({ id, canEdit, query }: { id: number | undefined, canEdit: boolean, query: UseQueryResult<any> }) => {
		const userDisabled = !users.data?.find((user: UserTableView) => user.id === id)?.is_active

		return (
			userDisabled ? (
				<Button
					color="success"
					variant="contained"
					disabled={!canEdit || !id}
					onClick={() => {
						activateUser.mutate(id!,
							{ onSuccess: () => query.refetch() })
					}}
				>Enable</Button>
			) : (
				<Button
					color="error"
					variant="contained"
					onClick={() => { handleDisable(id!); query.refetch() }}
					disabled={!canEdit || !id}>Deactivate</Button>
			)
		)
	}

	const columns: GridColDef[] = useMemo(() => [
		{ field: "id", headerName: "ID", width: 45 },
		{ field: "first_name", headerName: "First Name", width: 150 },
		{ field: "last_name", headerName: "Last Name", width: 150 },
		{ field: "email", headerName: "Email", width: 250 },
		{ field: "type", headerName: "Type", width: 100 },
		{ field: "is_active", headerName: "Activated", width: 100, type: "boolean" },
		{ field: "organizations", headerName: "Organizations", width: 130 },
		{ field: "roles", headerName: "Roles", width: 100 },
		{ field: "facilities", headerName: "Facilities", width: 130 },
		{ field: "deactivated_on", headerName: "Deactivated On", width: 150 },
		{ field: "deactivated_by", headerName: "Deactivated By", width: 150 },
		{ field: "deactivated_reason", headerName: "Deactivated Reason", width: 350 },
		{ field: "reset", headerName: "Reset Password", width: 240, renderCell: (params) => <ResetButton params={params} /> },
	], [])

	return (
		<>
			<DisableUserDialog userId={disableDialogId} setUserId={setDisableDialogId} />
			<EntityPanelTable
				columns={columns}
				query={useUsersTableView()}
				dataGripProps={{ initialState: { pinnedColumns: { 'right': ["reset"] } } }}
				onDelete={handleDisable}
				deleteButtonOverride={disableButton}
				{...props} />
		</>

	)
}
