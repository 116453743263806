import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SessionContext } from "../hooks/getContext";
import { CircularProgress, Dialog, DialogContent } from "@mui/material";

const ProtectedRoute = ({ children, requiredRoles, excludedRoles, permission }) => {
    const { user, loading } = useContext(SessionContext);
    console.log(user, loading)
    const location = useLocation();
    const navigate = useNavigate();
    const currentPath = location.pathname;

    useEffect(() => {
        if (!loading && !user) {
            console.log("redirecting to login")
            navigate("/login", { state: { from: currentPath } });
            return
        }

        if (!loading && user) {
            let isAuthorized =
                (user.Permissions && user.Permissions.includes(permission)) || !permission;

            if (!isAuthorized) {
                console.log("attempted unauthorized access. redirecting")
                navigate("/unauthorized");
                return
            }
        }
    }, [loading, user, currentPath, permission, navigate]);

    if (loading) {
        return (
            <Dialog open={true}>
                <DialogContent>
                    <CircularProgress color="inherit" />
                </DialogContent>
            </Dialog>
        );
    }

    if (!user || !user.Permissions) {
        return null;
    }

    return children;
};

export { ProtectedRoute };

