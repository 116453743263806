import React, { useEffect, useState, useRef } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { getProviders } from "../services/providers";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Draw } from "@mui/icons-material";
import SignatureSetup from "./SetupSignature";
import { usePermission } from "../hooks/usePermission";

String.prototype.toProperCase = function() {
    return this.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export default function ProvidersTable({ setProvider, Title, refresh, providers, setProviders }) {
    const [allProviders, setAllProviders] = useState([]);
    const [open, setOpen] = useState(false);
    const [iRefresh, setIRefresh] = useState(false);
    const providerId = useRef(null);
    const canEdit = usePermission("facilities:write")

    useEffect(() => {
        getProviders().then((p) => {
            setAllProviders(p);
        });
    }, [refresh, iRefresh]);

    const toTitle = (str) => {
        return str.value.toProperCase();
    };

    const countFacilities = (value) => {
        return value.value.length;
    };

    const handleSetupSignature = (id) => {
        console.log('opening')
        providerId.current = id;
        setOpen(true);
    }

    const columns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "Enabled", headerName: "Enabled", width: 75, type: "boolean" },
        { field: "FirstName", headerName: "First Name", width: 175, valueGetter: toTitle },
        { field: "LastName", headerName: "Last Name", width: 175, valueGetter: toTitle },
        { field: "NPI", headerName: "NPI", width: 125 },
        { field: "Type", headerName: "Type", width: 125, valueGetter: toTitle },
        {
            field: "Facilities",
            headerName: "Facilities",
            width: 125,
            valueGetter: countFacilities,
        },
        {
            field: "actions",
            headerName: "Setup Signature",
            width: 145,
            type: "actions",
            getActions: (params) => [
                <GridActionsCellItem icon={
                    !params.row.HasSignature ? <Draw /> :
                        <Draw color="success" />} disabled={!canEdit} onClick={() => handleSetupSignature(params.id)} label="Setup Signature" />,
            ]
        },
    ];

    return (
        <React.Fragment>
            <SignatureSetup
                open={open}
                setOpen={(open) => { if (!open) { setIRefresh(!iRefresh) } setOpen(open) }}
                providerId={providerId.current} />
            <DataGridPro
                hideFooterRowCount
                getRowId={(row) => row.ID}
                density="compact"
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                rowSelectionModel={providers}
                onRowSelectionModelChange={(newSelection) => {
                    for (const f of allProviders) {
                        if (f.ID === newSelection[0]) {
                            setProvider(f);
                            break;
                        }
                    }
                    setProviders(newSelection)
                }}
                rows={allProviders}
                columns={columns}
            />
        </React.Fragment>
    );
}
