import React, { useContext } from "react";
import { CustomListField, CustomTextField } from "../components/CustomTextField";
import { NoteTypeFacility, NoteTypeInternal, NoteTypeReport } from "../constants/go_types";
import { useCreateOrderNote } from "../services/queries";
import { orderNoteInitialValues, orderNoteValidation } from "../validations/order_notes";
import { DialogForm } from "./DialogForm";
import { ColumnStack } from "./Stacks";
import { SessionContext } from "../hooks/getContext";

type OrderNoteDialogProps = {
    open: boolean,
    onClose: () => void,
    onCancel: () => void,
    orderId: number,
    availableNoteTypes?: number[]
}

export function OrderNoteDialog({ open, onClose, onCancel, orderId, availableNoteTypes }: OrderNoteDialogProps) {
    const initValues = { orderId: orderId, m: orderNoteInitialValues }
    // initValues.order_id = orderId

    return (
        <DialogForm
            open={open}
            validationSchema={orderNoteValidation}
            initialValues={initValues}
            createMutation={useCreateOrderNote(orderId)}
            form={<OrderNoteForm availableNoteTypes={availableNoteTypes} />}
            onSave={() => onClose()}
            onBack={() => onCancel()}
        />
    )


}

function OrderNoteForm({ availableNoteTypes }: { availableNoteTypes?: number[] }) {
    const session = useContext(SessionContext);
    const isFacilityUser = session.user.Type === "Facility"
    // facility users should only have the facility note type
    var options = [
        { ID: NoteTypeFacility, Name: "Facility" },
        { ID: NoteTypeInternal, Name: "Internal" },
        { ID: NoteTypeReport, Name: "Report" }
    ]

    if (availableNoteTypes) {
        options = options.filter(o => availableNoteTypes.includes(o.ID))
    }

    if (isFacilityUser) {
        options = options.filter(o => o.ID === NoteTypeFacility)
    }

    return (
        <ColumnStack pt={1}>
            <CustomListField
                required
                name="m.note_type"
                label="Type"
                options={options} />
            <CustomTextField
                sx={{ minWidth: "300px" }}
                rows={3}
                multiline
                required
                name="m.note.note"
                label="Note" />
        </ColumnStack>
    )
}
