import { DataGridPro, GridColDef, GridToolbar } from "@mui/x-data-grid-pro";
import { UseQueryResult } from "@tanstack/react-query";
import { useField } from "formik";
import React from "react";
import { AxiosError } from "../AxiosError";

type GenericTableSelectorProps = {
    name: string;
    columns: GridColDef[];
    queryResult: UseQueryResult<any>
}

function GenericTableSelector({ name, queryResult, columns }: GenericTableSelectorProps) {
    const [field, _, helper] = useField(name);

    return (
        queryResult.isError ? (<AxiosError error={queryResult.error} />)
            : (
                <DataGridPro
                    keepNonExistentRowsSelected
                    loading={queryResult.isLoading}
                    density="compact"
                    getRowId={(row) => row.id ?? row.ID}
                    rows={queryResult.data || []}
                    columns={columns}
                    onRowSelectionModelChange={(v) => helper.setValue(v)}
                    rowSelectionModel={field.value || []}
                    checkboxSelection={true}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{

                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            )
    )
}

export default GenericTableSelector;
