import * as Yup from 'yup'

export const testInitialValues = {
	category_id: null,
	code: null,
	comment: "",
	control: false,
	cost: 0,
	cpt_code: "",
	department_id: null,
	description: "",
	internal: false,
	is_panel: false,
	laboratory_id: null,
	loinc: "",
	max_tat: 72,
	name: "",
	orderable: false,
	ordering: 1,
	reference_lab_test_code: "",
	reference_lab_result_code: "",
	referenced: false,
	report_type_id: 1,
	required_tests: [],
	specimen_types: [],
	stability: 72,
	sub_tests: [],
	sub_type: 0,
	type: 0,
}

export const testValidation = Yup.object().shape({
	category_id: Yup.number().nullable(),
	code: Yup.number().required(),
	comment: Yup.string(),
	control: Yup.bool(),
	cost: Yup.number(),
	cpt_code: Yup.string(),
	department_id: Yup.number().required("Department is required"),
	description: Yup.string().required(),
	internal: Yup.bool(),
	is_panel: Yup.bool(),
	laboratory_id: Yup.number().required("Performing laboratory is required"),
	loinc: Yup.string(),
	max_tat: Yup.number().min(1, "Max turnaround time must be greater then zero").required("Max turnaround time is required"),
	name: Yup.string().required(),
	orderable: Yup.bool(),
	ordering: Yup.number(),
	reference_lab_result_code: Yup.string(),
	reference_lab_test_code: Yup.string(),
	referenced: Yup.bool(),
	report_type_id: Yup.number().min(1).max(3).required(),
	required_tests: Yup.array().of(Yup.number()).nullable(),
	specimen_types: Yup.array().of(Yup.number().min(1)).min(1, "At least one specimen type must be selected"),
	stability: Yup.number().min(1, "Stability must be greater than zero").required("Stability is required"),
	sub_tests: Yup.array().of(Yup.number()).when("IsPanel", { is: true, then: (schema) => schema.min(1, "At least one subtest is required") }).nullable(),
	sub_type: Yup.number().required(),
	type: Yup.number().required(),
}) 
