import {
    Button,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormatDateTime } from "../services/util";
import { useState, useContext, useEffect } from "react";
import { apiFetch } from "../services/fetch";
import ConfirmDialog from "./ConfirmDialog";
import { SessionContext } from "../hooks/getContext";
import ModalImage from "react-modal-image";
import { Buffer } from "buffer";
import ErrorAlert from "./ErrorAlert";
import { Person } from "@mui/icons-material";
import { useNavigate } from "react-router";

export default function AttachmentsTable({ orderDetails, patientAttachments = [], callback = () => { } }) {
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [attachmentId, setAttachmentId] = useState(null);
    const [attachmentName, setAttachmentName] = useState(null);
    const [viewImage, setImageView] = useState(null);
    const [imageB64, setImageB64] = useState({});
    const [loadingImage, setLoadingImage] = useState(false);
    const [imageError, setImageError] = useState(null);
    const session = useContext(SessionContext);
    const navigate = useNavigate()
    const isLabUser = session?.user.Type === "Lab"

    useEffect(() => {
        if (!viewImage) {
            return
        }

        const getImage = async () => {
            try {
                setLoadingImage(true)
                const data = await apiFetch(`/attachments/${viewImage}`)
                const buf = await data.arrayBuffer()
                console.log(data);
                var b = Buffer.from(buf, 'binary')
                if (data.type === "image/png") {
                    setImageB64({ ...imageB64, [viewImage]: `data:image/png;base64,${b.toString('base64')}` })
                } else if (data.type === "image/jpeg") {
                    setImageB64({ ...imageB64, [viewImage]: `data:image/jpeg;base64,${b.toString('base64')}` })
                }
            } catch (e) {
                setImageError("Failed to load image.")
            } finally {
                setLoadingImage(false)
            }
        }

        getImage()

    }, [viewImage])

    const handleAttachmentDelete = (attachmentID, attachmentName) => {
        setAttachmentId(attachmentID)
        setAttachmentName(attachmentName)
        setConfirmDialog(true)

    }

    const delAttachment = async () => {
        if (!attachmentId) {
            return
        }

        try {
            await apiFetch(`/orders/${orderDetails?.ID}/attachments/${attachmentId}`, "DELETE")
            callback()
        } catch (e) {
            console.log(e)
        }
    }

    const isImage = (row) => {
        return ["image/png", "image/jpeg"].includes(row?.ContentType)
    }

    const handleDownloadPlain = async (uuid, filename) => {
        if (filename === "") {
            filename = uuid
        }
        try {
            const data = await apiFetch(`/attachments/${uuid}`)
            const url = window.URL.createObjectURL(data);
            const link = document.createElement("a");
            link.href = url;
            link.download = filename;
            link.click();
            // It's necessary to revoke the object URL to avoid memory leaks
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Fetch operation failed: " + error);
        }
    };

    const getDownloadButton = (attachment) => {
        const attachmentUUID = attachment.UUID
        const messageID = attachment.Name

        return (<>
            <Button disabled={attachmentUUID === ""} size="small" variant="contained" color="primary" onClick={() => handleDownloadPlain(attachmentUUID, messageID)}>Download</Button>
        </>)
    }


    const getLink = (attachment) => {
        console.log('contentType', attachment.ContentType)
        switch (attachment.ContentType) {
            case "text/plain":
                return getDownloadButton(attachment)
            default:
                const link = "/orders/" +
                    orderDetails?.ID +
                    "/attachment/" +
                    attachment?.UUID
                return <a href={link}>{attachment.Name}</a>
        }
    }

    const header = () => {
        return (
            <TableHead>
                <TableRow>
                    {session?.user?.Type === "Lab" &&
                        <TableCell size="small" sx={{ padding: "0" }}>
                        </TableCell>}
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Date Added</TableCell>
                    <TableCell>Added By</TableCell>
                </TableRow>
            </TableHead>
        );
    };
    if (orderDetails.Attachments === null) {
        return <Table size="small">{header()}</Table>;
    }
    return (
        <>
            <ConfirmDialog
                title={"Are you sure?"}
                message={`You are about to delete '${attachmentName}'.`}
                open={confirmDialog}
                cancel={() => setConfirmDialog(false)}
                confirm={() => { delAttachment(); setConfirmDialog(false) }}
            />
            <TableContainer>
                <Table style={{ width: "auto", tableLayout: "auto" }}>
                    {header()}
                    <TableBody>
                        {patientAttachments.map((row) => {
                            return (
                                <>
                                    <TableRow key={row.ID}>
                                        <TableCell size="small" sx={{ padding: "0" }}>
                                            <Button onClick={() => navigate(`/patients/${orderDetails?.Patient?.ID}`)}
                                                size="small"
                                                sx={{ minWidth: "12px" }}>
                                                <Person color="primary" />
                                            </Button>

                                        </TableCell>
                                        <TableCell>
                                            {isImage(row) ?
                                                <Button
                                                    disabled={loadingImage}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        if (viewImage !== row?.UUID) {
                                                            setImageView(row?.UUID)
                                                        } else {
                                                            const newB = { ...imageB64 }
                                                            delete newB[row?.UUID]
                                                            setImageView(null); setImageB64({ ...newB })
                                                        }
                                                    }}>{Object.getOwnPropertyNames(imageB64).includes(row?.UUID) ? "Hide" : "View"}</Button> :
                                                getLink(row)}
                                        </TableCell>
                                        <TableCell>{row.Type}</TableCell>
                                        <TableCell>
                                            {FormatDateTime(row.CreatedAt)}
                                        </TableCell>
                                        <TableCell>{row.AddedBy.Email}</TableCell>
                                    </TableRow>
                                    {Object.getOwnPropertyNames(imageB64).includes(row?.UUID) &&
                                        <TableRow>
                                            <TableCell colSpan={4} >
                                                <ModalImage small={imageB64[row?.UUID]} medium={imageB64[row?.UUID]} showRotate />
                                            </TableCell>
                                        </TableRow>}
                                </>
                            );
                        })}
                        {orderDetails.Attachments.filter((row) => {
                            if (isLabUser) { return true };
                            return row.Type !== 'IntegrationMessage'

                        }).map((row) => {
                            return (
                                <>
                                    <TableRow key={row.ID}>
                                        {session?.user?.Type === "Lab" &&
                                            <TableCell size="small" sx={{ padding: "0" }}>
                                                <Button onClick={() => handleAttachmentDelete(row.ID, row.Name)}
                                                    size="small"
                                                    sx={{ minWidth: "12px" }}>
                                                    <CloseIcon color="error" />
                                                </Button>
                                            </TableCell>}
                                        <TableCell>
                                            {isImage(row) ?
                                                <Button
                                                    disabled={loadingImage}
                                                    variant="outlined"
                                                    onClick={() => {
                                                        if (viewImage !== row?.UUID) {
                                                            setImageView(row?.UUID)
                                                        } else {
                                                            const newB = { ...imageB64 }
                                                            delete newB[row?.UUID]
                                                            setImageView(null); setImageB64({ ...newB })
                                                        }
                                                    }}>{Object.getOwnPropertyNames(imageB64).includes(row?.UUID) ? "Hide" : "View"}</Button> :
                                                getLink(row)}
                                        </TableCell>
                                        <TableCell>{row.Type}</TableCell>
                                        <TableCell>
                                            {FormatDateTime(row.CreatedAt)}
                                        </TableCell>
                                        <TableCell>{row.AddedBy.Email}</TableCell>
                                    </TableRow>
                                    {Object.getOwnPropertyNames(imageB64).includes(row?.UUID) &&
                                        <TableRow>
                                            <TableCell colSpan={4} >
                                                <ModalImage small={imageB64[row?.UUID]} medium={imageB64[row?.UUID]} showRotate />
                                            </TableCell>
                                        </TableRow>}
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
                <ErrorAlert error={imageError} />
            </TableContainer>
        </>
    );
}
