import { InputLabel } from "@mui/material";
import React from "react";
import { CustomTextField } from "../components/CustomTextField";
import FacilityTableSelector from "../components/fields/FacilityTableSelector";
import PanelForm from "../components/PanelForm";
import { ColumnStack, RowStack } from "../components/Stacks";
import { useSalesRep, useCreateSalesRep, useUpdateSalesRep } from "../services/queries";
import salesrepValidation, { salesrepInitialValues } from "../validations/salesreps";

type SalesRepPanelFormProps = {
	onBack: () => void;
	onSave: () => void;
	id?: number;
}

export function SalesRepPanelForm({ id, onBack, onSave }: SalesRepPanelFormProps) {
	const salesrep = useSalesRep(id)

	return (
		<PanelForm
			validationSchema={salesrepValidation}
			form={<SalesRepForm />}
			createMutation={useCreateSalesRep()}
			updateMutation={useUpdateSalesRep()}
			initialValues={salesrep.isSuccess ? salesrep.data : salesrepInitialValues}
			onBack={onBack}
			onSave={onSave}
		/>
	)
}

export default function SalesRepForm() {
	return (
		<ColumnStack>
			<RowStack>
				<CustomTextField name="first_name" label="First Name" required />
				<CustomTextField name="last_name" label="Last Name" required />
				<CustomTextField name="phone" label="Phone" />
				<CustomTextField name="email" label="Email" />
			</RowStack>
			<InputLabel>Facilities</InputLabel>
			<FacilityTableSelector name="facilities" />
		</ColumnStack>
	)
}
