import React from "react";
import { Stack, StackProps } from "@mui/material";

const RowStack = (props: StackProps) => <Stack
    direction={{ 'md': "row", 'sm': "column" }}
    spacing={{ 'xs': 1, 'sm': 1, 'md': 1, 'lg': 1, 'xl': 1 }}
    {...props} />

const ColumnStack = (props: StackProps) => <Stack
    direction="column"
    spacing={{ 'xs': 2, 'sm': 2, 'md': 2, 'lg': 2, 'xl': 2 }}
    {...props} />


export { RowStack, ColumnStack };
