import React from "react";
import { useReportGroups } from "../../services/queries";
import ReactQueryAutocomplete, { ReactQueryAutocompleteProps } from "../ReactQueryAutocomplete";

type ReportGroupAutoCompleteProps = Omit<ReactQueryAutocompleteProps, "query">;

export default function ReportGroupAutoComplete(props: ReportGroupAutoCompleteProps) {
    return (
        <ReactQueryAutocomplete {...{ ...props, query: useReportGroups }} />
    )
}
