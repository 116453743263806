import React, { useState, useEffect, useMemo, ReactNode, useCallback } from "react";

import {
	Box,
	Stack,
	TextField,
	Autocomplete,
	Button,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	Checkbox,
	FormControlLabel,
	SelectChangeEvent,
} from "@mui/material";
import { Facility, FacilityReportServiceTestMapping, Test } from "../constants/types";
import ErrorAlert from "../components/ErrorAlert";

import {
	GridToolbar,
	GridActionsCellItem,
	GridColDef,
	GridRowParams,
	useGridApiRef,
	GridRenderEditCellParams,
	DataGridPro,
} from "@mui/x-data-grid-pro";
import { Edit, Delete } from "@mui/icons-material";
import { apiFetch } from "../services/fetch";
import { ReportServiceMap, ReportServices } from "../constants/constants";
import { usePermission } from "../hooks/usePermission";

const deleteRow = async (id: number, setError: React.Dispatch<React.SetStateAction<string | null>>, setRefresh: React.Dispatch<React.SetStateAction<boolean>>) => {
	try {
		await apiFetch(`/facilities/reportService/${id}`, 'DELETE')
		setError(null);
		setRefresh((refresh) => !refresh);
	} catch (e) {
		console.log(e)
		setError("Failed to delete row");
	}
}


function FacilityReportServiceMappings() {
	const apiRef = useGridApiRef()
	const initMapping = {
		Test: null,
		TestCode: 0,
		Facility: null,
		FacilityID: 0,
		ReportServiceID: 3,
		Enable: false,
	}

	const [refresh, setRefresh] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [tests, setTests] = useState<Test[]>([]);
	const [facilities, setFacilities] = useState<Facility[]>([]);
	const [mappings, setMappings] = useState<FacilityReportServiceTestMapping[]>([]);
	const [newMappingError, setNewMappingError] = useState({});
	const [newMapping, setNewMapping] = useState<FacilityReportServiceTestMapping>(initMapping);
	const canEdit = usePermission("facilities:write")

	useEffect(() => {
		const init = async () => {
			let response = await apiFetch("/facilities/reportService");
			setMappings(response);
			response = await apiFetch("/tests");
			setTests(response);
			response = await apiFetch("/facilities");
			setFacilities(response);
		};

		init();
	}, [refresh]);

	const addMapping = () => {
		if (newMapping.TestCode === 0) {
			setNewMappingError({
				...newMappingError,
				TestCode: "Test code is required",
			});
			return;
		} else if (!newMapping.FacilityID) {
			setNewMappingError({
				...newMappingError,
				FacilityID: "Facility is required",
			});
			return;
		} else if (!newMapping.ReportServiceID) {
			setNewMappingError({
				...newMappingError,
				ReportServiceID: "Report Service is required",
			});
			return;
		}


		setNewMappingError({});

		const add = async () => {
			try {
				await apiFetch(
					`/facilities/reportService`,
					'POST',
					/* @ts-ignore */
					newMapping
				);
				setError(null);
				setRefresh((refresh) => !refresh);
				setNewMapping(initMapping);
			} catch (error) {
				console.log(error)
				setError("Failed to add row");
			}
		};
		add();
	};

	const saveEdit = (newRow: any, oldRow: any) => {
		newRow.id = newRow.ID
		apiRef.current.stopRowEditMode(newRow)
		console.log('oldValues', oldRow)
		console.log('newValues', newRow)
		// newRow.TestCode = newRow.Test?.Code
		const updateRow = async () => {
			try {
				await apiFetch(
					`/facilities/reportService`,
					'POST',
					newRow
				);
				setError(null);
				setRefresh(!refresh);
			} catch (error) {
				console.log(error)
				setError("Failed to update row");
			}

			return newRow;
		};

		return updateRow();
	};
	const RenderReportServiceValueCell = useCallback((params: GridRenderEditCellParams): ReactNode => {
		const { id, value } = params;
		const handleValueChange = (
			e: SelectChangeEvent<any>,
			_: React.ReactNode,
		) => {
			apiRef.current.setEditCellValue({ id, field: "ReportServiceID", value: e.target.value });
		};

		return (<FormControl>
			<InputLabel>Report Service ID</InputLabel>
			<Select
				label="Report Service ID"
				sx={{ minWidth: "200px" }}
				size="small"
				value={value}
				onChange={handleValueChange}
			>
				{ReportServices.map((reportService) => (
					<MenuItem value={reportService.ID} key={reportService.ID}>
						{reportService.Name}
					</MenuItem>
				))}
			</Select>
		</FormControl>)
	}, [apiRef])

	const RenderTestValueCell = useCallback((params: GridRenderEditCellParams): ReactNode => {
		const { id, value, field } = params;
		const handleValueChange = (
			_: React.SyntheticEvent<Element, Event>,
			newValue: Test | null,
		) => {
			console.log(newValue)
			console.log(field)
			apiRef.current.setEditCellValue({ id, field: "Test", value: newValue });
			if (newValue) {
				apiRef.current.setEditCellValue({ id, field: "TestCode", value: newValue?.Code });
			}
		};

		return (
			<Autocomplete
				options={tests}
				getOptionLabel={(option) =>
					option.Name || String(option)
				}
				value={value}
				sx={{ minWidth: "200px", width: "100%" }}
				isOptionEqualToValue={(
					option,
					value
				) => option.Code === value}
				onChange={handleValueChange}
				renderInput={(params) => (
					<TextField
						{...params}
					/>
				)}
			/>
		)
	}, [apiRef, tests]);

	const RenderFacilityValueCell = useCallback((params: GridRenderEditCellParams): ReactNode => {
		const { id, value, field } = params;
		const handleValueChange = (
			_: React.SyntheticEvent<Element, Event>,
			newValue: Facility | null,
		) => {
			console.log(newValue)
			console.log(field)
			apiRef.current.setEditCellValue({ id, field: "Facility", value: newValue });
			if (newValue) {
				apiRef.current.setEditCellValue({ id, field: "FacilityID", value: newValue?.ID });
			}
		};

		return (
			<Autocomplete
				options={facilities}
				getOptionLabel={(option) =>
					option.Name || String(option)
				}
				value={value}
				sx={{ minWidth: "200px", width: "100%" }}
				isOptionEqualToValue={(
					option,
					value
				) => option.Code === value}
				onChange={handleValueChange}
				renderInput={(params) => (
					<TextField
						{...params}
					/>
				)}
			/>
		)
	}, [apiRef, facilities]);

	const getActions = useCallback((params: GridRowParams) => {
		return [
			<GridActionsCellItem disabled={!canEdit || apiRef.current.getRowMode(params.id) !== "view"} icon={<Edit />} onClick={() => apiRef.current.startRowEditMode({ id: params.id })} label="Edit" />,
			<GridActionsCellItem icon={<Delete />} disabled={!canEdit} onClick={() => deleteRow(Number(params.id), setError, setRefresh)} label="Delete" />
		]
	}, [apiRef, canEdit])

	const columns: GridColDef[] = useMemo(() => [
		{ field: "ReportServiceID", headerName: "Report Service", width: 150, valueFormatter: (params) => params?.value?.Name, renderEditCell: RenderReportServiceValueCell, editable: true, renderCell: ({ value }) => ReportServiceMap[value] },
		{ field: "FacilityID", headerName: "Facility", editable: true, width: 200, renderEditCell: RenderFacilityValueCell, renderCell: (params) => params.row.Facility?.Name },
		{ field: "TestCode", headerName: "Test Code", editable: true, width: 200, renderEditCell: RenderTestValueCell, renderCell: (params) => params.row.Test?.Name },
		{ field: "Enable", headerName: "Enabled", editable: true, type: "boolean", width: 75 },
		{ field: "actions", type: "actions", getActions: getActions },
	], [RenderTestValueCell, RenderFacilityValueCell, RenderReportServiceValueCell, getActions])

	return <>
		<Stack direction="column" spacing={2}>
			<Stack direction={{ md: "column", lg: "row" }} spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
				<FormControl>
					<InputLabel>Report Service ID</InputLabel>
					<Select
						label="Report Service ID"
						sx={{ minWidth: "200px" }}
						size="small"
						value={newMapping?.ReportServiceID}
						onChange={(e) =>
							setNewMapping({
								...newMapping,
								ReportServiceID: Number(e.target.value),
							})
						}
					>
						{ReportServices.map((reportService) => (
							<MenuItem value={reportService.ID} key={reportService.ID}>
								{reportService.Name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<Autocomplete
					options={facilities}
					getOptionLabel={(option) =>
						option.Name
					}
					sx={{ minWidth: "200px" }}
					value={newMapping?.Facility}
					size="small"
					onChange={(_, newValue) =>
						setNewMapping({
							...newMapping,
							FacilityID: newValue?.ID || null,
							Facility: newValue,
						})
					}
					renderInput={(params) => (
						<TextField
							label="Facility"
							{...params} />
					)}
				/>
				<Autocomplete
					options={tests}
					getOptionLabel={(option) =>
						option.Name
					}
					sx={{ minWidth: "200px" }}
					value={newMapping?.Test}
					size="small"
					onChange={(_, newValue) =>
						setNewMapping({
							...newMapping,
							TestCode: newValue?.Code || null,
							Test: newValue,
						})
					}
					renderInput={(params) => (
						<TextField
							label="Test"
							{...params} />
					)}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={newMapping?.Enable}
							onChange={(e) => setNewMapping({
								...newMapping,
								Enable: e.target.checked,
							})}
						/>}
					label="Enable"
				/>
				<Button variant="contained" color="primary" onClick={() => addMapping()} disabled={!canEdit}>Add</Button>
			</Stack>
			<Box height={`calc(100vh - 425px)`}>
				<DataGridPro
					getRowId={(row) => row.ID}
					apiRef={apiRef}
					columns={columns}
					rows={mappings}
					editMode="row"
					processRowUpdate={saveEdit}
					onProcessRowUpdateError={(e) => { console.log(e); setError("Failed to update row") }}
					getRowClassName={(params) =>
						params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
					}
					density="compact"
					slots={{ toolbar: GridToolbar }}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
				/>
			</Box>
			<ErrorAlert error={error} />
		</Stack>
	</>;

}

export default FacilityReportServiceMappings;
