import { InputLabel } from "@mui/material";
import React from "react";
import { CustomCheckboxField, CustomTextField } from "../components/CustomTextField";
import FacilityTableSelector from "../components/fields/FacilityTableSelector";
import PanelForm from "../components/PanelForm";
import { ColumnStack, RowStack } from "../components/Stacks";
import { useCollector, useCreateCollector, useUpdateCollector } from "../services/queries";
import collectorValidation, { collectorInitialValues } from "../validations/collectors";

type CollectorPanelFormProps = {
	onBack: () => void;
	onSave: () => void;
	id?: number;
}

export function CollectorPanelForm({ id, onBack, onSave }: CollectorPanelFormProps) {
	const collector = useCollector(id)

	return (
		<PanelForm
			validationSchema={collectorValidation}
			form={<CollectorForm />}
			createMutation={useCreateCollector()}
			updateMutation={useUpdateCollector()}
			initialValues={collector.isSuccess ? collector.data : collectorInitialValues}
			onBack={onBack}
			onSave={onSave}
		/>
	)
}

export default function CollectorForm() {
	return (
		<ColumnStack>
			<RowStack>
				<CustomTextField name="first_name" label="First Name" required />
				<CustomTextField name="last_name" label="Last Name" required />
				<CustomTextField name="phone" label="Phone" />
				<CustomTextField name="email" label="Email" />
				<CustomCheckboxField name="is_phlebotomist" label="Is Phlebotomist" />
			</RowStack>
			<InputLabel>Facilities</InputLabel>
			<FacilityTableSelector name="facilities" />
		</ColumnStack>
	)
}
