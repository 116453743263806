import { Autocomplete, Link, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";


type LOINCSelectorProps = {
    name: string;
    label: string
}

export default function LOINCSelector({ name, label }: LOINCSelectorProps) {
    const [loincList, setLoincList] = useState([]);
    const [loincInput, setLoincInput] = useState("");
    const [error, setError] = useState(null);
    const [field, _, helpers] = useField(name);
    const { values, setFieldValue } = useFormikContext<any>();

    useEffect(() => {
        if (loincInput === "") {
            return;
        }
        fetch(
            `https://clinicaltables.nlm.nih.gov/api/loinc_items/v3/search?terms=${loincInput}&df=text,LOINC_NUM,AnswerLists&sf=text,LONG_COMMON_NAME,LOINC_NUM&maxList=25`
        )
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load medication list.");
                }

                return p.json();
            })
            .then((p) => {
                let loincs = p[3].map((row: any) => {
                    return { Description: row[0], LOINC: row[1] };
                });

                setLoincList(loincs);
            })
            .catch((e) => setError(e.message));
    }, [loincInput]);


    return (
        <Autocomplete
            options={loincList}
            getOptionLabel={(option) => {
                if (option?.LOINC === undefined) {
                    return option;
                }
                return option.LOINC + " - " + option.Description;
            }}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option, value) => {
                return option.LOINC === value;
            }}
            value={field.value || ""}
            onChange={(_, v) => {
                helpers.setValue(v.LOINC);
                if (values?.description === "") {
                    setFieldValue("description", v.Description)
                }
            }}
            sx={{ width: "100%" }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        error={!!error}
                        size="small"
                        defaultValue={field.value}
                        onChange={(e) =>
                            setLoincInput(e.target.value)
                        }
                        label={label}
                        helperText={
                            field.value !== undefined ? (
                                <Link
                                    href={`https://loinc.org/${field.value}`}
                                    target="_blank"
                                >{`https://loinc.org/${field.value}`}</Link>
                            ) : null
                        }
                    />
                );
            }}
        />
    )
}
