import * as Yup from 'yup'

export const facilityMappingInitialValues = {
	"facility_id": 0,
	"external_facility": "",
	"instance_id": 0,
}

const facilityMappingValidation = Yup.object().shape({
	"facility_id": Yup.number().required(),
	"external_facility": Yup.string(),
	"instance_id": Yup.number().required(),
})

export default facilityMappingValidation;
