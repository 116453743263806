import { GridColDef } from "@mui/x-data-grid-pro"
import React from "react"
import { useMemo } from "react"
import EntityPanelTable, { EntityPanelTableProps } from "../components/EntityPanelTable"
import { Test } from "../constants/go_types"
import { useReflexes, useTests } from "../services/queries"

type ReflexPanelTableProps = Omit<EntityPanelTableProps, "columns" | "query">

export default function ReflexPanelTable(props: ReflexPanelTableProps) {
	const tests = useTests()

	const columns: GridColDef[] = useMemo(() => [
		{ field: "id", headerName: "ID", width: 45 },
		{ field: "auto_order", headerName: "Auto Order", width: 100, type: "boolean" },
		{ field: "force_order", headerName: "Force Order", width: 100, type: "boolean" },
		{ field: "description", headerName: "Description", width: 275 },
		{ field: "source_test_id", headerName: "Source Test", width: 125, valueGetter: (params) => tests.data?.find((t: Test) => t.id === params.value)?.name },
		{ field: "dest_test_id", headerName: "Destination Test", width: 125, valueGetter: (params) => tests.data?.find((t: Test) => t.id === params.value)?.name },
		{ field: "reflex_range_type", headerName: "Type", width: 125 },
	], [tests.data])

	return (
		<EntityPanelTable columns={columns} query={useReflexes()} {...props} />

	)
}
