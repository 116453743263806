import React, { useRef, useEffect, useState } from "react";
import { Button, Dialog, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Barcode from "react-barcode";
import { useReactToPrint } from "react-to-print";
import { FormatDateTime } from "../services/util";
import { Stack } from "@mui/system";
import Box from "@mui/material/Box";
import { FormatDate, FormatDateUTC } from "../services/util";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { ColumnStack } from "./Stacks";
dayjs.extend(utc);

const truncateName = (str) => {
    const MAX_LENGTH = 18
    if (str.length > MAX_LENGTH) {
        return str.slice(0, MAX_LENGTH) + "..."
    }
    return str
}

export const ComponentToPrint = React.forwardRef((props, ref) => {


    return (
        <div
            ref={ref}
            className="print-container"
            style={{ margin: "0", padding: "0" }}
        >
            {props.samples.map((sample, i) => (
                <>
                    {i !== 0 && <div className="page-break" />}

                    <Box id="labelsize" width="2.25in" height="1.25in" p={1} key={i}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography
                                variant="body1"
                                align="left"
                                fontSize={10}
                                m={0}
                            >
                                {sample.LastName}, {sample.FirstName}
                            </Typography>
                            <Typography
                                variant="body1"
                                align="right"
                                fontSize={10}
                                m={0}
                            >
                                {sample.DOB}
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography
                                variant="body1"
                                align="left"
                                fontSize={10}
                                m={0}
                            >
                                {truncateName(sample.Specimen)}
                            </Typography>
                            <Typography
                                variant="body1"
                                align="right"
                                fontSize={10}
                                m={0}
                            >
                                {truncateName(sample.Container)}
                            </Typography>
                        </Stack>
                        <Box width="100%" align="center">
                            <Barcode
                                format="CODE128B"
                                height={30}
                                width={.94}
                                margin={0}
                                marginBottom={0}
                                fontSize={10}
                                value={sample.Barcode}
                            />
                        </Box>
                        <Typography
                            variant="body1"
                            align="left"
                            fontSize={10}
                            m={0}
                        >
                            Collected: {sample.Collected}
                        </Typography>
                    </Box>
                </>
            ))
            }
        </div>
    );
});

export default function SampleBarcodes({ samples }) {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <ComponentToPrint
                ref={componentRef}
                samples={samples}
            />
            <ColumnStack>
                <Button variant="contained" onClick={handlePrint}>Print</Button>
            </ColumnStack>
        </>
    );
}

const zpl300dpi = (sample) => `
^XA
^PW675                        
^LL375                       
^LH0,0                      
^FO30,30^ADN,27,15^FD${sample.LastName}, ${sample.FirstName}^FS
^FO30,74^ADN,27,15^FD${sample.DOB}^FS
^FO30,118^ADN,27,15^FD${sample.Specimen}^FS
^FO30,162^ADN,27,15^FD${sample.Container}^FS
^FO165,220^BCN,89,Y,N,N^FD${sample.Barcode}^FS
^FO30,350^ADN,26,13^FDCollected: ${sample.Collected}^FS
^XZ

`
const zpl203dpi = (sample) => `
^XA
^PW456
^LL254
^LH0,5
^FO10,20^ADN,20,10^FD${sample.LastName}, ${sample.FirstName}^FS
^FO330,20^ADN,20,10^FD${sample.DOB}^FS
^FO10,50^ADN,20,10^FD${sample.Specimen}^FS
^FO300,50^ADN,20,10^FD${sample.Container}^FS
^FO80,100^BCN,60,Y,N,N^FD${sample.Barcode}^FS
^FO30,200^ADN,20,10^FDCollected: ${sample.Collected}^FS
^XZ
`

export function BarcodeDialog({ samples, open, setOpen }) {
    const [printer, setPrinter] = useState(null);
    const [printerStateError, setPrinterStateError] = useState(null); // empty if printer is ready other wise shows error

    console.log('printer', printer)
    const generateZPL = (samples) => {
        let zpl = '';
        samples.forEach((sample) => {
            zpl += zpl203dpi(sample);
        });
        return zpl;
    };

    const printLabels = () => {
        if (!printer) {
            console.error('Printer not ready');
            return;
        }
        const zpl = generateZPL(samples);
        printer.send(
            zpl,
            () => console.log('Labels sent to printer'),
            (error) => console.error('Print error:', error)
        );
    };

    const isPrinterReady = async () => {
        try {
            await printer.isPrinterReady()
            setPrinterStateError(null)
        } catch (e) {
            console.error('printer not ready', e)
            setPrinterStateError(e)
        }
    }

    useEffect(() => {
        if (!open) {
            return
        }
        // Check for default printer first
        window.BrowserPrint.getDefaultDevice(
            'printer',
            (device) => {
                if (device) {
                    setPrinter(new window.Zebra.Printer(device));
                } else {
                    // If no default, discover all printers
                    window.BrowserPrint.getLocalDevices((deviceList) => {
                        const zebraPrinter = deviceList.find((d) => d.manufacturer === 'Zebra');
                        if (zebraPrinter) {
                            console.log('zebra', zebraPrinter)
                            setPrinter(new window.Zebra.Printer(zebraPrinter));
                        } else {
                            console.error('No Zebra printer found');
                        }
                    });
                }
            },
            (error) => console.error('Error getting default device:', error)
        );

    }, [open]);

    useEffect(() => {
        if (!printer || !open) {
            return
        }
        isPrinterReady()
    }, [printer, open])

    return (
        <Dialog open={open}>
            <DialogTitle>Barcodes</DialogTitle>
            <DialogContent>
                <SampleBarcodes samples={samples} />

                {printer &&
                    <ColumnStack sx={{ mt: 2 }}>
                        <Button variant="contained" disabled={!!printerStateError} onClick={printLabels}>Zebra Browser Print</Button>
                    </ColumnStack>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
