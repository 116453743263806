import React, { useMemo, useCallback, useRef } from "react";
import { Box } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRowParams, } from "@mui/x-data-grid-pro";
import { Delete } from "@mui/icons-material";
import { useCreateIntegrationFacilityMapping, useDeleteIntegrationFacilityMapping, useIntegrationInstancesDeprecated, useIntegrationFacilityMappings, useFacilitiesDeprecated } from "../services/queries";
import { ColumnStack, RowStack } from "../components/Stacks";
import { CustomTextField } from "../components/CustomTextField";
import IntegrationInstanceAutoComplete from "../components/fields/IntegrationInstances";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import facilityMappingValidation, { facilityMappingInitialValues } from "../validations/facility_mappings";
import { AxiosError } from "../components/AxiosError";
import { IntegrationFacilityMapping } from "../constants/go_types";
import { SaveButton } from "../components/Buttons";
import PanelDataGrid from "../components/PanelDataGrid";
import FacilityAutoComplete from "../components/fields/Facilities";
import { DataGridActionDelete } from "../components/DataGridActionDelete";
import { usePermission } from "../hooks/usePermission";

function FacilityMappings() {
	const ref = useRef<FormikProps<any>>(null);
	const facilities = useFacilitiesDeprecated()
	const mappings = useIntegrationFacilityMappings()
	const addMapping = useCreateIntegrationFacilityMapping()
	const instances = useIntegrationInstancesDeprecated()
	const canEdit = usePermission("interfaces:write")

	const handleSubmit = async (values: IntegrationFacilityMapping) => {
		addMapping.mutate(values)
		if (ref.current) {
			ref.current.resetForm()
		}
	}


	const columns: GridColDef[] = useMemo(() => [
		{ field: "facility_id", headerName: "Internal Facility", width: 200, valueGetter: (params) => facilities.data?.find((t) => t.ID === params.value)?.Name },
		{ field: "external_facility", headerName: "External Facility", width: 200 },
		{ field: "instance_id", headerName: "Integration Instance", width: 300, valueGetter: (params) => instances.data?.find((i: any) => i.ID === params.value)?.Name },
		{ field: "actions", type: "actions", getActions: (params) => [<DataGridActionDelete disabled={!canEdit} useMutation={useDeleteIntegrationFacilityMapping} params={params} />] },
	], [facilities.data, instances.data, canEdit])

	return <>
		<ColumnStack height="90%">
			<Box>
				<Formik
					initialValues={facilityMappingInitialValues}
					validationSchema={facilityMappingValidation}
					onSubmit={handleSubmit}
					innerRef={ref}
				>
					<Form>
						<FacilityMappingForm />
					</Form>
				</Formik>
			</Box >
			<Box height="100%" >
				<PanelDataGrid
					storageName="facility-mapping"
					columns={columns}
					rows={mappings.data ?? []}
				/>
			</Box>
			<AxiosError error={mappings.error || addMapping.error} />
		</ColumnStack>
	</>;
}

function FacilityMappingForm() {
	const { isValid, isSubmitting } = useFormikContext()
	const canEdit = usePermission("interfaces:write")
	return (
		<RowStack alignItems="flex-start">
			<FacilityAutoComplete
				required
				name="facility_id"
				label="Internal Facility"
				field="id"
				labelField="name"
				sx={{ width: "100%" }}
			/>
			<CustomTextField name="external_facility" label="External Facility" sx={{ width: "100%" }} />
			<IntegrationInstanceAutoComplete
				required
				name="instance_id"
				label="Integration Instance"
				labelField="Name"
				field="ID"
				sx={{ width: "100%" }}
			/>
			<SaveButton type="submit" disabled={!canEdit || !isValid || isSubmitting} />
		</RowStack>
	)
}

export default FacilityMappings;




