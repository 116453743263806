import * as Yup from 'yup'

export const contactInitialValues = {
	"first_name": "",
	"last_name": "",
	"email": "",
	"phone_1": "",
	"phone_2": "",
	"fax": "",
	"email_results": false,
	"text_results": false,
	"fax_results": false,
	"email_orders": false,
	"text_orders": false,
	"fax_orders": false,
	"type": "",
	"critical": false,
	"notes": "",
	"facilities": [],
	"providers": []
}

const contactValidation = Yup.object().shape({
	"id": Yup.number().nullable(),
	"first_name": Yup.string().required(),
	"last_name": Yup.string().required(),
	"phone_1": Yup.string().matches(
		/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
		"Phone number is not valid"
	),
	"phone_2": Yup.string().matches(
		/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
		"Phone number is not valid"
	),
	"fax": Yup.string().matches(
		/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
		"Phone number is not valid"
	),
	"email": Yup.string().email(),
	"email_results": Yup.boolean(),
	"fax_results": Yup.boolean(),
	"text_results": Yup.boolean(),
	"email_orders": Yup.boolean(),
	"fax_orders": Yup.boolean(),
	"text_orders": Yup.boolean(),
	"type": Yup.string().required(),
	"facilities": Yup.array().of(Yup.number()).nullable(),
	"providers": Yup.array().of(Yup.number()).nullable(),
	"notes": Yup.string()
})

export default contactValidation;
