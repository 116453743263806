import React from 'react';
import { Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { UseMutationResult } from '@tanstack/react-query';
import ButtonTray from './ButtonTray';
import { BackButton, SaveButton } from './Buttons';
import { ColumnStack } from './Stacks';
import { AxiosError } from './AxiosError';

type PanelFormProps<T> = {
    validationSchema: Yup.ObjectSchema<any>;
    createMutation: UseMutationResult<any, unknown, any, unknown>;
    updateMutation: UseMutationResult<any, unknown, any, unknown>;
    form: React.JSX.Element;
    initialValues: T;
    onBack: () => void
    onSave: () => void
}

export default function PanelForm<T>({ validationSchema, createMutation, updateMutation, form, initialValues, onBack, onSave }: PanelFormProps<T>) {
    const handleSubmit = async (values: FormikValues) => {
        values = await validationSchema.cast(values)

        if ('id' in values && values.id) {
            await updateMutation.mutateAsync(values as T)
        } else {
            await createMutation.mutateAsync(values as T)
        }
        onSave()
    }

    return (
        <>
            <Formik
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                initialValues={initialValues as any}
                enableReinitialize
            >{({ isSubmitting }) => (
                <Form>
                    <ColumnStack>
                        {form}
                        {createMutation.isError && <AxiosError error={createMutation?.error} />}
                        {updateMutation.isError && <AxiosError error={updateMutation?.error} />}
                        <ButtonTray>
                            <BackButton disabled={isSubmitting} onClick={onBack} />
                            <></>
                            <SaveButton disabled={isSubmitting} />
                        </ButtonTray>
                    </ColumnStack>
                </Form>
            )}
            </Formik>
        </>
    )
}
