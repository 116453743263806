import * as Yup from 'yup'

export const salesrepInitialValues = {
	"first_name": "",
	"last_name": "",
	"email": "",
	"phone": "",
	"facilities": []
}

const salesrepValidation = Yup.object().shape({
	"id": Yup.number(),
	"first_name": Yup.string().required(),
	"last_name": Yup.string().required(),
	"phone": Yup.string(),
	"email": Yup.string().email(),
	"facilities": Yup.array().of(Yup.number()).nullable(),
})

export default salesrepValidation;
