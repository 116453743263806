import React, { useState, useRef } from "react";
import {
    Button,
    ButtonGroup,
    Paper,
    Popper,
    Grow,
    MenuList,
    MenuItem,
    ClickAwayListener,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

type ButtonListOptions = {
    Name: string,
    Action: () => void,
    Disabled?: boolean
}


function ButtonList({ options, withHeaderBtn, headerBtn, disabled }: { options: ButtonListOptions[], withHeaderBtn?: boolean, headerBtn: string, disabled: boolean }) {
    const anchorRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleMenuItemClick = (
        action: () => void
    ) => {
        action();
        setOpen(false);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} disabled={disabled}>
                {withHeaderBtn ? <Button sx={{ width: "100%" }} disabled={disabled} onClick={() => setOpen(true)}>{headerBtn}</Button> :
                    <Button sx={{ width: "100%" }} onClick={() => handleMenuItemClick(options[0].Action)}>{options[0].Name}</Button>}
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    disabled={disabled}
                    onClick={handleToggle}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            {/* @ts-ignore */}
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.slice(withHeaderBtn ? 0 : 1).filter((option) => !option.Disabled).map((option) => (
                                        <MenuItem
                                            key={option.Name}
                                            onClick={() => handleMenuItemClick(option.Action)}
                                        >
                                            {option.Name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    )

}

export default ButtonList;
