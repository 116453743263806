import { Box, Container, CssBaseline, Paper, PaperProps, Toolbar } from "@mui/material";
import AppBar from "../components/AppBar/AppBar";
import React from "react";
import Copyright from "../components/Copyright";

type Frameworkv2Props = {
    children: React.ReactNode;
    name: string;
    table?: boolean;
    props?: PaperProps
}

// terrible attempt, create v3... when you have more time
function Frameworkv2({ children, table, name, ...props }: Frameworkv2Props) {
    // if is table we'll increase maxwidth
    let maxWidth = "1600px";
    if (table) {
        maxWidth = "2400px";
    }

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar name={name} />
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                }}
            >
                <Toolbar />
                <Container
                    id="main-container"
                    maxWidth={false}
                    sx={{ maxWidth: maxWidth, padding: { xs: "0px", md: "16px" } }}
                >
                    <Paper
                        id="panel_paper"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            minHeight: "500px",
                            height: "100%",
                        }}
                        // margin={{ xs: 0, sm: 1, md: 2 }}
                        {...props}
                    >
                        {children}
                    </Paper>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
        </Box>
    );
}

export default Frameworkv2;
