import React, { useState, useEffect } from "react";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Orders from "./pages/Orders";
import NewOrder2 from "./pages/NewOrder2";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Results from "./pages/Results";
import Shipments from "./pages/Shipments";
import NewShipment from "./pages/NewShipment";
import NewSample from "./pages/NewSample";
import Samples from "./pages/Samples";
import Setup from "./pages/Setup";
import Facilities from "./pages/Facilities";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Patients from "./pages/Patients";
import Activate from "./pages/Register";
import ViewPDF from "./pages/ViewPDF";
import Criticals from "./pages/Criticals";
import { ProtectedRoute } from "./components/PrivateRoute";
import { decodeCookie, SessionContext } from "./hooks/getContext";
import { checkToken } from "./services/auth";
import { useLocation } from "react-router-dom";
import OrderDisplay from "./components/OrderDisplay";
import PatientPage from "./pages/PatientPage";
import PatientEdit from "./pages/PatientEdit";
import ShipmentView from "./components/ShipmentView";
import NewPatient from "./pages/NewPatient";
import Billing from "./pages/Billing";
import Interfaces from "./pages/Interfaces";
import PatientResult from "./pages/PatientResult";
import PatientOrder from "./pages/PatientOrder";
import ChartNotesPDF from "./pages/ChartNotesPDF";
import Reports from "./pages/Reports";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import mdTheme from "./components/Theme";

LicenseInfo.setLicenseKey(
    "4f1c625e62d46a23c8f2580d053085d4Tz01OTE5NCxFPTE3MDcxNjE5MDc4NjMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const queryClient = new QueryClient()

function App() {
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = decodeCookie();
        if (token !== null) {
            checkToken().then((r) => {
                if (r.ok) {
                    setUser(token);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setUser(null);
                }
            });
        } else {
            setLoading(false);
            setUser(null);
        }
    }, [location]);

    console.log('user', user)


    return (
        <ThemeProvider theme={mdTheme}>
            <QueryClientProvider client={queryClient}>
                <SessionContext.Provider value={{ user, setUser, loading, setLoading }}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute permission="orders:read">
                                    <Dashboard />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/orders"
                            element={
                                <ProtectedRoute permission="orders:read">
                                    <Orders />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/orders/:orderID"
                            element={
                                <ProtectedRoute permission="orders:read">
                                    <OrderDisplay withNav={true} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/orders/:orderID/chartNotes"
                            element={
                                <ProtectedRoute permission="orders:read">
                                    <ChartNotesPDF />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/orders/:orderID/resultReport"
                            element={
                                <ProtectedRoute permission="orders:read">
                                    <ViewPDF />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/orders/:orderID/printOrder"
                            element={
                                <ProtectedRoute permission="orders:read">
                                    <ViewPDF pdfType={"order"} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/orders/:orderID/attachment/:attachmentUUID"
                            element={
                                <ProtectedRoute permission="orders:read">
                                    <ViewPDF />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/criticals"
                            element={
                                <ProtectedRoute permission="criticals:read">
                                    <Criticals />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/samples"
                            element={
                                <ProtectedRoute permission="samples:read">
                                    <Samples />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/neworder"
                            element={
                                <ProtectedRoute permission="orders:write">
                                    <NewOrder2 />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/newsample"
                            element={
                                <ProtectedRoute permission="samples:write">
                                    <NewSample />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/shipments"
                            element={
                                <ProtectedRoute permission="shipments:read">
                                    <Shipments />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/shipments/:shipmentID"
                            element={
                                <ProtectedRoute permission="shipments:read">
                                    <ShipmentView />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/newshipment"
                            element={
                                <ProtectedRoute permission="shipments:write">
                                    <NewShipment />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/results"
                            element={
                                <ProtectedRoute permission="results:read">
                                    <Results />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/patients/:patientID"
                            element={
                                <ProtectedRoute permission="patients:read">
                                    <PatientPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/newPatient"
                            element={
                                <ProtectedRoute permission="patients:write">
                                    <NewPatient />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/patients/:patientID/attachment/:attachmentUUID"
                            element={
                                <ProtectedRoute permission="patients:read">
                                    <ViewPDF />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/patients/:patientID/edit"
                            element={
                                <ProtectedRoute permission="patients:write">
                                    <PatientEdit />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/patients"
                            element={
                                <ProtectedRoute permission="patients:read">
                                    <Patients />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/billing"
                            element={
                                <ProtectedRoute permission="billing:read">
                                    <Billing />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/facilities"
                            element={
                                <ProtectedRoute permission="facilities:read">
                                    <Facilities />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/setup"
                            element={
                                <ProtectedRoute permission="setup:read">
                                    <Setup />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/setup/tests"
                            element={
                                <ProtectedRoute permission="setup:read">
                                    <Setup tab={"tests"} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/setup/profiles"
                            element={
                                <ProtectedRoute permission="setup:read">
                                    <Setup tab={"profiles"} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/setup/methods"
                            element={
                                <ProtectedRoute permission="setup:read">
                                    <Setup tab={"methods"} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/setup/reflexes"
                            element={
                                <ProtectedRoute permission="setup:read">
                                    <Setup tab={"reflexes"} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/setup/departments"
                            element={
                                <ProtectedRoute permission="setup:read">
                                    <Setup tab={"departments"} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/setup/laboratories"
                            element={
                                <ProtectedRoute permission="setup:read">
                                    <Setup tab={"laboratories"} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/setup/feeschedules"
                            element={
                                <ProtectedRoute permission="setup:read">
                                    <Setup tab={"feeschedules"} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/interfaces"
                            element={
                                <ProtectedRoute permission="interfaces:read">
                                    <Interfaces />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/reports"
                            element={
                                // "Admin", "Technician", "Billing", "Office Staff"
                                <ProtectedRoute permission="reports:read">
                                    <Reports />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/viewPDF"
                            element={
                                <ProtectedRoute>
                                    <ViewPDF />
                                </ProtectedRoute>
                            }
                        />

                        <Route path="/result" element={<PatientResult />} />
                        <Route path="/patientOrder" element={<PatientOrder />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                        <Route path="/activate" element={<Activate />} />
                    </Routes>
                    <ReactQueryDevtools initialIsOpen={false} />
                </SessionContext.Provider>
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
