import React from "react"
import { Button, ButtonProps } from "@mui/material"

export const CreateButton = ({ ...props }: ButtonProps) => <Button
    variant="contained"
    color="primary"
    {...props}
>
    Create
</Button>
export const EditButton = ({ ...props }: ButtonProps) => <Button
    variant="contained"
    color="secondary"
    sx={{ color: "white" }}
    {...props}
>
    Edit
</Button>
export const BackButton = ({ ...props }: ButtonProps) => <Button
    variant="outlined"
    {...props}
>
    Back
</Button>

export const CloseButton = ({ ...props }: ButtonProps) => <Button
    variant="outlined"
    {...props}
>
    Close
</Button>


export const SaveButton = ({ ...props }: ButtonProps) => <Button
    type="submit"
    variant="contained"
    {...props}
>
    Save
</Button>

export const DeleteButton = ({ ...props }: ButtonProps) => <Button
    variant="contained"
    color="error"
    {...props}
>
    Delete
</Button>
