import React from "react";
import { useFacilities } from "../../services/queries";
import ReactQueryAutocomplete, { ReactQueryAutocompleteProps } from "../ReactQueryAutocomplete";

type FacilityAutoCompleteProps = Omit<ReactQueryAutocompleteProps, "query">;

export default function FacilityAutoComplete(props: FacilityAutoCompleteProps) {
    return (
        <ReactQueryAutocomplete {...{ ...props, query: useFacilities }} />
    )
}
