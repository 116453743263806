import React, { useState, useEffect } from "react";
import FacilitiesTable from "../components/FacilitiesTable";
import { getFacilities, getFacility } from "../services/facilities";
import { Button } from "@mui/material";
import FacilityInput from "../components/FacilityInput";
import ButtonTray from "./ButtonTray";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import { usePermission } from "../hooks/usePermission";

export default function FacilitySetup() {
    const newFacility = {
        Name: "",
        Address: {
            Address1: "",
            Address2: "",
            City: "",
            State: "",
            ZipCode: "",
        },
        Phone: "",
        Fax: "",
        Active: true,
        DisableReflexAutoOrder: false,
        CanSelfPay: false,
        CanClientBill: false,
        CanInsurance: false,
        CanNoBill: false,
        FrequencyAlgorithmID: 0,
        ReportOrderPreference: 0,
        FacilityID: 1,
        POS: "clinic",
        NPI: "",
        FEIN: "",
        Collectors: [],
        Providers: [],
        Contacts: [],
        TestFilter: [],
    };

    const [initialValues, setInitialValues] = useState(newFacility);
    const [editMode, setEditMode] = useState(false);
    const [createMode, setCreateMode] = useState(false);
    const [id, setId] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const canEdit = usePermission("facilities:write")

    useEffect(() => {
        if (createMode) {
            setInitialValues(newFacility);
            return
        }

        if (id.length === 1 && editMode) {
            getFacility(id[0])
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load facility");
                    }
                    return p.json();
                })
                .then((p) => {
                    setInitialValues(p);
                });
        }
    }, [id, editMode, createMode]);

    const reset = () => {
        setRefresh(!refresh);
        setEditMode(false);
        setCreateMode(false);
    };


    if (editMode || createMode) {
        return <FacilityInput initialValues={initialValues} reset={reset} />;
    }

    return (
        <TableFrame>
            <DataTableContainer>
                <FacilitiesTable
                    getFacilities={getFacilities}
                    Title=""
                    setSelectedFacilities={setId}
                    refresh={refresh}
                />
            </DataTableContainer>
            <ButtonTray>
                <Button
                    disabled={!canEdit}
                    variant="contained"
                    color="success"
                    onClick={() => {
                        setInitialValues(newFacility);
                        setCreateMode(true);
                    }}
                >
                    Create
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!canEdit || id.length !== 1}
                    onClick={() => setEditMode(true)}
                >
                    Edit
                </Button>
                <Button
                    variant="contained"
                    disabled={!canEdit || id.length !== 1}
                    color="error"
                >
                    Deactivate
                </Button>
            </ButtonTray>
        </TableFrame>
    );
}
