import React from "react";
import PanelDataGrid from "../components/PanelDataGrid";
import { GridColDef } from "@mui/x-data-grid-pro";
import { ColumnStack } from "../components/Stacks";
import ButtonTray from "../components/ButtonTray";
import { CreateButton, DeleteButton, EditButton } from "../components/Buttons";
import { Box } from "@mui/system";
import { UseQueryResult } from "@tanstack/react-query";
import { usePermission } from "../hooks/usePermission";

export type EntityPanelTableProps = {
    onCreate: () => void;
    onEdit: (id: number) => void;
    onDelete?: (id: number) => void;
    requiredPermission: string;
    storageName: string;
    query: UseQueryResult<any>;
    columns: GridColDef[];
    dataGripProps?: any;
    deleteButtonOverride?: (props: { id: number | undefined; canEdit: boolean, query: UseQueryResult<any> }) => React.JSX.Element;
}


function EntityPanelTable({ onEdit, onCreate, onDelete, requiredPermission, storageName, query, columns, dataGripProps, deleteButtonOverride }: EntityPanelTableProps) {
    const [id, setId] = React.useState<number | undefined>(undefined)
    const canEdit = usePermission(requiredPermission)

    // Default delete button logic
    const defaultDeleteButton = onDelete && (
        <DeleteButton
            onClick={() => onDelete(id!)}
            disabled={!canEdit || !id}
        />
    );

    return (
        <>
            <Box height="100%" maxHeight="100%" display="flex" flexDirection="column" overflow="auto">
                <Box flexGrow={1} display="flex" overflow="auto" minHeight="300px">
                    <PanelDataGrid
                        storageName={storageName}
                        columns={columns}
                        rows={query.data || []}
                        onRowSelectionModelChange={(v) => setId(v[0] as number)}
                        rowSelectionModel={id}
                        {...dataGripProps}
                    />
                </Box>
                <Box mt={1}>
                    <ButtonTray>
                        <CreateButton onClick={() => onCreate()} disabled={!canEdit} />
                        <EditButton onClick={() => onEdit(id!)} disabled={!canEdit || !id} />
                        {deleteButtonOverride ? deleteButtonOverride({ id: id, canEdit: canEdit, query: query }) : defaultDeleteButton}
                    </ButtonTray>
                </Box>
            </Box>
        </>
    )
}

export default EntityPanelTable;
