
import { GridColDef } from "@mui/x-data-grid-pro";
import { UseQueryResult } from "@tanstack/react-query";
import { useField } from "formik";
import React, { useMemo } from "react";
import { Test } from "../../constants/go_types";
import { useDepartments, useSubTests, useTests } from "../../services/queries";
import ReactQueryTableSelector, { ReactQueryTableSelectorProps } from "../ReactQueryTableSelector";

type TestTableSelectorProps = Omit<ReactQueryTableSelectorProps, "query" | "columns"> & {
    columns?: GridColDef[]
    query?: UseQueryResult<any>
    name: string
}

function priorityComparator(value1: string, value2: string) {
    // Check if the first value is an empty string
    if (value1 === "") {
        return 1; // Place it after the second value
    }
    // Check if the second value is an empty string
    if (value2 === "") {
        return -1; // Place it after the first value
    }
    // Convert values to numbers for comparison
    const num1 = Number(value1);
    const num2 = Number(value2);

    // Compare the numeric values
    if (num1 < num2) {
        return -1;
    }
    if (num1 > num2) {
        return 1;
    }
    return 0;
}

export default function SubTestTableSelector(props: TestTableSelectorProps) {
    const tests = useSubTests()
    const departments = useDepartments()
    const [field] = useField(props.name)

    const columns: GridColDef[] = useMemo(() => [
        {
            field: "priority",
            headerName: "Priority",
            width: 80,
            type: "number",
            valueGetter: (row) => {
                if (!field.value) {
                    return "";
                }

                const i = field.value.indexOf(row.id);
                if (i > -1) {
                    return i + 1;
                } else {
                    return "";
                }
            },
            sortComparator: priorityComparator
        },
        { field: "id", headerName: "ID", width: 75 },
        { field: "code", headerName: "Code", width: 100 },
        { field: "name", headerName: "Name", width: 250 },
        { field: "department_id", headerName: "Department", width: 250, valueGetter: (params) => departments.data?.find((d) => d.id === params.value)?.name },
    ], [field.value, departments.data])

    return <ReactQueryTableSelector query={props.query ? props.query : tests} columns={props.columns ? props.columns : columns} name={props.name}
        initialState={{ sorting: { sortModel: [{ field: "priority", sort: "asc" }] } }}
    />

}
