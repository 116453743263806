import React, { useState, useEffect, useMemo, ReactNode, useCallback } from "react";

import {
	Box,
	Stack,
	TextField,
	Autocomplete,
	Button,
	FormControl,
	Checkbox,
	FormControlLabel,
} from "@mui/material";
import { Facility, FrequencyRule, Test } from "../constants/types";
import ErrorAlert from "../components/ErrorAlert";

import {
	GridToolbar,
	GridActionsCellItem,
	GridColDef,
	GridRowParams,
	useGridApiRef,
	GridRenderEditCellParams,
	DataGridPro,
} from "@mui/x-data-grid-pro";
import { Edit, Delete } from "@mui/icons-material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { apiFetch } from "../services/fetch";
import { usePermission } from "../hooks/usePermission";

const deleteRow = async (id: number, setError: React.Dispatch<React.SetStateAction<string | null>>, setRefresh: React.Dispatch<React.SetStateAction<boolean>>) => {
	try {
		await apiFetch(`/frequency/${id}`, 'DELETE')
		setError(null);
		setRefresh((refresh) => !refresh);
	} catch (e) {
		console.log(e)
		setError("Failed to delete row");
	}
}


function FrequencyRules() {
	const apiRef = useGridApiRef()
	const initMapping = {
		Test: null,
		TestCode: 0,
		Frequency: 1,
		FrequencyWithUniqueDx: 1,
		TimeSpan: 1,
		OnAbnormalResult: false,
		PreventOrder: false,
	}

	const [refresh, setRefresh] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [tests, setTests] = useState<Test[]>([]);
	const [mappings, setMappings] = useState<FrequencyRule[]>([]);
	const [newMappingError, setNewMappingError] = useState({});
	const [newMapping, setNewMapping] = useState<FrequencyRule>(initMapping);
	const canEdit = usePermission("setup:write")

	useEffect(() => {
		const init = async () => {
			let response = await apiFetch("/frequency");
			setMappings(response);
			response = await apiFetch("/tests");
			setTests(response);
		};

		init();
	}, [refresh]);

	const addMapping = () => {
		if (newMapping.TestCode === 0) {
			setNewMappingError({
				...newMappingError,
				TestCode: "Test code is required",
			});
			return;
		} else if (newMapping.Frequency < 1) {
			setNewMappingError({
				...newMappingError,
				Frequency: "Frequency must be greater than zero",
			});
			return;
		} else if (newMapping.FrequencyWithUniqueDx < 1) {
			setNewMappingError({
				...newMappingError,
				FrequencyWithUniqueDx: "Frequency w/ unique diagnosis must be greater than zero",
			});
			return;
		} else if (newMapping.TimeSpan < 1) {
			setNewMappingError({
				...newMappingError,
				TimeSpan: "Timespan must be greater than zero",
			});
			return;
		}


		setNewMappingError({});

		const add = async () => {
			try {
				await apiFetch(
					`/frequency`,
					'POST',
					/* @ts-ignore */
					newMapping
				);
				setError(null);
				setRefresh((refresh) => !refresh);
				setNewMapping(initMapping);
			} catch (error) {
				console.log(error)
				setError("Failed to add row");
			}
		};
		add();
	};

	const saveEdit = (newRow: any, oldRow: any) => {
		newRow.id = newRow.ID
		apiRef.current.stopRowEditMode(newRow)
		console.log('oldValues', oldRow)
		console.log('newValues', newRow)
		// newRow.TestCode = newRow.Test?.Code
		const updateRow = async () => {
			try {
				await apiFetch(
					`/frequency`,
					'POST',
					newRow
				);
				setError(null);
				setRefresh(!refresh);
			} catch (error) {
				console.log(error)
				setError("Failed to update row");
			}

			return newRow;
		};

		return updateRow();
	};

	const filterOptions = createFilterOptions({
		matchFrom: "start",
		stringify: (option: Test) => option.Name,
	});


	const RenderTestValueCell = useCallback((params: GridRenderEditCellParams): ReactNode => {
		const { id, value, field } = params;
		const handleValueChange = (
			_: React.SyntheticEvent<Element, Event>,
			newValue: Test | null,
		) => {
			console.log(newValue)
			console.log(field)
			apiRef.current.setEditCellValue({ id, field: "Test", value: newValue });
			if (newValue) {
				apiRef.current.setEditCellValue({ id, field: "TestCode", value: newValue?.Code });
			}
		};

		return (
			<Autocomplete
				options={tests}
				filterOptions={filterOptions}
				getOptionLabel={(option) =>
					option.Name || String(option)
				}
				value={value}
				sx={{ minWidth: "200px", width: "100%" }}
				isOptionEqualToValue={(
					option,
					value
				) => option.Code === value.Code}
				onChange={handleValueChange}
				renderInput={(params) => (
					<TextField
						{...params}
					/>
				)}
			/>
		)
	}, [apiRef, tests]);


	const getActions = useCallback((params: GridRowParams) => {
		return [
			<GridActionsCellItem disabled={!canEdit || apiRef.current.getRowMode(params.id) !== "view"} icon={<Edit />} onClick={() => apiRef.current.startRowEditMode({ id: params.id })} label="Edit" />,
			<GridActionsCellItem icon={<Delete />} disabled={!canEdit} onClick={() => deleteRow(Number(params.id), setError, setRefresh)} label="Delete" />
		]
	}, [apiRef, canEdit])

	const columns: GridColDef[] = useMemo(() => [
		{ field: "TestCode", headerName: "Test Code", editable: true, width: 200, renderEditCell: RenderTestValueCell, renderCell: (params) => params.row.Test?.Name },
		{ field: "TimeSpan", headerName: "Timespan (days)", editable: true, type: "number", width: 150 },
		{ field: "Frequency", headerName: "Frequency (days)", editable: true, type: "number", width: 150 },
		{ field: "FrequencyWithUniqueDx", headerName: "Frequency w/ Unique Dx (days)", editable: true, type: "number", width: 150 },
		{ field: "OnAbnormalResult", headerName: "Abnormal Result", editable: true, type: "boolean", width: 150 },
		{ field: "PreventOrder", headerName: "Prevent Order", editable: true, type: "boolean", width: 150 },
		{ field: "actions", type: "actions", getActions: getActions },
	], [RenderTestValueCell, getActions])

	return <>
		<Stack direction="column" spacing={2}>
			<Stack direction={{ md: "column", lg: "row" }} spacing={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
				<Autocomplete
					options={tests}
					getOptionLabel={(option) =>
						option.Name
					}
					filterOptions={filterOptions}
					sx={{ minWidth: "200px" }}
					value={tests.find((t) => t.Code === newMapping?.TestCode)}
					size="small"
					onChange={(_, newValue) =>
						setNewMapping({
							...newMapping,
							TestCode: newValue?.Code || null,
						})
					}
					renderInput={(params) => (
						<TextField
							label="Test"
							{...params} />
					)}
				/>
				<FormControl>
					<TextField
						label="Timespan"
						type="number"
						size="small"
						value={newMapping.TimeSpan}
						onChange={(e) => setNewMapping({
							...newMapping,
							TimeSpan: parseInt(e.target.value)

						})}
					/>
				</FormControl>
				<FormControl>
					<TextField
						label="Frequency"
						type="number"
						size="small"
						value={newMapping.Frequency}
						onChange={(e) => setNewMapping({
							...newMapping,
							Frequency: parseInt(e.target.value)

						})}
					/>
				</FormControl>
				<FormControl>
					<TextField
						label="Frequency w/ Unique Dx"
						size="small"
						type="number"
						value={newMapping.FrequencyWithUniqueDx}
						onChange={(e) => setNewMapping({
							...newMapping,
							FrequencyWithUniqueDx: parseInt(e.target.value)

						})}
					/>
				</FormControl>
				<FormControlLabel
					control={
						<Checkbox
							checked={newMapping?.OnAbnormalResult}
							onChange={(e) => setNewMapping({
								...newMapping,
								OnAbnormalResult: e.target.checked,
							})}
						/>}
					label="Abnormal Result"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={newMapping?.PreventOrder}
							onChange={(e) => setNewMapping({
								...newMapping,
								PreventOrder: e.target.checked,
							})}
						/>}
					label="Prevent Order"
				/>
				<Button variant="contained" color="primary" onClick={() => addMapping()} disabled={!canEdit}>Add</Button>
			</Stack>
			<Box height={`calc(100vh - 425px)`}>
				<DataGridPro
					getRowId={(row) => row.ID}
					apiRef={apiRef}
					columns={columns}
					rows={mappings}
					editMode="row"
					processRowUpdate={saveEdit}
					onProcessRowUpdateError={(e) => { console.log(e); setError("Failed to update row") }}
					getRowClassName={(params) =>
						params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
					}
					density="compact"
					slots={{ toolbar: GridToolbar }}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
				/>
			</Box>
			<ErrorAlert error={error} />
		</Stack>
	</>;

}

export default FrequencyRules;
