import React from "react";
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { useField } from "formik";
import { UseQueryResult } from "@tanstack/react-query";
import { matchSorter } from 'match-sorter';


// no idea if this is working
export type ReactQueryAutocompleteProps<M extends boolean = false> =
    Omit<AutocompleteProps<any, M, false, false>, "renderInput" | "options"> & {
        query: () => UseQueryResult<any, Error>;
        name: string;
        required?: boolean;
        multiple?: M; // Allow multiple to be optional and dynamic
        label?: string;
        field?: string; // Used to override selection. By default, we select the entire object
        labelField?: string;
    };


export default function ReactQueryAutocomplete({ query, name, required, multiple, label, field, labelField = "name", ...props }: ReactQueryAutocompleteProps) {
    const [formikField, meta, helper] = useField(name);
    const options = query();

    let getOptionLabel = (option: any) => option?.[labelField]

    const selectedValues = React.useMemo(
        () => {
            let value: any = []
            if (Array.isArray(formikField.value)) {
                value = formikField.value
            }
            if (field) {
                if (!options.data || !Array.isArray(options.data)) {
                    return []
                }
                return options.data?.filter((v: any) => value?.includes(v?.[field!])) ?? []
            } else {
                return value ?? []
            }
        },
        [options.data, field, formikField.value]
    );

    const selectedValue = React.useMemo(
        () => {
            if (field) {
                return options.data?.find((v: any) => v?.[field!] === formikField.value) ?? null
            } else {
                return formikField.value ?? null
            }
        }, [options.data, field, formikField.value]
    );

    const matchKeys = [labelField]

    const filterOptions = (options: any[], { inputValue }: { inputValue: any }) => matchSorter(options, inputValue, { keys: matchKeys });

    return (
        <Autocomplete
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            multiple={multiple}
            filterOptions={filterOptions}
            options={options.data ?? []}
            loading={options.isLoading}
            sx={{
                minWidth: "180px",
            }}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => {
                if (field) {
                    return option?.[field] === value
                }
                return option.id === value.id
            }
            }
            value={multiple ? selectedValues : selectedValue}
            onChange={(_, value) => {
                if (field && !multiple) {
                    helper.setValue(value?.[field])
                } else if (multiple && field) {
                    if (value.length === 0) {
                        helper.setValue([])
                    } else {
                        helper.setValue(value.map((v: any) => v?.[field]))
                    }
                } else {
                    helper.setValue(value)
                }
            }}
            {...props}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        required={required}
                        error={meta.touched && Boolean(meta.error)}
                        helperText={meta.error}
                        size="small"
                        name={name}
                        label={label ?? name}
                    />
                );
            }}
        />
    )
}
