import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import React from "react"
import * as Yup from 'yup';
import { UseMutationResult } from "@tanstack/react-query";
import { CloseButton, SaveButton } from "./Buttons";
import { Form, Formik, FormikValues } from "formik";
import { AxiosError } from "./AxiosError";



type DialogFormProps<T> = {
    open: boolean,
    validationSchema: Yup.ObjectSchema<any>
    createMutation: UseMutationResult<any, unknown, T, unknown>;
    updateMutation?: UseMutationResult<any, unknown, T, unknown>;
    form: React.JSX.Element;
    initialValues: T;
    onBack: () => void;
    onSave: () => void
}


export function DialogForm<T>(props: DialogFormProps<T>) {
    const handleSubmit = async (values: FormikValues) => {
        if ('id' in values && values.id && props.updateMutation) {
            await props.updateMutation.mutateAsync(values as T)
        } else {
            await props.createMutation.mutateAsync(values as T)
        }
        props.onSave()
    }

    return (
        <Dialog open={props.open}>
            <Formik
                validationSchema={props.validationSchema}
                onSubmit={handleSubmit}
                initialValues={props.initialValues as any}
                enableReinitialize
            >{({ isSubmitting }) => (
                <Form>
                    <DialogTitle>Add Note</DialogTitle>
                    <DialogContent>
                        {props.form}
                        {props.createMutation && props.createMutation.isError && <AxiosError error={props.createMutation?.error} />}
                        {props.updateMutation && props.updateMutation.isError && <AxiosError error={props.updateMutation?.error} />}
                    </DialogContent>
                    <DialogActions>
                        <CloseButton disabled={isSubmitting} onClick={props.onBack} />
                        <SaveButton disabled={isSubmitting} />
                    </DialogActions>
                </Form>
            )}
            </Formik>

        </Dialog>
    )
}
