import React, { useEffect, useState } from "react";

import ErrorAlert from "./ErrorAlert";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Dialog,
    TextField,
    MenuItem,
    Typography
} from "@mui/material";
import { apiFetch } from "../services/fetch";
import { paymentMethods } from "../services/util";

import DiagnosisCodeSearch from "./DiagnosisCodeSelect";
import { LoadingButton } from "@mui/lab";

function getPaymentOptions(provider, patient, facility) {
    let options = [];
    if (canInsurance(provider, patient, facility)) {
        options.push("Insurance")
    }
    if (canSelfPay(facility)) {
        options.push("Self Pay")
    }
    if (canNoBill(facility)) {
        options.push("No Bill")
    }
    if (canClientBill(facility)) {
        options.push("Client Bill")
    }

    return options
}

function canClientBill(facility) {
    return facility.CanClientBill
}

function canNoBill(facility) {
    return facility.CanNoBill
}

function canSelfPay(facility) {
    return facility.CanSelfPay
}

function canInsurance(provider, patient, facility) {
    if (provider.Type === "other") {
        return false
    }

    if (!facility.CanInsurance) {
        return false
    }

    if (patient.FinancialClass < 2 && patient.PatientInsurances.length < 1) {
        return false
    }
    return true
}

function ChangePaymentMethodDialog({ orderId, open, setOpen, callback = () => { } }) {
    const [newPaymentOption, setNewPaymentOption] = useState("")
    const [diagnosisCodes, setDiagnosisCodes] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [paymentOptions, setPaymentOptions] = useState([])
    const [error, setError] = useState()
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if (!orderId || !open) {
            return
        }

        const init = async () => {
            try {
                let order = await apiFetch(`/orders/${orderId}`, "GET")
                let facility = await apiFetch(`/facilities/${order?.Facility?.ID}`, "GET")
                let provider = order.Provider
                let patient = order.Patient

                setPaymentOptions(getPaymentOptions(provider, patient, facility))
                setIsLoading(false)
            } catch (e) {
                setError("Failed to load")
                setIsLoading(false)
            }
        }

        init()
    }, [orderId, open])

    const updateOrder = () => {
        setSaving(true)
        const save = async () => {
            try {
                let data = {
                    PaymentMethod: paymentMethods[newPaymentOption],
                    DiagnosisCodes: diagnosisCodes,
                }

                await apiFetch(`/orders/${orderId}/payment`, "POST", data)
                setSaving(false)
                setOpen(false)
                callback()
            } catch (e) {
                setError("Failed to update order.")
                setSaving(false)
            }
        }

        save()
    }

    if (isLoading) {
        return <></>
    }

    return (
        <Dialog open={open} >
            <DialogTitle>Change Payment Method</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select new payment method.
                </DialogContentText>
                <br />
                <TextField
                    name="Payment Method"
                    label="Payment Method"
                    sx={{ minWidth: "200px" }}
                    value={newPaymentOption}
                    onChange={(e) => setNewPaymentOption(e.target.value)}
                    select
                >
                    {paymentOptions.map((opt, index) => (
                        <MenuItem key={index} value={opt}>{opt}</MenuItem>
                    ))}
                </TextField>

                {newPaymentOption === "Insurance" &&
                    <>
                        <Typography variant="body2">Insurance requires diagnosis codes. Please select all applicable codes from below.</Typography>
                        <br />
                        <DiagnosisCodeSearch diagnosisCodes={diagnosisCodes}
                            setDiagnosis={(_, v) => setDiagnosisCodes(v)}
                        />
                    </>}

                <ErrorAlert error={error} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <LoadingButton
                    loading={saving}
                    loadingIndicator="Updating..."
                    disabled={!paymentOptions.length > 0 || (newPaymentOption === "Insurance" && diagnosisCodes.length < 1)}
                    onClick={() => updateOrder()}>

                    Change

                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default ChangePaymentMethodDialog; 
