import React from "react";
import { ColumnStack, RowStack } from "../components/Stacks";
import { CustomCheckboxField, CustomListField, CustomTextField } from "../components/CustomTextField";
import { Box, InputAdornment, InputLabel, Typography } from "@mui/material";
import LOINCSelector from "../components/fields/LOINCSelector";
import { useFormikContext } from "formik";
import TestAutoComplete from "../components/fields/Tests";
import LaboratoriesAutoComplete from "../components/fields/Laboratories";
import ReportTypes from "../components/fields/ReportTypes";
import ReportGroups from "../components/fields/ReportGroups";
import DepartmentAutoComplete from "../components/fields/Departments";
import CategoryAutoComplete from "../components/fields/Categories";
import SpecimenTypeTableSelector from "../components/fields/SpecimenTypeTableSelector";
import { useCreateTest, useTest, useUpdateTest } from "../services/queries";
import PanelForm from "../components/PanelForm";
import { testInitialValues, testValidation } from "../validations/tests";
import SubTestTableSelector from "../components/fields/SubTestTableSelector";
import TestCode from "../components/fields/TestCode";

type TestPanelFormProps = {
	onBack: () => void;
	onSave: () => void;
	id?: number;
}

export function TestPanelForm({ id, onBack, onSave }: TestPanelFormProps) {
	const test = useTest(id)

	return (
		<PanelForm
			validationSchema={testValidation}
			form={<TestForm />}
			createMutation={useCreateTest()}
			updateMutation={useUpdateTest()}
			initialValues={test.isSuccess ? test.data : testInitialValues}
			onBack={onBack}
			onSave={onSave}
		/>
	)
}


export default function TestForm() {
	const { values, errors, setFieldValue } = useFormikContext<any>()
	console.log('values', values)
	console.log('errors', errors)

	return (
		<ColumnStack>
			<RowStack>
				<TestCode />
				<CustomTextField name="name" label="Name" required />
				<CustomTextField
					name="description"
					label="Description"
					sx={{ width: "100%" }}
					required />
			</RowStack>
			<RowStack>
				<CustomTextField name="cpt_code" label="CPT Code"
					inputProps={{ maxLength: 5 }}
				/>
				<LOINCSelector name="loinc" label="LOINC" />
			</RowStack>
			<TestAutoComplete
				// @ts-ignore
				multiple
				name="required_tests"
				label="Required Tests"
				labelField="name"
				field="id" />
			<RowStack>
				<CustomCheckboxField name="orderable" label="Orderable" />
				<CustomCheckboxField name="control" label="Control" />
				<CustomCheckboxField name="internal" label="Internal" />
				<CustomCheckboxField name="is_panel" label="Is Panel"
					onChange={(_: any, b: boolean) => {
						setFieldValue("sub_tests", [])
						setFieldValue("is_panel", b)
					}}

				/>
			</RowStack>
			<RowStack>
				<CustomTextField name="max_tat" label="Max TAT (hr)" />
				<CustomTextField name="stability" label="Stability (hr)" />
			</RowStack>
			<RowStack>
				<LaboratoriesAutoComplete name="laboratory_id" field="id" label="Performing Laboratory" labelField="name" />
				<CustomTextField
					name="reference_lab_test_code"
					label="Reference Order Code"
				/>
				<CustomTextField
					name="reference_lab_result_code"
					label="Reference Result Code"
				/>
			</RowStack>
			<Typography variant="h6">Test Type</Typography>
			<RowStack>
				<CustomListField
					name="type"
					label="Type"
					options={[{ ID: 0, Name: "Default" }, { ID: 1, Name: "Toxicology" }]}
				/>
				<CustomListField
					name="sub_type"
					label="Subtype"
					options={[
						{ ID: 0, Name: "Default" },
						{ ID: 1, Name: "Screen" },
						{ ID: 2, Name: "Confirmation" },
						{ ID: 3, Name: "Specimen Validity" },
					]}
				/>

			</RowStack>
			<Typography variant="h6">Result Report Options</Typography>
			<RowStack>
				<CustomTextField
					required
					name="ordering"
					label="Report Priority"
					type="number"
					inputProps={{ min: 0, step: 1 }}
				/>
				<ReportTypes
					required
					name="report_type_id"
					field="id"
					labelField="name"
					label="Report Type"
				/>
				<ReportGroups
					name="report_group_id"
					field="id"
					labelField="name" label="Report Group" />
			</RowStack>
			<CustomTextField
				name="comment"
				label="Report Comment"
				inputProps={{ maxLength: 600 }}
				rows={2}
				multiline

			/>
			<Typography variant="h6">Financial Reporting</Typography>
			<RowStack>
				<CustomTextField
					name="cost"
					label="Cost"
					inputProps={{ type: "number", min: 0, step: 0.01 }}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								$
							</InputAdornment>
						),
					}}

				/>
				<DepartmentAutoComplete
					required
					name="department_id"
					label="Department"
					labelField="name"
					field="id"
				/>
				<CategoryAutoComplete
					name="category_id"
					label="Category"
					labelField="name"
					field="id"
				/>
			</RowStack>
			<Typography variant="h6">Specimen Types</Typography>
			<Box height="300px">
				<SpecimenTypeTableSelector name="specimen_types" />
			</Box>
			{values?.is_panel &&
				<>
					<Typography variant="h6">Subtests</Typography>
					<Box height="600px">
						<SubTestTableSelector name="sub_tests" />
					</Box>
				</>

			}
		</ColumnStack>
	)
}
