// Code generated by tygo. DO NOT EDIT.

//////////
// source: models.go

export interface ErrResponse {
  status: string; // user-level status message
  code?: number /* int64 */; // application-specific error code
  error?: string; // application-level error message, for debugging
}
export interface Meta {
  id?: number /* uint */;
  created_at?: string /* RFC3339 */;
  updated_at?: string /* RFC3339 */;
  deleted_at?: string /* RFC3339 */;
}
export interface Address {
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip_code: string;
}
export interface Attachment extends Meta {
  name?: string;
  uuid?: string;
  content_type?: string;
  type?: string;
  order_id?: number /* uint */;
  patient_id?: number /* uint */;
  sample_id?: number /* uint */;
  facility_id?: number /* uint */;
  added_by_id?: number /* uint */;
}
export type BillingStatus = string;
export const BillingNotReady: BillingStatus = "Not Ready To Bill";
export const BillingReady: BillingStatus = "Ready To Bill";
export const BillingBilled: BillingStatus = "Billed";
export const BillingNoBill: BillingStatus = "No Bill";
export interface Category extends Meta {
  id?: number /* uint */;
  name?: string;
  code?: string;
}
export interface Collector extends Meta {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  is_phlebotomist: boolean;
  facilities: number /* uint */[];
}
export interface Contact extends Meta {
  critical: boolean;
  email: string;
  email_results: boolean;
  email_orders: boolean;
  fax: string;
  fax_results: boolean;
  fax_orders: boolean;
  first_name: string;
  last_name: string;
  notes: string;
  phone_1: string;
  phone_2: string;
  text_results: boolean;
  text_orders: boolean;
  type: string;
  facilities: number /* uint */[];
  providers: number /* uint */[];
}
export interface Department extends Meta {
  id?: number /* uint */;
  name?: string;
  code?: string;
}
export interface DiagnosisCode {
  code?: string;
  order_number?: number /* uint */;
  description?: string;
  header?: boolean;
  secondary_only?: boolean;
}
export interface DrugClass {
  id?: number /* uint */;
  rx_norm_id?: string;
  name?: string;
  diagnosis_codes?: string[];
}
export interface Facility extends Meta, Address {
  id: number /* uint */;
  name: string;
  active: boolean;
  can_self_pay: boolean;
  can_client_bill: boolean;
  can_insurance: boolean;
  can_no_bill: boolean;
  disable_reflex_auto_order: boolean;
  require_id_capture: boolean;
  require_insurance_capture: boolean;
  require_patient_photo_capture: boolean;
  require_patient_signature: boolean;
  frequency_algorithm_id: number /* uint */;
  fee_schedule_id?: number /* uint */;
  phone: string;
  email: string;
  fax: string;
  fax_results: boolean;
  email_results: boolean;
  text_results: boolean;
  pos: string;
  npi: string;
  fein: string;
  sales_rep_id?: number /* uint */;
  organization_id?: number /* uint */;
  category_filter: number /* uint */[];
  test_filter: number /* uint */[];
  providers: number /* uint */[];
  contacts: number /* uint */[];
  collectors: number /* uint */[];
  integrations: number /* uint */[];
}
export type FinancialClass = number /* int64 */;
export const ClientBillFC: FinancialClass = 0;
export const SelfPayFC: FinancialClass = 1;
export const CommercialFC: FinancialClass = 2;
export const MedicareFC: FinancialClass = 3;
export const MedicaidFC: FinancialClass = 4;
export interface Insurance extends Meta {
  id: number /* uint */;
  active: boolean;
  name: string;
  code: string;
  in_network: boolean;
  requires_pre_auth: boolean;
  phone: string;
  financial_class: number /* uint */;
  p_verify_code: string;
}
export interface IntegrationTestCodeMapping extends Meta {
  external_test_code: string;
  test_code: number /* uint */;
  instance_id: number /* uint */;
}
export interface IntegrationInsuranceMapping extends Meta {
  external_insurance: string;
  insurance_id: number /* uint */;
  instance_id: number /* uint */;
}
export interface IntegrationFacilityMapping extends Meta {
  external_facility: string;
  facility_id: number /* uint */;
  instance_id: number /* uint */;
}
export interface Laboratory extends Meta, Address {
  name?: string;
  code?: string;
  description?: string;
  director?: string;
  clia?: string;
  npi?: string;
  phone?: string;
  email?: string;
  fax?: string;
  report_disclaimer?: string;
  patient_disclaimer?: string;
  timezone?: string;
  reference?: boolean;
}
export interface Note extends Meta {
  added_by_id: number /* uint */;
  note: string;
}
export type NoteType = number /* uint */;
export const NoteTypeFacility: NoteType = 0; // visible to lab and facility, not on report
export const NoteTypeReport: NoteType = 1; // visible to lab and facility, on result report
export const NoteTypeInternal: NoteType = 2; // visible to lab only
export interface Permission {
  id: number /* uint */;
  resource: string;
  action: string;
  name: string;
}
export interface Provider extends Meta {
  id?: number /* uint */;
  enabled?: boolean;
  first_name?: string;
  last_name?: string;
  npi?: string;
  signature_on_file?: boolean;
  signature_image_id?: number /* uint */;
  type?: string;
  facilities?: number /* uint */[];
  contacts?: number /* uint */[];
}
export interface ProviderSignature extends Meta {
  provider_id?: number /* uint */;
  signature_img_id?: number /* uint */;
  signature_img_string?: string;
  signature_name?: string;
  auto_apply?: boolean;
  delegated_signees?: number /* uint */[];
}
export interface Order extends Meta {
  id?: number /* uint */;
  reference_id?: string;
  integration_id?: number /* uint */;
  tests?: number /* uint */[];
  profiles?: number /* uint */[];
  services?: number /* uint */[];
  all_tests?: number /* uint */[];
  reflexes?: number /* uint */[];
  add_ons?: number /* uint */[];
  samples?: number /* uint */[];
  results?: number /* uint */[];
  critical_notification?: number /* uint */[];
  result_reports?: number /* uint */[];
  diagnosis_codes?: string[];
  billing_status?: BillingStatus;
  provider_id?: number /* uint */;
  notes?: number /* uint */[];
  order_views?: number /* uint */[];
  facility_id?: number /* uint */;
  payment_method: PaymentMethod;
  requested_collection_date?: string /* RFC3339 */;
  date_reported?: string /* RFC3339 */;
  provider_signature_id?: number /* uint */;
  signed_at?: string /* RFC3339 */;
  signature_applied_by_id?: number /* uint */;
  patient_order_disclaimer_id?: number /* uint */;
  patient_signature_id?: number /* uint */;
  price?: number /* int */;
  status?: OrderStatus;
  results_available?: boolean;
  requires_approval?: boolean;
  approved?: boolean;
  reviewed_by_id?: number /* uint */;
  reviewed_at?: string /* RFC3339 */;
  stat?: boolean;
  added_by_id?: number /* uint */;
  patient_id?: number /* int */;
  laboratory_id?: number /* uint */;
  pos?: number /* int */;
  ao_es?: number /* uint */[];
  diagnosis_questions?: number /* uint */[];
  provider_notes_required?: boolean;
  provider_notes_added?: boolean;
}
export interface OrderDiagnosisCodeRequest {
  diagnosis_codes: string[];
}
export interface OrderSignatureEligibilityRequest {
  provider_id: number /* uint */;
}
export interface OrderSignatureEligibilityResponse {
  allowed: boolean;
}
export interface OrderNote {
  order_id: number /* uint */;
  note_id?: number /* uint */;
  note_type: NoteType;
  note: Note;
}
export type OrderStatus = string;
export const OrderSubmitted: OrderStatus = "Submitted";
export const OrderSampleCollected: OrderStatus = "Sample(s) Collected";
export const OrderSampleReceived: OrderStatus = "Sample(s) Received";
export const OrderCompleted: OrderStatus = "Completed";
export const OrderRecollect: OrderStatus = "Recollect Required";
export const OrderCorrecting: OrderStatus = "Correcting";
export const OrderCorrected: OrderStatus = "Corrected";
export const OrderRejected: OrderStatus = "Rejected";
export interface Organization extends Meta {
  active?: boolean;
  name?: string;
  facilities?: number /* uint */[];
  users?: number /* uint */[];
}
export interface Patient extends Meta, Address {
  id?: number /* uint */;
  enabled?: boolean;
  emrid?: string;
  first_name?: string;
  last_name?: string;
  dob?: string /* RFC3339 */;
  sex?: Sex;
  facility_id?: number /* int */;
  ssn?: string;
  financial_class: FinancialClass;
  ethnicity?: string;
  disabled_reason?: string;
  phone?: string;
  email?: string;
  fax?: string;
  text_results?: boolean;
  email_results?: boolean;
  fax_results?: boolean;
  medications?: number /* uint */[];
  allergies?: number /* uint */[];
  patient_insurances?: number /* uint */[];
}
export type PaymentMethod = number /* int64 */;
export const ClientBill: PaymentMethod = 0;
export const SelfPay: PaymentMethod = 1;
export const InsurancePay: PaymentMethod = 2;
export const NoBill: PaymentMethod = 3;
export type ReflexMethod = string;
export const ReflexMethodNormal: ReflexMethod = "Normal";
export const ReflexMethodAbnormal: ReflexMethod = "Abnormal";
export const ReflexMethodCritical: ReflexMethod = "Critical";
export const ReflexMethodInconsistent: ReflexMethod = "Inconsistent";
export const ReflexMethodSpecificAbnormal: ReflexMethod = "SpecificAbnormal";
export const ReflexMethodSpecificCrit: ReflexMethod = "SpecificAbnormal";
export type ReflexType = number /* int */;
export const Test2Test: ReflexType = 0;
export const Test2Profile: ReflexType = 1;
export const Profile2Profile: ReflexType = 2;
export const Profile2Test: ReflexType = 3;
export interface Reflex extends Meta {
  is_active: boolean;
  reflex_type: ReflexType;
  auto_order: boolean;
  force_order: boolean;
  source_test_id?: number /* uint */;
  source_subtests: number /* uint */[];
  dest_test_id?: number /* uint */;
  source_profile_id?: number /* uint */;
  dest_profile_id?: number /* uint */;
  description: string;
  reflex_range_type: ReflexMethod;
  facilities: number /* uint */[];
}
export interface ReportGroup extends Meta {
  name: string;
}
export interface ReportType extends Meta {
  id?: number /* uint */;
  name?: string;
  code?: string;
  enabled?: boolean;
}
export interface Role extends Meta {
  name: string;
  type: string;
  permissions: number /* uint */[];
}
export interface SalesRep extends Meta {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  facilities: number /* uint */[];
}
export type Sex = string;
export const Male: Sex = "Male";
export const Female: Sex = "Female";
export const Unknown: Sex = "Unknown";
export interface SpecimenType extends Meta {
  id?: number /* uint */;
  prefix?: string;
  name?: string;
  specimen?: string;
  container?: string;
  directions?: string;
  qty?: number /* uint */;
  tests?: number /* uint */[];
}
export interface Test extends Meta {
  is_active: boolean;
  id: number /* uint */;
  cpt_code: string;
  category_id?: number /* uint */;
  code: number /* uint */;
  comment: string;
  control: boolean;
  cost: number /* int */;
  department_id: number /* int */;
  description: string;
  drug_class_id?: number /* uint */;
  internal: boolean;
  is_panel: boolean;
  loinc: string;
  laboratory_id: number /* int */;
  max_tat: number /* int */;
  name: string;
  orderable: boolean;
  ordering: number /* int */;
  reference_lab_result_code: string;
  reference_lab_test_code: string;
  referenced: boolean;
  report_group_id?: number /* uint */;
  report_service_id?: number /* uint */;
  report_type_id: number /* int */;
  stability: number /* int */;
  sub_type: number /* uint */;
  type: number /* uint */;
  version: number /* uint */;
  fees: number /* uint */[];
  report_services: number /* uint */[];
  required_tests: number /* uint */[];
  specimen_types: number /* uint */[];
  sub_tests: number /* uint */[];
}
export interface User extends Meta {
  id?: number /* uint */;
  first_name?: string;
  last_name?: string;
  email?: string;
  facilities?: number /* uint */[];
  organizations?: number /* uint */[];
  laboratory_id?: number /* uint */; // nullable bc admin user cant be tied to lab that doesnt exist yet
  provider_id?: number /* uint */;
  collector_id?: number /* uint */;
  activated_at?: string /* RFC3339 */;
  roles?: number /* uint */[];
  type?: string; // Lab or Facility
  order_views?: number /* uint */[];
  is_active?: boolean;
  deactivated_on?: string /* RFC3339 */;
  deactivated_by_id?: number /* uint */;
  deactivated_reason?: string;
}
export interface UserCreateRequest extends Meta {
  id?: number /* uint */;
  first_name?: string;
  last_name?: string;
  email?: string;
  facilities?: number /* uint */[];
  organizations?: number /* uint */[];
  laboratory_id?: number /* uint */; // nullable bc admin user cant be tied to lab that doesnt exist yet
  provider_id?: number /* uint */;
  collector_id?: number /* uint */;
  activated_at?: string /* RFC3339 */;
  roles?: number /* uint */[];
  type?: string; // Lab or Facility
  order_views?: number /* uint */[];
  is_active?: boolean;
  create_collector?: boolean;
  create_contact?: boolean;
}
export interface UserUpdateRequest extends Meta {
  id?: number /* uint */;
  first_name?: string;
  last_name?: string;
  email?: string;
  facilities?: number /* uint */[];
  organizations?: number /* uint */[];
  laboratory_id?: number /* uint */; // nullable bc admin user cant be tied to lab that doesnt exist yet
  provider_id?: number /* uint */;
  collector_id?: number /* uint */;
  activated_at?: string /* RFC3339 */;
  roles?: number /* uint */[];
  type?: string; // Lab or Facility
  order_views?: number /* uint */[];
  is_active?: boolean;
  deactivated_on?: string /* RFC3339 */;
  deactivated_by_id?: number /* uint */;
  deactivated_reason?: string;
}
export interface UserTableView {
  id?: number /* uint */;
  first_name?: string;
  last_name?: string;
  email?: string;
  facilities?: number /* uint */;
  organizations?: number /* uint */;
  is_active?: boolean;
  roles?: number /* uint */;
  type?: string; // Lab or Facility
  deactivated_on?: string /* RFC3339 */;
  deactivated_by?: string;
  deactivated_reason?: string;
  pending_invite?: string;
}
