import { GridColDef } from "@mui/x-data-grid-pro";
import { User } from "../constants/go_types";

export const collectorPanelColumns: GridColDef[] = [
	{ field: "id", headerName: "ID", width: 45 },
	{ field: "first_name", headerName: "First Name", width: 150 },
	{ field: "last_name", headerName: "Last Name", width: 150 },
	{ field: "phone", headerName: "Phone", width: 150 },
	{ field: "email", headerName: "Email", width: 200 },
	{
		field: "is_phlebotomist",
		headerName: "Is Phlebotomist",
		width: 160,
		align: "center",
		type: "boolean",
	},
];

export const contactPanelColumns: GridColDef[] = [
	{ field: "id", headerName: "ID", width: 45 },
	{ field: "critical", headerName: "Critical Contact", width: 100, type: "boolean" },
	{ field: "first_name", headerName: "First Name", width: 150 },
	{ field: "last_name", headerName: "Last Name", width: 150 },
	{ field: "phone_1", headerName: "Phone 1", width: 150 },
	{ field: "phone_2", headerName: "Phone 2", width: 150 },
	{ field: "fax", headerName: "Fax", width: 150 },
	{ field: "email", headerName: "Email", width: 200 },
	{ field: "email_results", headerName: "Email Results", width: 100, type: "boolean" },
	{ field: "fax_results", headerName: "Fax Results", width: 100, type: "boolean" },
	{ field: "text_results", headerName: "Text Results", width: 100, type: "boolean" },
	{ field: "email_orders", headerName: "Email Orders", width: 100, type: "boolean" },
	{ field: "fax_orders", headerName: "Fax Orders", width: 100, type: "boolean" },
	{ field: "text_orders", headerName: "Text Orders", width: 100, type: "boolean" },
	{ field: "type", headerName: "Type", width: 200 },
];

export const reportGroupColumns: GridColDef[] = [
	{ field: "id", headerName: "ID", width: 45 },
	{ field: "name", headerName: "Name", width: 350 },
]

export const rolePanelColumns: GridColDef[] = [
	{ field: "id", headerName: "ID", width: 45 },
	{ field: "name", headerName: "Name", width: 350 },
	{ field: "type", headerName: "Type", width: 350 },
]

export const salesRepPanelColumns: GridColDef[] = [
	{ field: "id", headerName: "ID", width: 45 },
	{ field: "first_name", headerName: "First Name", width: 150 },
	{ field: "last_name", headerName: "Last Name", width: 150 },
	{ field: "phone", headerName: "Phone", width: 150 },
	{ field: "email", headerName: "Email", width: 200 },
];


export const userPanelColumns: GridColDef<User>[] = [
	{ field: "id", headerName: "ID", width: 45 },
	{ field: "first_name", headerName: "First Name", width: 200 },
	{ field: "last_name", headerName: "Last Name", width: 200 },
	{ field: "email", headerName: "Email", width: 250 },
	{ field: "type", headerName: "Type", width: 100 },
	{ field: "activated", headerName: "Activated", width: 100, type: "boolean" },
	{ field: "deactivated_on", headerName: "Deactivated On", width: 150 },
	{ field: "deactivated_by_id", headerName: "Deactivated By", width: 150 },
	{ field: "deactivated_reason", headerName: "Deactivated Reason", width: 150 },
	{ field: "facilities", headerName: "Facilities", width: 150 },
	{ field: "roles", headerName: "Roles", width: 150 },
	{ field: "reset", headerName: "Reset Password", width: 150 },
]
