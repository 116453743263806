import PatientDisplay from "../components/PatientDisplay";
import { Typography } from "@mui/material";
import Framework from "../components/Framework";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { usePermission } from "../hooks/usePermission";

export default function PatientPage() {
    let { patientID } = useParams();
    const navigate = useNavigate();
    const canEdit = usePermission("patients:write");

    if (patientID === undefined) {
        return <></>;
    }

    return (
        <Framework>
            <Box sx={{ m: 2 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5">Patient</Typography>
                    <Button onClick={() => navigate("/patients/" + patientID + "/edit")} startIcon={<ModeEditIcon />} disabled={!canEdit}>Edit</Button>
                </Stack>
                <PatientDisplay
                    patient={patientID}
                    back={() => {
                        navigate(-1);
                    }}
                />
            </Box>
        </Framework>
    );
}
