import React from "react";
import { Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Box, Button } from '@mui/material';
import { Order } from "../../constants/go_types";
import { useOrderDiagnosisCodes } from "../../services/queries";
import Loading from "../Loading";
import StyledPaper from "../StyledPaper";
import Title from "../Title";
import { ColumnStack, RowStack } from "../Stacks";
import ChangeDiagnosisCodesDialog from "../ChangeDiagnosisCodesDialog";
import { useQueryClient } from "@tanstack/react-query";
import { ModeEdit } from "@mui/icons-material";
import { usePermission } from "../../hooks/usePermission";


const style = { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }

export default function OrderDiagnosisCodes({ order }: { order: Order }) {
    const diagnosisCodes = useOrderDiagnosisCodes(order.id)
    const [openDiagnosis, setOpenDiagnosis] = React.useState(false)
    const canEdit = usePermission("orders:write")

    if (diagnosisCodes.isLoading) {
        <Loading />
    }

    return (
        <>
            <ChangeDiagnosisCodesDialog
                open={openDiagnosis}
                setOpen={setOpenDiagnosis}
                orderId={order.id}

            />
            <StyledPaper>
                <Box height="100%" display="flex" flexDirection="column">
                    <RowStack justifyContent="space-between">
                        <Title>Diagnosis Codes</Title>
                        <Button
                            variant="text"
                            color="primary"
                            disabled={!canEdit}
                            startIcon={<ModeEdit />}
                            onClick={() => setOpenDiagnosis(true)}
                        >
                            Edit
                        </Button>
                    </RowStack>
                    <TableContainer sx={{ flex: 1 }}>
                        <Table style={{ width: "100%", tableLayout: "auto" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell size="small">Order</TableCell>
                                    <TableCell size="small">Code</TableCell>
                                    <TableCell size="small" sx={style}>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(!diagnosisCodes.data || diagnosisCodes.data.length === 0) && <TableRow><TableCell align="center" colSpan={3}>No diagnosis codes for this order.</TableCell></TableRow>}
                                {diagnosisCodes.data?.map((d, index) => {
                                    return (
                                        <TableRow>
                                            <TableCell size="small">{index + 1}</TableCell>
                                            <TableCell size="small">{d.code}</TableCell>
                                            <TableCell size="small" sx={style}>{d.description}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </StyledPaper>
        </>
    )

}
