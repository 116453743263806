import axios from '../axios';
import {
	Category,
	Collector,
	Contact,
	Department,
	DiagnosisCode,
	DrugClass,
	Facility,
	Insurance,
	IntegrationFacilityMapping,
	IntegrationInsuranceMapping,
	IntegrationTestCodeMapping,
	Laboratory,
	Order,
	OrderDiagnosisCodeRequest,
	OrderNote,
	OrderSignatureEligibilityResponse,
	Patient,
	Permission,
	Provider,
	ProviderSignature,
	Reflex,
	ReportGroup,
	ReportType,
	Role,
	SalesRep,
	SpecimenType,
	Test,
	User,
	UserCreateRequest,
	UserTableView
} from '../constants/go_types';
import { Facility as FacilityDeprecated, Organization } from '../constants/types';

type TestDeprecated = {
	ID: number;
	CreatedAt: string;
	UpdatedAt: string;
	Name: string;
	Code: string;
}

// OLD TO BE REPLACED
export const getFacilitiesDeprecated = async () => {
	return (await axios.get<FacilityDeprecated[]>('/v1/facilities')).data;
}

export const getProvidersDeprecated = async () => {
	return (await axios.get<Provider[]>('/v1/providers')).data;
}

export const getTestsDeprecated = async () => {
	return (await axios.get<TestDeprecated[]>('/v1/tests')).data;
}

export const getIntegrationInstancesDepracted = async () => {
	return (await axios.get('/v1/integrations/instances')).data;
}

export const getOrderSignDepracted = async (id: number) => {
	return (await axios.get(`/v1/orders/${id}/sign`)).data;
}

// NEW V2 ROUTES

export const getCategories = async () => {
	return (await axios.get<Category[]>('/v2/categories')).data;
}

export const getCategory = async (id: number) => {
	return (await axios.get<Category>(`/v2/categories/${id}`)).data;
}

export const createCategory = async (m: Category) => {
	return (await axios.post<Category>('/v2/categories', m)).data;
}

export const updateCategory = async (m: Category) => {
	return (await axios.put<Category>('/v2/categories', m)).data;
}

export const deleteCategory = async (id: number) => {
	return (await axios.delete<Category>(`/v2/categories/${id}`)).data;
}

export const getCollectors = async () => {
	return (await axios.get<Collector[]>('/v2/collectors')).data;
}

export const getCollector = async (id: number) => {
	return (await axios.get<Collector>(`/v2/collectors/${id}`)).data;
}

export const createCollector = async (collector: Collector) => {
	return (await axios.post<Collector>('/v2/collectors', collector)).data;
}

export const updateCollector = async (collector: Collector) => {
	return (await axios.put<Collector>('/v2/collectors', collector)).data;
}

export const getContacts = async () => {
	return (await axios.get<Contact[]>('/v2/contacts')).data;
}

export const getContact = async (id: number) => {
	return (await axios.get<Contact>(`/v2/contacts/${id}`)).data;
}

export const createContact = async (contact: Contact) => {
	return (await axios.post<Contact>('/v2/contacts', contact)).data;
}

export const updateContact = async (contact: Contact) => {
	return (await axios.put<Contact>('/v2/contacts', contact)).data;
}

export const deleteContact = async (id: number) => {
	return (await axios.delete<Contact>(`/v2/contacts/${id}`)).data;
}

export const getDepartments = async () => {
	return (await axios.get<Department[]>('/v2/departments')).data;
}

export const getDepartment = async (id: number) => {
	return (await axios.get<Department>(`/v2/departments/${id}`)).data;
}

export const createDepartment = async (m: Department) => {
	return (await axios.post<Department>('/v2/departments', m)).data;
}

export const updateDepartment = async (m: Department) => {
	return (await axios.put<Department>('/v2/departments', m)).data;
}

export const deleteDepartment = async (id: number) => {
	return (await axios.delete<Department>(`/v2/departments/${id}`)).data;
}

export const getDrugClasses = async () => {
	return (await axios.get<DrugClass[]>('/v2/drug-classes')).data;
}

export const getDrugClass = async (id: number) => {
	return (await axios.get<DrugClass>(`/v2/drug-classes/${id}`)).data;
}

export const createDrugClass = async (m: DrugClass) => {
	return (await axios.post<DrugClass>('/v2/drug-classes', m)).data;
}

export const updateDrugClass = async (m: DrugClass) => {
	return (await axios.put<DrugClass>('/v2/drug-classes', m)).data;
}

export const deleteDrugClass = async (id: number) => {
	return (await axios.delete<DrugClass>(`/v2/drug-classes/${id}`)).data;
}

export const getFacilities = async () => {
	return (await axios.get<Facility[]>('/v2/facilities')).data;
}

export const getFacility = async (id: number) => {
	return (await axios.get<Facility>(`/v2/facilities/${id}`)).data;
}

export const createFacility = async (m: Facility) => {
	return (await axios.post<Facility>('/v2/facilities', m)).data;
}

export const updateFacility = async (m: Facility) => {
	return (await axios.put<Facility>('/v2/facilities', m)).data;
}

export const deleteFacility = async (id: number) => {
	return (await axios.delete<Facility>(`/v2/facilities/${id}`)).data;
}

export const getInsurances = async () => {
	return (await axios.get<Insurance[]>('/v2/insurances')).data;
}

export const getInsurance = async (id: number) => {
	return (await axios.get<Insurance>(`/v2/insurances/${id}`)).data;
}

export const createInsurance = async (m: Insurance) => {
	return (await axios.post<Insurance>('/v2/insurances', m)).data;
}

export const updateInsurance = async (m: Insurance) => {
	return (await axios.put<Insurance>('/v2/insurances', m)).data;
}

export const deleteInsurance = async (id: number) => {
	return (await axios.delete<Insurance>(`/v2/insurances/${id}`)).data;
}

export const getIntegrationFacilityMappings = async () => {
	return (await axios.get<IntegrationFacilityMapping[]>('/v2/integrationFacilityMappings')).data;
}

export const getIntegrationFacilityMapping = async (id: number) => {
	return (await axios.get<IntegrationFacilityMapping>(`/v2/integrationFacilityMappings/${id}`)).data;
}

export const createIntegrationFacilityMapping = async (m: IntegrationFacilityMapping) => {
	return (await axios.post<IntegrationFacilityMapping>('/v2/integrationFacilityMappings', m)).data;
}

export const updateIntegrationFacilityMapping = async (m: IntegrationFacilityMapping) => {
	return (await axios.put<IntegrationFacilityMapping>('/v2/integrationFacilityMappings', m)).data;
}

export const deleteIntegrationFacilityMapping = async (id: number) => {
	return (await axios.delete<IntegrationFacilityMapping>(`/v2/integrationFacilityMappings/${id}`)).data;
}

export const getIntegrationInsuranceMappings = async () => {
	return (await axios.get<IntegrationInsuranceMapping[]>('/v2/integrationInsuranceMappings')).data;
}

export const getIntegrationInsuranceMapping = async (id: number) => {
	return (await axios.get<IntegrationInsuranceMapping>(`/v2/integrationInsuranceMappings/${id}`)).data;
}

export const createIntegrationInsuranceMapping = async (m: IntegrationInsuranceMapping) => {
	return (await axios.post<IntegrationInsuranceMapping>('/v2/integrationInsuranceMappings', m)).data;
}

export const updateIntegrationInsuranceMapping = async (m: IntegrationInsuranceMapping) => {
	return (await axios.put<IntegrationInsuranceMapping>('/v2/integrationInsuranceMappings', m)).data;
}

export const deleteIntegrationInsuranceMapping = async (id: number) => {
	return (await axios.delete<IntegrationInsuranceMapping>(`/v2/integrationInsuranceMappings/${id}`)).data;
}

export const getIntegrationTestCodeMappings = async () => {
	return (await axios.get<IntegrationTestCodeMapping[]>('/v2/integrationTestCodeMappings')).data;
}

export const getIntegrationTestCodeMapping = async (id: number) => {
	return (await axios.get<IntegrationTestCodeMapping>(`/v2/integrationTestCodeMappings/${id}`)).data;
}

export const createIntegrationTestCodeMapping = async (m: IntegrationTestCodeMapping) => {
	return (await axios.post<IntegrationTestCodeMapping>('/v2/integrationTestCodeMappings', m)).data;
}

export const updateIntegrationTestCodeMapping = async (m: IntegrationTestCodeMapping) => {
	return (await axios.put<IntegrationTestCodeMapping>('/v2/integrationTestCodeMappings', m)).data;
}

export const deleteIntegrationTestCodeMapping = async (id: number) => {
	return (await axios.delete<IntegrationTestCodeMapping>(`/v2/integrationTestCodeMappings/${id}`)).data;
}

export const getLaboratories = async () => {
	return (await axios.get<Laboratory[]>('/v2/laboratories')).data;
}

export const getLaboratory = async (id: number) => {
	return (await axios.get<Laboratory>(`/v2/laboratories/${id}`)).data;
}

export const createLaboratory = async (m: Laboratory) => {
	return (await axios.post<Laboratory>('/v2/laboratories', m)).data;
}

export const updateLaboratory = async (m: Laboratory) => {
	return (await axios.put<Laboratory>('/v2/laboratories', m)).data;
}

export const deleteLaboratory = async (id: number) => {
	return (await axios.delete<Laboratory>(`/v2/laboratories/${id}`)).data;
}

export const getPermissions = async (isLabOnly: boolean) => {
	return (await axios.get<Permission[]>('/v2/roles/permissions', { params: { isLabOnly: isLabOnly } })).data;
}

export const getPatients = async () => {
	return (await axios.get<Patient[]>('/v2/patients')).data;
}

export const getPatient = async (id: number) => {
	return (await axios.get<Patient>(`/v2/patients/${id}`)).data;
}

export const createPatient = async (m: Patient) => {
	return (await axios.post<Patient>('/v2/patients', m)).data;
}

export const updatePatient = async (m: Patient) => {
	return (await axios.put<Patient>('/v2/patients', m)).data;
}

export const deletePatient = async (id: number) => {
	return (await axios.delete<Patient>(`/v2/patients/${id}`)).data;
}

export const getProviders = async () => {
	return (await axios.get<Provider[]>('/v2/providers')).data;
}

export const getProvider = async (id: number) => {
	return (await axios.get<Provider>(`/v2/providers/${id}`)).data;
}

export const createProvider = async (m: Provider) => {
	return (await axios.post<Provider>('/v2/providers', m)).data;
}

export const updateProvider = async (m: Provider) => {
	return (await axios.put<Provider>('/v2/providers', m)).data;
}

export const deleteProvider = async (id: number) => {
	return (await axios.delete<Provider>(`/v2/providers/${id}`)).data;
}

export const getOrder = async (id: number,) => {
	return (await axios.get<Order>(`/v2/orders/${id}`)).data;
}

export const getOrderDiagnosisCodes = async (id: number) => {
	return (await axios.get<DiagnosisCode[]>(`/v2/orders/${id}/diagnosis-codes`)).data;
}

export const updateOrderDiagnosisCodes = async (id: number, d: OrderDiagnosisCodeRequest) => {
	return (await axios.post<OrderDiagnosisCodeRequest>(`/v2/orders/${id}/diagnosis-codes`, d)).data;
}


export const updateOrderComplete = async (id: number) => {
	return (await axios.post(`/v2/orders/${id}/complete`)).data;
}

export const updateOrderReject = async (id: number) => {
	return (await axios.post(`/v2/orders/${id}/reject`)).data;
}

export const getOrderNotes = async (orderId: number,) => {
	return (await axios.get<OrderNote[]>(`/v2/orders/${orderId}/notes`)).data;
}

export const createOrderNote = async ({ orderId, m }: { orderId: number, m: OrderNote }) => {
	return (await axios.post<OrderNote>(`/v2/orders/${orderId}/notes`, m)).data;
}

export const updateOrderNote = async ({ orderId, m }: { orderId: number, m: OrderNote }) => {
	return (await axios.put<OrderNote>(`/v2/orders/${orderId}/notes`, m)).data;
}

export const deleteOrderNote = async ({ orderId, noteId }: { orderId: number, noteId: number }) => {
	return (await axios.delete<OrderNote>(`/v2/orders/${orderId}/notes/${noteId}`)).data;
}

export const getOrderSignatureEligibility = async (orderId: number, providerId: number) => {
	return (await axios.post<OrderSignatureEligibilityResponse>(`/v2/orders/${orderId}/signature-eligibility`, { provider_id: providerId })).data;
}

export const signOrder = async (orderId: number) => {
	return (await axios.post(`/v2/orders/${orderId}/sign`)).data;
}

export const getOrderSignature = async (orderId: number, providerSignatureId: number) => {
	return (await axios.get<ProviderSignature>(`/v2/orders/${orderId}/signature`, { params: { "provider-signature-id": providerSignatureId } })).data;
}

export const deleteOrderSignature = async (orderId: number) => {
	return (await axios.delete(`/v2/orders/${orderId}/signature`)).data;
}

export const getOrganizations = async () => {
	return (await axios.get<Organization[]>('/v2/organizations')).data;
}

export const getOrganization = async (id: number) => {
	return (await axios.get<Organization>(`/v2/organizations/${id}`)).data;
}

export const createOrganization = async (m: Organization) => {
	return (await axios.post<Organization>('/v2/organizations', m)).data;
}

export const updateOrganization = async (m: Organization) => {
	return (await axios.put<Organization>('/v2/organizations', m)).data;
}

export const deleteOrganization = async (id: number) => {
	return (await axios.delete<Organization>(`/v2/organizations/${id}`)).data;
}

export const getReflexes = async () => {
	return (await axios.get<Reflex[]>('/v2/reflexes')).data;
}

export const getReflex = async (id: number) => {
	return (await axios.get<Reflex>(`/v2/reflexes/${id}`)).data;
}

export const createReflex = async (m: Reflex) => {
	return (await axios.post<Reflex>('/v2/reflexes', m)).data;
}

export const updateReflex = async (m: Reflex) => {
	return (await axios.put<Reflex>('/v2/reflexes', m)).data;
}

export const deleteReflex = async (id: number) => {
	return (await axios.delete<Reflex>(`/v2/reflexes/${id}`)).data;
}

export const getReportGroups = async () => {
	return (await axios.get<ReportGroup[]>('/v2/report-groups')).data;
}

export const getReportGroup = async (id: number) => {
	return (await axios.get<ReportGroup>(`/v2/report-groups/${id}`)).data;
}

export const createReportGroup = async (reportGroup: ReportGroup) => {
	return (await axios.post<ReportGroup>('/v2/report-groups', reportGroup)).data;
}

export const deleteReportGroup = async (id: number) => {
	return (await axios.delete<ReportGroup>(`/v2/report-groups/${id}`)).data;
}

export const updateReportGroup = async (reportGroup: ReportGroup) => {
	return (await axios.put<ReportGroup>('/v2/report-groups', reportGroup)).data;
}

export const getReportTypes = async () => {
	return (await axios.get<ReportType[]>('/v2/report-types')).data;
}

export const getReportType = async (id: number) => {
	return (await axios.get<ReportType>(`/v2/report-types/${id}`)).data;
}

export const createReportType = async (m: ReportType) => {
	return (await axios.post<ReportType>('/v2/report-types', m)).data;
}

export const updateReportType = async (m: ReportType) => {
	return (await axios.put<ReportType>('/v2/report-types', m)).data;
}

export const deleteReportType = async (id: number) => {
	return (await axios.delete<ReportType>(`/v2/report-types/${id}`)).data;
}

export const getRoles = async () => {
	return (await axios.get<Role[]>('/v2/roles')).data;
}

export const getRole = async (id: number) => {
	return (await axios.get<Role>(`/v2/roles/${id}`)).data;
}

export const createRole = async (m: Role) => {
	return (await axios.post<Role>('/v2/roles', m)).data;
}

export const updateRole = async (m: Role) => {
	return (await axios.put<Role>('/v2/roles', m)).data;
}

export const deleteRole = async (id: number) => {
	return (await axios.delete<Role>(`/v2/roles/${id}`)).data;
}

export const getSalesReps = async () => {
	return (await axios.get<SalesRep[]>('/v2/salesReps')).data;
}

export const getSalesRep = async (id: number) => {
	return (await axios.get<SalesRep>(`/v2/salesReps/${id}`)).data;
}

export const createSalesRep = async (salesrep: SalesRep) => {
	return (await axios.post<SalesRep>('/v2/salesReps', salesrep)).data;
}

export const updateSalesRep = async (salesrep: SalesRep) => {
	return (await axios.put<SalesRep>('/v2/salesReps', salesrep)).data;
}

export const getSpecimenTypes = async () => {
	return (await axios.get<SpecimenType[]>('/v2/specimen-types')).data;
}

export const getSpecimenType = async (id: number) => {
	return (await axios.get<SpecimenType>(`/v2/specimen-types/${id}`)).data;
}

export const createSpecimenType = async (m: SpecimenType) => {
	return (await axios.post<SpecimenType>('/v2/specimen-types', m)).data;
}

export const updateSpecimenType = async (m: SpecimenType) => {
	return (await axios.put<SpecimenType>('/v2/specimen-types', m)).data;
}

export const deleteSpecimenType = async (id: number) => {
	return (await axios.delete<SpecimenType>(`/v2/specimen-types/${id}`)).data;
}

export const getTests = async () => {
	return (await axios.get<Test[]>('/v2/tests')).data;
}

export const getTest = async (id: number) => {
	return (await axios.get<Test>(`/v2/tests/${id}`)).data;
}

export const getTestSubTests = async (id: number) => {
	return (await axios.get<Test[]>(`/v2/tests/${id}/subTests`)).data;
}

export const createTest = async (m: Test) => {
	return (await axios.post<Test>('/v2/tests', m)).data;
}

export const updateTest = async (m: Test) => {
	return (await axios.put<Test>('/v2/tests', m)).data;
}

export const deleteTest = async (id: number) => {
	return (await axios.delete<Test>(`/v2/tests/${id}`)).data;
}

export const activateUser = async (id: number) => {
	return (await axios.delete<User>(`/v2/users/${id}/deactivate`)).data;
}

export const getUsers = async () => {
	return (await axios.get<User[]>('/v2/users')).data;
}

export const getUsersTableView = async () => {
	return (await axios.get<UserTableView[]>('/v2/users/table-view')).data;
}

export const getUser = async (id: number) => {
	return (await axios.get<User>(`/v2/users/${id}`)).data;
}

export const createUser = async (m: User) => {
	return (await axios.post<UserCreateRequest>('/v2/users', m)).data;
}

export const updateUser = async (m: User) => {
	return (await axios.put<User>('/v2/users', m)).data;
}

export const sendUserActivationEmail = async (id: number) => {
	return (await axios.post(`/v2/users/${id}/request-password-reset`, {})).data;
}

export const deleteUser = async (id: number) => {
	return (await axios.delete<User>(`/v2/users/${id}`)).data;
}

export const deactivateUser = async ({ id, m }: { id: number, m: User }) => {
	return (await axios.put<User>(`/v2/users/${id}/deactivate`, m)).data;
}
