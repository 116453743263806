import React from "react";
import { useIntegrationInstancesDeprecated } from "../../services/queries";
import ReactQueryAutocomplete, { ReactQueryAutocompleteProps } from "../ReactQueryAutocomplete";

type IntegrationInstanceAutoCompleteProps = Omit<ReactQueryAutocompleteProps, "query">;

export default function IntegrationInstanceAutoComplete(props: IntegrationInstanceAutoCompleteProps) {
    return (
        <ReactQueryAutocomplete {...{ ...props, query: useIntegrationInstancesDeprecated }} />
    )
}
