import * as Yup from 'yup'

export const collectorInitialValues = {
	"first_name": "",
	"last_name": "",
	"email": "",
	"phone": "",
	"is_phlebotomist": false,
	"facilities": []
}

const collectorValidation = Yup.object().shape({
	"id": Yup.number().nullable(),
	"first_name": Yup.string().required(),
	"last_name": Yup.string().required(),
	"phone": Yup.string(),
	"email": Yup.string().email(),
	"is_phlebotomist": Yup.boolean(),
	"facilities": Yup.array().of(Yup.number()).nullable(),
})

export default collectorValidation;
