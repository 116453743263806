import React, { useMemo, useCallback, useRef, useEffect } from "react";
import { Box } from "@mui/material";
import { GridActionsCellItem, GridColDef, GridRowParams, } from "@mui/x-data-grid-pro";
import { Delete } from "@mui/icons-material";
import { useCreateIntegrationInsuranceMapping, useDeleteIntegrationInsuranceMapping, useIntegrationInstancesDeprecated, useIntegrationInsuranceMappings, useInsurances } from "../services/queries";
import { ColumnStack, RowStack } from "../components/Stacks";
import { CustomTextField } from "../components/CustomTextField";
import IntegrationInstanceAutoComplete from "../components/fields/IntegrationInstances";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import insuranceMappingValidation, { insuranceMappingInitialValues } from "../validations/insurance_mappings";
import { AxiosError } from "../components/AxiosError";
import { IntegrationInsuranceMapping } from "../constants/go_types";
import { SaveButton } from "../components/Buttons";
import PanelDataGrid from "../components/PanelDataGrid";
import InsuranceAutoComplete from "../components/fields/Insurances";
import { DataGridActionDelete } from "../components/DataGridActionDelete";
import { usePermission } from "../hooks/usePermission";

// function DeleteInsuranceAction({ params }: { params: GridRowParams }) {
// const deleteMapping = useDeleteIntegrationInsuranceMapping()

// // Wrap mutation in a stable function
// const handleDelete = useCallback((id: number) => {
// deleteMapping.mutate(id);
// }, [deleteMapping]);

// return (
// <GridActionsCellItem icon={<Delete />} onClick={() => handleDelete(Number(params.id))} label="Delete" />
// )
// }

function InsuranceMappings() {
	const ref = useRef<FormikProps<any>>(null);
	const insurances = useInsurances()
	const mappings = useIntegrationInsuranceMappings()
	const addMapping = useCreateIntegrationInsuranceMapping()
	const instances = useIntegrationInstancesDeprecated()
	const canEdit = usePermission("interfaces:write")

	const handleSubmit = async (values: IntegrationInsuranceMapping) => {
		addMapping.mutate(values)
		if (ref.current) {
			ref.current.resetForm()
		}
	}

	const columns: GridColDef[] = useMemo(() => [
		{ field: "insurance_id", headerName: "Internal Insurance", width: 200, valueGetter: (params) => insurances.data?.find((t) => t.id === params.value)?.name },
		{ field: "external_insurance", headerName: "External Insurance", width: 200 },
		{ field: "instance_id", headerName: "Integration Instance", width: 300, valueGetter: (params) => instances.data?.find((i: any) => i.ID === params.value)?.Name },
		{ field: "actions", type: "actions", getActions: (params) => [<DataGridActionDelete disabled={!canEdit} useMutation={useDeleteIntegrationInsuranceMapping} params={params} />] },
	], [insurances.data, instances.data, canEdit])

	return <>
		<ColumnStack height="90%">
			<Box>
				<Formik
					initialValues={insuranceMappingInitialValues}
					validationSchema={insuranceMappingValidation}
					onSubmit={handleSubmit}
					innerRef={ref}
				>
					<Form>
						<InsuranceMappingForm />
					</Form>
				</Formik>
			</Box >
			<Box height="100%" >
				<PanelDataGrid
					storageName="insurance-mapping"
					columns={columns}
					rows={mappings.data ?? []}
				/>
			</Box>
			<AxiosError error={mappings.error || addMapping.error} />
		</ColumnStack>
	</>;
}

function InsuranceMappingForm() {
	const { isValid, isSubmitting } = useFormikContext()
	const canEdit = usePermission("interfaces:write")

	return (
		<RowStack alignItems="flex-start">
			<InsuranceAutoComplete
				required
				name="insurance_id"
				label="Internal Insurance"
				field="id"
				labelField="name"
				sx={{ width: "100%" }}
			/>
			<CustomTextField name="external_insurance" label="External Insurance" sx={{ width: "100%" }} />
			<IntegrationInstanceAutoComplete
				required
				name="instance_id"
				label="Integration Instance"
				labelField="Name"
				field="ID"
				sx={{ width: "100%" }}
			/>
			<SaveButton type="submit" disabled={!canEdit || !isValid || isSubmitting} />
		</RowStack>
	)
}

export default InsuranceMappings;




