import React from "react";
import { CustomTextField } from "../components/CustomTextField";
import PanelForm from "../components/PanelForm";
import { ColumnStack, RowStack } from "../components/Stacks";
import { useReportGroup, useCreateReportGroup, useUpdateReportGroup } from "../services/queries";
import reportGroupValidation, { reportGroupInitialValues } from "../validations/report_groups";

type ReportGroupPanelFormProps = {
	onBack: () => void;
	onSave: () => void;
	id?: number;
}

export function ReportGroupPanelForm({ id, onBack, onSave }: ReportGroupPanelFormProps) {
	const salesrep = useReportGroup(id)

	return (
		<PanelForm
			validationSchema={reportGroupValidation}
			form={<ReportGroupForm />}
			createMutation={useCreateReportGroup()}
			updateMutation={useUpdateReportGroup()}
			initialValues={salesrep.isSuccess ? salesrep.data : reportGroupInitialValues}
			onBack={onBack}
			onSave={onSave}
		/>
	)
}

export default function ReportGroupForm() {
	return (
		<ColumnStack>
			<RowStack>
				<CustomTextField name="name" label="Name" required />
			</RowStack>
		</ColumnStack>
	)
}
