import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import TestsPanel from "../components/TestsPanel";
import ProfilesPanel from "../components/ProfilesPanel";
import MethodsPanel from "../components/MethodsPanel";
import ReflexesPanel from "../components/ReflexesPanel";
import FeeSchedulePanel from "../components/FeeSchedulePanel";
import DepartmentPanel from "../components/DepartmentsPanel";
import LaboratoryPanel from "../components/LaboratoriesPanel";
import InsurancePanel from "../components/InsurancesPanel";
import DiagnosisPanel from "../components/DiagnosisPanel";
import DiagnosisRulesPanel from "../components/DiagnosisRulesPanel";
import Framework from "../components/Framework";
import InstrumentPanel from "../components/InstrumentsPanel";
import SpecimenTypePanel from "../components/SpecimenTypesPanel";
import { useTabState } from "../components/useTabState";
import CategoriesPanel from "../components/CategoryPanel";
import FrequencyRules from "../tables/frequencyrules";
import MedicationsPanel from "../components/Medications";
import MedicationBrandsPanel from "../components/MedicationBrands";
import DrugClassesPanel from "../components/DrugClasses";
import CommunityTrends from "../tables/communitytrends";
import Panel from "../components/Panel";
import EntityPanelTable from "../components/EntityPanelTable";
import { reportGroupColumns, testPanelColumns } from "../tables/columns";
import { useReportGroups, useDeleteReportGroup } from "../services/queries";
import { ReportGroupPanelForm } from "../forms/report_groups";
import { useReflexes, useDeleteReflex } from "../services/queries";
import { ReflexPanelForm } from "../forms/reflexes";
import { TestPanelForm } from "../forms/tests";
import ReflexPanelTable from "../tables/reflexes";
import { useTests, useDeleteTest } from "../services/queries";
import TestPanelTable from "../tables/tests";

export default function Setup({ tab }) {
    const [value, setValue] = useTabState(0);

    // const history = useHistory();
    const host = window.location.host;
    const client = host.split(".")[0];

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const tabs = [
        {
            label: "Tests", component: <Panel
                table={<TestPanelTable requiredPermission="setup:write" storageName="tests" />}
                form={<TestPanelForm />}
                query={useTests}
                deleteQuery={useDeleteTest()}
            />
        },
        { label: "Profiles", component: <ProfilesPanel /> },
        { label: "Methods", component: <MethodsPanel /> },
        {
            label: "Reflexes",
            component: <Panel
                table={<ReflexPanelTable requiredPermission="setup:write" storageName="reflexes" />}
                form={<ReflexPanelForm />}
                query={useReflexes}
                deleteQuery={useDeleteReflex()}
            />
        },
        { label: "Specimen Types", component: <SpecimenTypePanel /> },
        { label: "Departments", component: <DepartmentPanel /> },
        { label: "Categories", component: <CategoriesPanel /> },
        { label: "Medications", component: <MedicationsPanel /> },
        { label: "Brands", component: <MedicationBrandsPanel /> },
        { label: "Drug Classes", component: <DrugClassesPanel /> },
        {
            label: "Report Groups",
            component: <Panel
                table={<EntityPanelTable columns={reportGroupColumns} query={useReportGroups()} storageName="report-groups" requiredPermission="setup:write" />}
                form={<ReportGroupPanelForm />}
                query={useReportGroups}
                deleteQuery={useDeleteReportGroup()}
            />
        },
        { label: "Instruments", component: <InstrumentPanel /> },
        { label: "Laboratories", component: <LaboratoryPanel /> },
        { label: "Fee Schedules", component: <FeeSchedulePanel /> },
        { label: "Insurances", component: <InsurancePanel /> },
        { label: "Diagnosis Codes", component: <DiagnosisPanel /> },
        { label: "Diagnosis Rules", component: <DiagnosisRulesPanel /> },
        { label: "Frequency Rules", component: <FrequencyRules /> },
    ]

    if (client === "beechtree" || client === "test") {
        tabs.push({ label: "Community Trends", component: <CommunityTrends /> })
    }

    return (
        <Framework table>
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Setup</Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                    >
                        {tabs.map((tab, index) => (
                            <Tab label={tab.label} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                </Box>
            </Box>
            {tabs.map((tab, index) => (
                <TabPanel key={index} value={value} index={index}>
                    {tab.component}
                </TabPanel>
            )
            )}
        </Framework>
    );
}
