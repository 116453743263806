import { matchSorter } from "match-sorter";
import React from "react";
import { useProviders } from "../../services/queries";
import ReactQueryAutocomplete, { ReactQueryAutocompleteProps } from "../ReactQueryAutocomplete";

type ProviderAutoCompleteProps = Omit<ReactQueryAutocompleteProps, "query">;

export default function ProviderAutoComplete(props: ProviderAutoCompleteProps) {

    const matchKeys = ["first_name", "last_name", "npi"]

    const filterOptions = (options: any[], { inputValue }: { inputValue: any }) => matchSorter(options, inputValue, { keys: matchKeys });
    return (
        <ReactQueryAutocomplete {...{ ...props, query: useProviders }}
            getOptionLabel={(p) => p.first_name + " " + p.last_name + " | " + p.npi}
            filterOptions={filterOptions}
        />
    )
}
