import React, { useState } from "react";
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import DataTableContainer from "./DataTableContainer";

type PanelProps = {
    table: React.ReactElement;
    form: React.ReactElement;
    query: UseQueryResult<any>;
    deleteQuery?: UseMutationResult<any>;
    onDeleteOverride?: (id: number, defaultDelete: (id: number) => void) => void; // Optional override
};

export default function Panel({ table, form, query, deleteQuery, onDeleteOverride }: PanelProps) {
    const [view, setView] = useState<"table" | "form">("table");
    const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

    // Switch to form (for create)
    const handleCreate = () => {
        setSelectedId(undefined);
        setView("form");
    };

    // Switch to form (for edit)
    const handleEdit = (id: number) => {
        setSelectedId(id);
        setView("form");
    };

    // dont switch to form, just refresh data
    const handleDelete = (id: number) => {
        if (!deleteQuery) return;

        setSelectedId(id);
        deleteQuery.mutate(id)
    };

    // Composed delete handler with override support
    const composedHandleDelete = (id: number) => {
        if (onDeleteOverride) {
            // Pass the default handler to the override for composition
            onDeleteOverride(id, handleDelete);
        } else if (deleteQuery) {
            handleDelete(id);
        }
    };

    // Return to table and refresh data
    const handleSave = () => {
        setView("table");
        query.refetch();
    };

    // Return to table without saving
    const handleBack = () => {
        console.log('back')
        setView("table");
    };

    // Clone table and inject handlers
    const enhancedTable = React.cloneElement(table, {
        onCreate: handleCreate,
        onEdit: handleEdit,
        onDelete: composedHandleDelete,
    });

    const enhancedForm = React.cloneElement(form, {
        id: selectedId,
        onBack: handleBack,
        onSave: handleSave,
    })

    // Render table or form
    return view === "table" ? (
        <DataTableContainer>
            {enhancedTable}
        </DataTableContainer>)
        :
        enhancedForm;
}
