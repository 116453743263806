import MethodsTable from "../tables/MethodsTable";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { getMethodDetails, getMethods } from "../services/methods";
import MethodForm from "./MethodEdit";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";
import { usePermission } from "../hooks/usePermission";

export default function MethodsPanel() {
    const initValues = {
        Test: null,
        Units: "",
        Instruments: [],
        InstrumentTestCode: "",
        RangeType: "NoRange",
        HasDependencies: false,
        MaxReportableValue: null,
        MinReportableValue: null,
        Precision: 2,
        CalculationExpression: "",
        Dependencies: [],
        RefRangeList: [],
        RefRangeNumeric: [],
        Medications: [],
    }

    const [view, setView] = useState("table");
    const [method, setMethod] = useState();
    const [methodDetails, setMethodDetails] = useState({});
    const [refresh, setRefresh] = useState(false);
    const canEdit = usePermission("setup:write")

    useEffect(() => {
        if (method !== undefined) {
            getMethodDetails(method[0]).then((p) => {
                setMethodDetails(p)
            });
        }
    }, [method]);

    if (view === "table") {
        return (
            <>
                <TableFrame>
                    <DataTableContainer>
                        <MethodsTable
                            methods={method}
                            setMethods={setMethod}
                            checkboxes={false}
                            refresh={refresh}
                        />
                    </DataTableContainer>
                    <ButtonTray>
                        <Button
                            disabled={!canEdit}
                            variant="contained"
                            color="success"
                            onClick={() => {
                                setMethod(undefined);
                                setMethodDetails({});
                                setView("create");
                            }}
                        >
                            Create
                        </Button>
                        <Button
                            disabled={!canEdit || !method}
                            variant="contained"
                            color="primary"
                            onClick={() => setView("edit")}
                        >
                            Edit
                        </Button>
                    </ButtonTray>
                </TableFrame>
            </>
        );
    } else if (view === "create") {
        const saved = () => { setView("table"); setRefresh(!refresh) };
        return (
            <MethodForm
                methodDetails={initValues}
                setMethodDetails={setMethodDetails}
                saved={saved}
            />
        );
    } else if (view === "edit") {
        const saved = () => { setView("table"); setRefresh(!refresh) };
        return (
            <MethodForm
                methodDetails={methodDetails}
                setMethodDetails={setMethodDetails}
                saved={saved}
            />
        );
    }
}
