import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { Box, Button } from "@mui/material";
import { Stack } from "@mui/system";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useNavigate } from "react-router";
import { GetShipmentStatusChip } from "./StatusChips";
import ButtonTray from "./ButtonTray";
import { DataTableContainer } from "./TableContainer";
import TableFrame from "./TableFrame";
import { FormatDateTime } from "../services/util";
import CustomToolbar from "./GridToolbar";
import { usePermission } from "../hooks/usePermission";

export default function ShipmentsTable({ getShipments, refresh }) {
    const [shipments, setShipments] = useState([]);
    const [selectedShipment, setSelectedShipment] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const canEdit = usePermission("shipments:write");

    const renderViewButton = (params) => {
        return (
            <LocalShippingIcon
                sx={{ "&:hover": { color: "#1976d2" } }}
                onClick={() => navigate("/shipments/" + params.id)}
            />
        );
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 70 },
        { field: "Tracking", headerName: "Tracking #", width: 150 },
        { field: "Samples", headerName: "Samples", width: 100 },
        { field: "Status", headerName: "Status", width: 100, renderCell: (params) => GetShipmentStatusChip(params.row?.Status) },
        {
            field: "DateShipped",
            headerName: "Shipped On",
            width: 120,
            valueGetter: (row) => FormatDateTime(row.row.DateShipped),
        },
        {
            field: "DateReceived",
            headerName: "Received On",
            width: 120,
            valueGetter: (row) => FormatDateTime(row.row.DateReceived),
        },
        {
            field: "ReceivedBy",
            headerName: "Received By",
            width: 100,
            valueGetter: (row) => row.row.ReceivedBy,
        },
        { field: "Courier", headerName: "Courier", width: 200 },
        { field: "Destination", headerName: "Destination", width: 250, valueGetter: (row) => row.row.Destination.Name },
        {
            field: "View",
            headerName: "View",
            width: 100,
            renderCell: renderViewButton,
        },
    ];

    useEffect(() => {
        getShipments().then((p) => {
            setShipments(p);
            setLoading(false);
        });
    }, [refresh]);

    return <>
        <TableFrame>
            <DataTableContainer>
                <DataGridPro
                    hideFooterRowCount
                    loading={loading}
                    pagination
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "ID", sort: "desc" }],
                        },
                    }}
                    getRowId={(row) => row.ID}
                    density="compact"
                    components={{ Toolbar: CustomToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    rowSelectionModel={selectedShipment}
                    onRowSelectionModelChange={setSelectedShipment}
                    rows={shipments}
                    columns={columns}
                />
            </DataTableContainer>
            <ButtonTray>
                <Button
                    href="/newshipment"
                    variant="contained"
                    color="success"
                    disabled={!canEdit}
                >
                    Create
                </Button>
            </ButtonTray>
        </TableFrame>
    </>;
}
