import { Delete } from "@mui/icons-material";
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid-pro";
import { UseMutationResult } from "@tanstack/react-query";
import React from "react";
import { useCallback } from "react";


type DataGridActionDeleteProps = {
    params: GridRowParams
    useMutation: () => UseMutationResult<any, unknown, number, unknown>
    disabled?: boolean
}

export function DataGridActionDelete({ params, useMutation, disabled }: DataGridActionDeleteProps) {
    const mutation = useMutation()
    // Wrap mutation in a stable function
    const handleDelete = useCallback((id: number) => {
        mutation.mutate(id);
    }, [mutation]);

    return (
        <GridActionsCellItem icon={<Delete />} disabled={disabled} onClick={() => handleDelete(Number(params.id))} label="Delete" />
    )
}

