import * as Yup from 'yup'

export const reportGroupInitialValues = {
	"name": "",
}

const reportGroupValidation = Yup.object().shape({
	"id": Yup.number().nullable(),
	"name": Yup.string().required(),
})

export default reportGroupValidation;
