import * as Yup from 'yup'

export const testCodeMappingInitialValues = {
	"test_code": 0,
	"external_test_code": "",
	"instance_id": 0,
}

const testCodeMappingValidation = Yup.object().shape({
	"test_code": Yup.number().required(),
	"external_test_code": Yup.string().required(),
	"instance_id": Yup.number().required(),
})

export default testCodeMappingValidation;
