import React, { useState, useEffect, useMemo, ReactNode, useCallback } from "react";

import {
    Box,
    Stack,
    TextField,
    Autocomplete,
    Button,
    FormControlLabel,
    Checkbox,
} from "@mui/material";

import { GridToolbar, GridActionsCellItem, GridColDef, GridRowParams, useGridApiRef, GridRenderEditCellParams, DataGridPro } from "@mui/x-data-grid-pro";
import { Edit } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import { Insurance } from "../constants/types";
import { apiFetch } from "../services/fetch";
import ErrorAlert from "./ErrorAlert";
import { usePermission } from "../hooks/usePermission";


type CustomRule = {
    CPTCode: string;
    ICD10Code: string;
    InsuranceID: number | null;
    Supported: boolean;
}

const DiagnosisValidity = () => {
    const ruleDefaults = {
        CPTCode: "",
        ICD10Code: "",
        InsuranceID: null,
        Supported: true,

    }
    const [rules, setRules] = useState<CustomRule[]>([]);
    const [newRule, setNewRule] = useState<CustomRule>(ruleDefaults);
    const [newRuleError, setNewRuleError] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [payers, setPayers] = useState<Insurance[]>([]);
    const apiRef = useGridApiRef()
    const canEdit = usePermission("setup:write")

    useEffect(() => {
        const init = async () => {
            let response = await apiFetch("/diagnosisValidity");
            setRules(response);

            response = await apiFetch("/insurances");
            setPayers(response);
        };

        init();
    }, [refresh]);


    const saveEdit = (newRow: any, oldRow: any) => {
        console.log('oldValues', oldRow)
        console.log('newValues', newRow)
        newRow.InsuranceID = newRow.Insurance?.ID
        newRow.ICD10Code = newRow.ICD10Code.replace(".", "")
        const updateRule = async () => {
            try {
                await apiFetch(
                    `/diagnosisValidity/${newRow.ID}`,
                    'POST',
                    newRow
                );
                setError(null);
                setRefresh(!refresh);
            } catch (error) {
                console.log(error)
                setError("Failed to update row");
            }
        };

        return updateRule();
    };


    const addRule = () => {
        if (newRule.CPTCode === "") {
            setNewRuleError({
                ...newRuleError,
                Code: "CPT Code is required",
            });
            return;
        } else if (newRule.ICD10Code === "") {
            setNewRuleError({
                ...newRuleError,
                Name: "ICD10 Code is required",
            });
            return;
        }

        setNewRuleError({});

        const add = async () => {
            newRule.ICD10Code = newRule.ICD10Code.replace(".", "")
            try {
                // @ts-ignore
                await apiFetch(`/diagnosisValidity`, 'POST', newRule);
                setError(null);
                setRefresh(!refresh);
                setNewRule({
                    ...{},
                    ...ruleDefaults,
                });
            } catch (error) {
                console.log(error)
                setError("Failed to add row");
            }
        };
        add();
    };

    const RenderValueCell = useCallback((params: GridRenderEditCellParams): ReactNode => {
        const { id, value, field } = params;
        const handleValueChange = (
            _: React.SyntheticEvent<Element, Event>,
            newValue: Insurance | null,
        ) => {
            apiRef.current.setEditCellValue({ id, field, value: newValue });
            if (newValue) {
                const payerId = newValue?.ID
                apiRef.current.setEditCellValue({ id, field: "InsuranceID", value: payerId });
            }
        };

        return (
            <Autocomplete
                options={payers}
                getOptionLabel={(option) =>
                    option.Name
                }
                value={value}
                sx={{ minWidth: "200px", width: "100%" }}
                isOptionEqualToValue={(
                    option,
                    value
                ) => option.ID === value.ID}
                onChange={handleValueChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                    />
                )}
            />
        )
    }, [apiRef, payers]);

    const deleteRow = async (id: number) => {
        try {
            // @ts-ignore
            await apiFetch(`/diagnosisValidity/${id}`, 'DELETE', {});
        } catch (e) {
            console.log(e)
        } finally {
            setRefresh(!refresh)
        }
    }

    const getActions = useCallback((params: GridRowParams) => {
        console.log(apiRef.current.getRowMode(params.id))
        return [
            <GridActionsCellItem icon={<Edit />} disabled={!canEdit || apiRef.current.getRowMode(params.id) !== "view"} onClick={() => apiRef.current.startRowEditMode({ id: params.id })} label="Edit" />,
            <GridActionsCellItem icon={<Delete />} disabled={!canEdit} onClick={() => deleteRow(Number(params.id))} label="Delete" />

        ]
    }, [apiRef, canEdit])

    const columns: GridColDef[] = useMemo(() => [
        { field: "CPTCode", headerName: "CPT Code", editable: true, width: 100 },
        { field: "ICD10Code", headerName: "ICD10 Code", editable: true, width: 100 },
        { field: "Insurance", headerName: "Insurance", width: 250, valueFormatter: (params) => params?.value?.Name, editable: true, renderEditCell: RenderValueCell },
        { field: "Supported", headerName: "Supported", editable: true, width: 100, type: "boolean" },
        { field: "actions", type: "actions", getActions: getActions },
    ], [RenderValueCell, getActions])

    const RuleDataTable = () => {
        return (
            <DataGridPro
                getRowId={(row) => row.ID}
                apiRef={apiRef}
                columns={columns}
                rows={rules || []}
                editMode="row"
                processRowUpdate={saveEdit}
                onProcessRowUpdateError={(e) => { console.log(e); setError("Failed to update row") }}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                density="compact"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        )
    }

    return (
        <>
            <Stack direction={{ sm: "column", md: "row" }} spacing={2} mb={2}>
                <Stack direction="row" spacing={2}>
                    <TextField
                        label="CPT Code"
                        value={newRule?.CPTCode}
                        size="small"
                        onChange={(e) =>
                            setNewRule({
                                ...newRule,
                                CPTCode: e.target.value,
                            })
                        }
                    />
                    <TextField
                        label="ICD10 Code"
                        value={newRule?.ICD10Code}
                        size="small"
                        onChange={(e) =>
                            setNewRule({
                                ...newRule,
                                ICD10Code: e.target.value,
                            })
                        }
                    />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <Autocomplete
                        options={payers}
                        getOptionLabel={(option) =>
                            option.Name
                        }
                        sx={{ minWidth: "200px" }}
                        value={payers.find((p) => p.ID === newRule?.InsuranceID)}
                        onChange={(_, newValue) =>
                            setNewRule({
                                ...newRule,
                                InsuranceID: newValue?.ID || null,
                            })
                        }
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                label="Insurance"
                                {...params} />
                        )}
                    />
                    <FormControlLabel control={<Checkbox
                        checked={newRule.Supported}
                        onChange={(e) => setNewRule({
                            ...newRule,
                            Supported: e.target.checked,
                        })}
                    />} label={"Supported"} />
                    <Button variant="contained" color="primary" onClick={() => addRule()} disabled={!canEdit} >Add</Button>
                </Stack>
            </Stack>
            <Box height={`calc(100vh - 425px)`}>
                <RuleDataTable />
            </Box>
            <ErrorAlert error={error} />
        </>
    )
}

export default DiagnosisValidity;

