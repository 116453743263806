import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography"; import FacilitySetup from "../components/FacilitySetup";
import TabPanel, { a11yProps } from "../components/TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ProviderSetup from "../components/ProviderSetup";
import PatientOrderLinkSetup from "../components/PatientOrderLinkSetup";
import { useTabState } from "../components/useTabState";
import ProviderNoteTriggerSetup from "../components/ProviderNoteTriggerSetup";
import FacilityReportServiceMappings from "../tables/facilityservicereports";
import OrganizationsPanel from "../components/Organizations";
import { useCollectors, useSalesReps, useContacts, useDeleteContact, useRoles, useDeleteRole, useUsers, useDeleteUser } from "../services/queries";
import { CollectorPanelForm } from "../forms/collectors";
import Panel from "../components/Panel";
import { collectorPanelColumns, contactPanelColumns, salesRepPanelColumns, rolePanelColumns, userPanelColumns } from "../tables/columns";
import EntityPanelTable from "../components/EntityPanelTable";
import { SalesRepPanelForm } from "../forms/sales_reps";
import { ContactPanelForm } from "../forms/contacts";
import Frameworkv2 from "../components/Frameworkv2";
import { RolePanelForm } from "../forms/roles";
import { UserPanelForm } from "../forms/users";
import UserPanelTable from "../tables/users";

export default function Facilities() {
    const [tabValue, setTabValue] = useTabState(0)

    const tabs = [
        { label: "Facilities", component: <FacilitySetup /> },
        { label: "Organizations", component: <OrganizationsPanel /> },
        { label: "Providers", component: <ProviderSetup /> },
        {
            label: "Contacts",
            component: <Panel
                table={<EntityPanelTable
                    columns={contactPanelColumns}
                    query={useContacts()}
                    storageName="facilities"
                    requiredPermission="facilities:write" />}
                form={<ContactPanelForm />}
                deleteQuery={useDeleteContact()}
                query={useContacts} />
        },
        {
            label: "Collectors",
            component: <Panel
                table={<EntityPanelTable
                    columns={collectorPanelColumns}
                    query={useCollectors()}
                    storageName="collectors"
                    requiredPermission="facilities:write" />}
                form={<CollectorPanelForm />}
                query={useCollectors} />
        },
        {
            label: "Sales Reps",
            component: <Panel
                table={<EntityPanelTable
                    columns={salesRepPanelColumns}
                    query={useSalesReps()}
                    storageName="salesreps"
                    requiredPermission="facilities:write" />}
                form={<SalesRepPanelForm />}
                query={useSalesReps} />
        },
        {
            label: "Users", component: <Panel
                table={<UserPanelTable
                    storageName="users"
                    requiredPermission="facilities:write" />}
                form={<UserPanelForm />}
                deleteQuery={useDeleteUser()}
                query={useUsers} />
        },
        {
            label: "Roles",
            component: <Panel
                table={<EntityPanelTable
                    columns={rolePanelColumns}
                    query={useRoles()}
                    storageName="roles"
                    requiredPermission="facilities:write" />}
                form={<RolePanelForm />}
                deleteQuery={useDeleteRole()}
                query={useRoles} />
        },
        { label: "Patient Order Links", component: <PatientOrderLinkSetup /> },
        { label: "Provider Note Triggers", component: <ProviderNoteTriggerSetup /> },
        { label: "Facility Report Services", component: <FacilityReportServiceMappings /> }

    ]

    const handleChange = (_, newValue) => {
        setTabValue(newValue);
    };

    return (<Frameworkv2 table>
        <Box sx={{ pl: 2, pr: 2, pt: 1 }} height="100%">
            <Typography variant="h5">Facilities</Typography>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                }}
            >
                <Tabs
                    value={tabValue}
                    variant="scrollable"
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    {tabs.map((tab, index) => (

                        <Tab label={tab.label} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box>
        </Box>
        {tabs.map((tab, index) => (
            <TabPanel value={tabValue} index={index}>
                {tab.component}
            </TabPanel>
        ))}
    </Frameworkv2>
    );
}
