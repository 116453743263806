import * as Yup from 'yup'
import { NoteTypeFacility } from "../constants/go_types";

export const orderNoteInitialValues = {
	order_id: 0,
	note_id: undefined,
	note_type: NoteTypeFacility,
	note: {
		added_by_id: 0,
		note: "",
	}
}

// this shape looks stange because the object 
// is what's passed in for react query 
// which is multiple keys
export const orderNoteValidation = Yup.object().shape({
	"orderId": Yup.number().required(),
	"m": Yup.object().shape({
		"order_id": Yup.number().required(),
		"note_id": Yup.number().nullable(),
		"note_type": Yup.number(),
		"note": Yup.object().shape({
			"note": Yup.string().required(),
		})
	})
})

