import { useField, useFormikContext } from "formik";
import React from "react";
import { Test } from "../../constants/go_types";
import { useTests } from "../../services/queries";
import { CustomTextField } from "../CustomTextField";

export default function TestCode() {
    const tests = useTests()
    const { values } = useFormikContext<Test>()
    const [field, meta] = useField("code")

    const isDuplicateCode = !!tests.data?.find((t) => String(t.code) === field.value && t.id !== values.id)
    const duplicateError = isDuplicateCode ? field.value + " already exists" : ""

    return (
        <CustomTextField
            inputProps={{ type: "number", min: 1 }}
            name="code"
            label="Code"
            required
            error={(meta.touched && meta.error) || isDuplicateCode}
            helperText={meta.error || duplicateError}
            type="number"
        />
    )
}
