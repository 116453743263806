import React from "react";
import { useDepartments } from "../../services/queries";
import ReactQueryAutocomplete, { ReactQueryAutocompleteProps } from "../ReactQueryAutocomplete";

type DepartmentAutoCompleteProps = Omit<ReactQueryAutocompleteProps, "query">;

export default function DepartmentAutoComplete(props: DepartmentAutoCompleteProps) {
    return (
        <ReactQueryAutocomplete {...{ ...props, query: useDepartments }} />
    )
}
