
import { useContext } from "react";
import { SessionContext } from "./getContext";


// accepts a permission and returns a boolean value
export function usePermission(perm: string) {
	const session = useContext(SessionContext);

	// @ts-ignore
	return session.user.Permissions.includes(perm);
}
