import DataTable from "../components/DataTable";
import { useState, useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { apiFetch } from "../services/fetch";
import PatientOrderLinkForm from "./PatientOrderLinkForm";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import Title from "./Title";
import ButtonTray from "./ButtonTray";
import { Button } from "@mui/material";
import { usePermission } from "../hooks/usePermission";

function PatientOrderLinkTable({ links, setSelected, error }) {
    const host = window.location.host;
    const protocol = window.location.protocol;
    console.log(protocol);

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const getLink = (params) => {
        return <a target="_blank" rel="noreferrer" href={`${protocol}//${host}/patientOrder?id=${params?.row?.ID}`}>LINK</a>
    }


    const columns = [
        { field: "ID", headerName: "ID", width: 330 },
        { field: "Enabled", headerName: "Enabled", width: 100, renderCell: getCheckbox },
        { field: "Facility", headerName: "Facility", width: 200, valueGetter: (params) => (params?.row?.Facility?.Name) },
        { field: "Provider", headerName: "Provider", width: 200, valueGetter: (params) => (`${params?.row?.Provider?.FirstName} ${params?.row?.Provider?.LastName}`) },
        { field: "AOEs", headerName: "AOEs", width: 150, valueGetter: (params) => (params?.row?.AOEs.length) },
        { field: "DiagnosisQuestions", headerName: "Diagnosis Questions", width: 200, valueGetter: (params) => (params?.row?.DiagnosisQuestions.length) },
        { field: "TestCodes", headerName: "TestCodes", width: 200, valueGetter: (params) => (params?.row?.TestCodes.length) },
        { field: "Link", headerName: "Link", width: 200, renderCell: getLink },

    ]

    return <DataTable
        data={links}
        error={error}
        setSelected={setSelected}
        columns={columns}

    />

}

function PatientOrderLinkSetup() {
    const [links, setLinks] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [create, setCreate] = useState(false)
    const [edit, setEdit] = useState(false)
    const [error, setError] = useState(null)
    const [selected, setSelected] = useState([])
    const canEdit = usePermission("facilities:write")

    useEffect(() => {
        const getLinks = async () => {
            try {
                const response = await apiFetch("/facilities/patientOrder", "GET")
                setLinks(response)
            } catch (e) {
                setError("failed to load patient order links")
            }
        }

        getLinks()

    }, [refresh])


    return (
        <>
            {!edit && !create ?
                <TableFrame>
                    <DataTableContainer>
                        <Title></Title>
                        <PatientOrderLinkTable links={links} error={error} setSelected={setSelected} />
                    </DataTableContainer>
                    <ButtonTray>
                        <Button
                            disabled={!canEdit}
                            variant="contained"
                            color="success"
                            onClick={() => setCreate(true)}
                        >
                            Create
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!canEdit || selected.length === 0}
                            onClick={() => setEdit(true)}
                        >
                            Edit
                        </Button>
                    </ButtonTray></TableFrame>
                :
                edit ? <PatientOrderLinkForm id={selected[0]} callback={() => { setEdit(false); setRefresh(!refresh) }} />
                    : <PatientOrderLinkForm callback={() => { setCreate(false); setRefresh(!refresh) }} />
            }
        </>
    )

}

export default PatientOrderLinkSetup;
