import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { useField } from "formik";
import React from "react";
import { useFacilitiesDeprecated } from "../../services/queries";
import { AxiosError } from "../AxiosError";

const columns = [
    { field: "ID", headerName: "ID", width: 45 },
    { field: "Name", headerName: "Name", width: 225 },
];

type FacilityTableSelectorProps = {
    name: string;
}

function FacilityTableSelector({ name }: FacilityTableSelectorProps) {
    const [field, _, helper] = useField(name);
    const facilities = useFacilitiesDeprecated();

    return (
        facilities.isError ? (<AxiosError error={facilities.error} />)
            : (
                <DataGridPro
                    loading={facilities.isLoading}
                    density="compact"
                    getRowId={(row) => row.ID}
                    rows={facilities.data || []}
                    columns={columns}
                    onRowSelectionModelChange={(v) => helper.setValue(v)}
                    rowSelectionModel={field.value ?? []}
                    checkboxSelection={true}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{

                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            )
    )
}

export default FacilityTableSelector;
