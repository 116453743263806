import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Alert, InputLabel, Paper, TableContainer, Typography } from "@mui/material";
import PatientInsurancesTable from "./InsurancesTable";
import PatientOrdersTable from "./PatientOrdersTable";
import { getPatient } from "../services/patients";
import { FormatDateUTC } from "../services/util";
import Title from "./Title";
import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { financialClassIndex, formatAddress } from "../services/util";
import AddAttachment from "./NewAttachment";
import PatientAttachmentsTable from "./PatientAttachmentsTable";
import { useNavigate } from "react-router";
import MedicationsTable from "./MedicationsTable";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import AllergysTable from "./AllergyTable";
import { usePermission } from "../hooks/usePermission";

export default function PatientDisplay({ patient, back }) {
    const [patientDetails, setPatientDetails] = useState();
    const canEdit = usePermission("patients:write");
    const [addAttachment, setAddAttachment] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        getPatient(patient)
            .then((p) => {
                if (!p.ok) {
                    throw new Error(
                        "Failed to load patient. Please try again."
                    );
                }
                return p.json();
            })
            .then((p) => {
                setError("");
                setPatientDetails(p);
            })
            .catch((e) => setError(e.message));
    }, [addAttachment, refresh]);

    if (error != "") {
        console.log(error);
        return (
            <Box width="100%">
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    if (patientDetails === undefined) {
        return <></>;
    }

    const allergiesTable = () => {
        if (patientDetails.Allergies.length === 0) {
            return <></>;
        }
        return (
            <Grid item xs={12}>
                <Paper
                    elevation={5}
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        m: 2,
                    }}
                >
                    <Title>Reported Allergies</Title>
                    <AllergysTable
                        allergys={patientDetails?.Allergies}
                    />
                </Paper>
            </Grid>
        );
    };


    const medicationsTable = () => {
        if (patientDetails.Medications.length === 0) {
            return <></>;
        }
        return (
            <Grid item xs={12}>
                <Paper
                    elevation={5}
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        m: 2,
                    }}
                >
                    <Title>Reported Medications</Title>
                    <MedicationsTable
                        medications={patientDetails?.Medications}
                    />
                </Paper>
            </Grid>
        );
    };

    return (
        <Box width="100%">
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={6}>
                    <Paper
                        elevation={5}
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            m: 2,
                        }}
                    >
                        <AddAttachment
                            open={addAttachment}
                            setOpen={setAddAttachment}
                            patientID={patient}
                        />
                        <Title>Patient</Title>
                        <Typography variant="h6" display="block" align="left">
                            {patientDetails["LastName"]},{" "}
                            {patientDetails["FirstName"]}
                        </Typography>
                        <Typography variant="h6" display="block" align="left">
                            {FormatDateUTC(patientDetails.DOB)}
                        </Typography>
                        <Typography
                            variant="body1"
                            display="block"
                            align="left"
                        >
                            {patientDetails.Sex}
                        </Typography>
                        <Divider />
                        <InputLabel>Address</InputLabel>
                        <Typography variant="body1" display="block">
                            {patientDetails.Address.Address1}
                            {patientDetails.Address.Address2 === ""
                                ? null
                                : `, ${patientDetails.Address.Address2}`}
                        </Typography>
                        <Typography variant="body1" display="block">
                            {patientDetails.Address.City},{" "}
                            {patientDetails.Address.State}{" "}
                            {patientDetails.Address.ZipCode}
                        </Typography>
                        <Divider />
                        <InputLabel>Financial Class</InputLabel>
                        <Typography variant="body1" display="block">
                            {financialClassIndex[patientDetails.FinancialClass]}
                        </Typography>
                        <Divider />
                        <InputLabel>Contact</InputLabel>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {patientDetails?.TextResults ? (
                                <NotificationsActiveIcon color="primary" />
                            ) : (
                                <NotificationsOffIcon color="disabled" />
                            )}
                            <Typography variant="body1">
                                Phone: {patientDetails?.Phone}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {patientDetails?.EmailResults ? (
                                <NotificationsActiveIcon color="primary" />
                            ) : (
                                <NotificationsOffIcon color="disabled" />
                            )}
                            <Typography variant="body1">
                                Email: {patientDetails?.Email}
                            </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {patientDetails?.FaxResults ? (
                                <NotificationsActiveIcon color="primary" />
                            ) : (
                                <NotificationsOffIcon color="disabled" />
                            )}
                            <Typography variant="body1">
                                Fax: {patientDetails?.Fax}
                            </Typography>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper
                        elevation={5}
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            m: 2,
                        }}
                    >
                        <Title>Facility</Title>
                        <Typography variant="h6">
                            {patientDetails.Facility.Name}
                        </Typography>
                        <Divider />
                        <InputLabel>Address</InputLabel>
                        <Typography varianty="body2">
                            {formatAddress(
                                patientDetails?.Facility?.Address?.Address1,
                                patientDetails?.Facility?.Address?.Address2,
                                patientDetails?.Facility?.Address?.City,
                                patientDetails?.Facility?.Address?.State,
                                patientDetails?.Facility?.Address?.ZipCode
                            )}
                        </Typography>
                        <Divider />
                        <InputLabel>Credentials</InputLabel>
                        <Typography varianty="body2">
                            NPI: {patientDetails?.Facility?.NPI}
                        </Typography>
                        <Typography varianty="body2">
                            FEIN: {patientDetails?.Facility?.FEIN}
                        </Typography>
                        <Divider />
                        <InputLabel>Contact</InputLabel>
                        <Typography varianty="body2">
                            Phone: {patientDetails?.Facility?.Phone}
                        </Typography>
                        <Typography varianty="body2">
                            Email: {patientDetails?.Facility?.Email}
                        </Typography>
                        <Typography varianty="body2">
                            Fax: {patientDetails?.Facility?.Fax}
                        </Typography>

                    </Paper>
                </Grid>
            </Grid>
            {medicationsTable()}
            {allergiesTable()}
            {patientDetails.Attachments !== undefined &&
                patientDetails.Attachments.length > 0 ? (
                <Paper
                    elevation={5}
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        m: 2,
                    }}
                >
                    <Title>Attachments</Title>
                    <TableContainer sx={{ maxHeight: "500px" }}>
                        <PatientAttachmentsTable patientDetails={patientDetails} />
                    </TableContainer>
                </Paper>
            ) : (
                <></>
            )}

            {patientDetails.FinancialClass > 1 ? (
                <Paper
                    elevation={5}
                    sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        m: 2,
                    }}
                >
                    <Title>Insurance</Title>
                    <TableContainer sx={{ maxHeight: "500px" }}>
                        <PatientInsurancesTable
                            insurances={patientDetails.PatientInsurances}
                            setInsurances={(ins) => {
                                let pi = { ...patientDetails };
                                pi.PatientInsurances = ins;

                                setPatientDetails(pi)
                            }}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        />
                    </TableContainer>
                </Paper>
            ) : (
                <></>
            )}
            <Paper
                elevation={5}
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    m: 2,
                }}
            >
                <Title>Orders</Title>
                <PatientOrdersTable patient={patient} />
            </Paper>
            <Stack direction="row" justifyContent="space-between">
                <Button variant="outlined" onClick={back}>
                    Back
                </Button>

                <Stack
                    direction="row"
                    spacing={2}
                    divider={<Divider orientation="vertical" />}
                >
                    <Button
                        variant="contained"
                        onClick={() => setAddAttachment(true)}
                        disabled={!canEdit}
                    >
                        Add Attachment
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => navigate("/neworder?patient=" + patient)}
                        disabled={!canEdit}
                    >
                        Add Order
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
}
