import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";

export default function SpecimenTypeTable({
    specimenTypes,
    setSpecimenTypes,
    allSpecimenTypes,
    checkboxs,
    sx,
    showPriority,
}) {
    const specimenColumns = [
        {
            field: "Prefix",
            headerName: "Prefix",
            width: 50,
        },
        {
            field: "Container",
            headerName: "Container",
            width: 250,
        },
        {
            field: "Specimen",
            headerName: "Specimen",
            width: 250,
        },
        {
            field: "Directions",
            headerName: "Directions",
        },
    ];

    let specimenColumnsWPriority = [
        {
            field: "Priority",
            headerName: "Priority",
            width: 100,
            type: "number",
            valueGetter: (row) => {
                if (!specimenTypes) {
                    return "";
                }


                const i = specimenTypes.indexOf(row.id);
                if (i > -1) {
                    return i + 1;
                } else {
                    return "";
                }
            },
        },
    ];
    specimenColumnsWPriority = specimenColumnsWPriority.concat(specimenColumns);

    return (
        <DataGridPro
            hideFooterRowCount
            getRowId={(row) => row.ID}
            density="compact"
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            sx={sx}
            keepNonExistentRowsSelected={true}
            rowSelectionModel={specimenTypes}
            onRowSelectionModelChange={setSpecimenTypes}
            checkboxSelection={checkboxs}
            rows={allSpecimenTypes}
            columns={
                showPriority === true
                    ? specimenColumnsWPriority
                    : specimenColumns
            }
        />
    );
}
