import * as Yup from 'yup'

export const roleInitialValues = {
	name: "",
	type: "Lab",
	permissions: [],
}

export const roleValidation = Yup.object().shape({
	"name": Yup.string().required(),
	"type": Yup.string().required(),
	"permissions": Yup.array().of(Yup.number()).min(1).required(),
}) 
