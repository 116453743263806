import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { User } from '../../constants/go_types';
import { useDeactivateUser, useUsersTableView } from '../../services/queries';
import { AxiosError } from '../AxiosError';

type DisableUserDialogProps = {
    userId: number | null;
    setUserId: React.Dispatch<React.SetStateAction<number | null>>;
}

function DisableUserDialog({ userId, setUserId }: DisableUserDialogProps) {
    const [disableReason, setDisableReason] = React.useState<string>("");
    const deleteUser = useDeactivateUser()
    const userTableView = useUsersTableView()

    const save = async () => {
        const user: User = {
            id: userId!,
            is_active: false,
            deactivated_reason: disableReason,
        }
        await deleteUser.mutateAsync({ id: userId!, m: user },
            { onSuccess: () => { setUserId(null); userTableView.refetch() } })
    }

    return (
        <Dialog open={!!userId}>
            <DialogTitle>Note</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ mt: 2 }}
                    label="Deactivation Reason"
                    multiline
                    value={disableReason}
                    onChange={(e) => setDisableReason(e.target.value)}
                />
                <AxiosError error={deleteUser.error} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setUserId(null)}>Close</Button>
                <Button onClick={() => save()} disabled={deleteUser.isPending || disableReason === ""}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DisableUserDialog;
