import { Stack } from "@mui/system";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Box from "@mui/system/Box";
import TextField from "@mui/material/TextField";
import { Alert, Button, InputLabel, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DataGridPro, GridToolbar, GridRowSelectionModel, GridCallbackDetails, GridRowId } from "@mui/x-data-grid-pro";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";
import { Organization, User } from "../constants/types";
import { apiFetch } from "../services/fetch";
import FacilitiesTable from "./FacilitiesTable";
import { getFacilities } from "../services/facilities";
import { usePermission } from "../hooks/usePermission";

export default function OrganizationsPanel() {
    const initValues = {
        Active: true,
        Name: "",
        Facilities: [],
        Users: [],
    };

    const [id, setId] = useState<GridRowId[]>();
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [initialValues, setInitialValues] = useState(initValues);
    const [create, setCreate] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const canEdit = usePermission("facilities:write")

    useEffect(() => {
        if (!id || id.length < 1) {
            return
        }

        const init = async () => {
            try {
                const response = await apiFetch(`/organizations/${id[0]}`)
                response.Facilities = response.Facilities.map((f: any) => f.ID)
                response.Users = response.Users.map((f: any) => f.ID)
                setInitialValues(response)
            } catch (e) {
                console.error(e)
                setError("Failed to load organization")
            }
        }

        init()
    }, [id]);

    useEffect(() => {
        const init = async () => {
            setLoading(true)
            try {
                const [orgs, users] = await Promise.all([apiFetch(`/organizations`), apiFetch('/users')])
                setOrganizations(orgs)
                setUsers(users)
            } catch (e) {
                setError("Failed to load organizations")
            } finally {
                setLoading(false)
            }
        }

        init()

    }, [refresh]);

    const handleSelected = (rowSelectionModel: GridRowSelectionModel, _: GridCallbackDetails<any>) => {
        setId(rowSelectionModel)
    }

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
    });

    const handleSubmit = async (values: any) => {
        console.log(values);
        let url = ""
        if (id && id.length > 0) {
            url = `/organizations`
        } else {
            url = `/organizations`
        }

        try {
            await apiFetch(url, 'POST', values)
            setError(null);
            setRefresh((prevValue) => !prevValue);
            setCreate(false);
        } catch (e) {
            console.log(e)
            setError("Failed to save organization.")
        }
    };

    const userColumns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "FirstName", headerName: "First Name", width: 120 },
        { field: "LastName", headerName: "Last Name", width: 120 },
        { field: "Email", headerName: "Email", width: 200 },
        { field: "Type", headerName: "Type", width: 120 },
    ]

    const myForm = () => {
        return (
            <>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            errors,
                            touched,
                        }) => <Form>
                                <Stack direction="column" spacing={2}>
                                    <InputLabel>General</InputLabel>
                                    <Stack
                                        direction={{ sm: "column" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <TextField
                                            name="Name"
                                            value={values.Name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Name &&
                                                Boolean(errors.Name)
                                            }
                                            label="Name"
                                            size="small"
                                            helperText={
                                                touched.Name && errors.Name
                                            }
                                            required
                                        />
                                        <Typography variant="h5">Facilities</Typography>
                                        <Box height="400px">
                                            <FacilitiesTable
                                                title=""
                                                refresh={null}
                                                getFacilities={getFacilities}
                                                checkboxes={true}
                                                selectedFacilities={
                                                    values.Facilities === undefined
                                                        ? []
                                                        : values.Facilities
                                                }
                                                setSelectedFacilities={(v: any) =>
                                                    setFieldValue('Facilities', v)
                                                }
                                            />
                                        </Box>
                                        <Typography variant="h5">Users</Typography>
                                        <Box height="400px">
                                            <DataGridPro
                                                hideFooterRowCount
                                                keepNonExistentRowsSelected
                                                getRowId={(row) => row.ID}
                                                checkboxSelection={true}
                                                loading={loading}
                                                density="compact"
                                                initialState={{
                                                    sorting: {
                                                        sortModel: [{ field: "FirstName", sort: "asc" }],
                                                    },
                                                }}
                                                sx={{
                                                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                                                    {
                                                        display: "none",
                                                    },
                                                }}
                                                components={{ Toolbar: GridToolbar }}
                                                componentsProps={{
                                                    toolbar: {
                                                        showQuickFilter: true,
                                                        quickFilterProps: { debounceMs: 500 },
                                                    },
                                                }}
                                                rowSelectionModel={values.Users}
                                                onRowSelectionModelChange={(v) => setFieldValue('Users', v)}
                                                rows={users}
                                                columns={userColumns}
                                            />
                                        </Box>
                                    </Stack>
                                    {error !== null ? (
                                        <Alert severity="error">{error}</Alert>
                                    ) : null}
                                    <Button type="submit">
                                        {id ? "Save" : "Create"}
                                    </Button>
                                </Stack>
                            </Form>}
                    </Formik>
                </Box>
            </>
        );
    };

    const columns = [
        { field: "Name", headerName: "Name", width: 175 },
        { field: "Facilities", headerName: "Facilities", width: 175 },
    ];

    if (create) {
        return myForm();
    }

    return <>
        <TableFrame>
            <DataTableContainer>
                <DataGridPro
                    getRowId={(row) => String(row.ID)}
                    density="compact"
                    sx={{
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                            display: "none",
                        },
                    }}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    keepNonExistentRowsSelected
                    onRowSelectionModelChange={handleSelected}
                    rowSelectionModel={id}
                    rows={organizations}
                    columns={columns}
                />
            </DataTableContainer>
            <ButtonTray>
                <Button
                    variant="contained"
                    color="success"
                    disabled={!canEdit}
                    onClick={() => {
                        setId(undefined);
                        setInitialValues(initValues);
                        setCreate(true);
                    }}
                >
                    Create
                </Button>
                <Button
                    disabled={!canEdit || !id}
                    variant="contained"
                    color="primary"
                    onClick={() => setCreate(true)}
                >
                    Edit
                </Button>
            </ButtonTray>
        </TableFrame>
    </>;
}
