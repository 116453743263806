import { GridColDef, useGridApiRef } from "@mui/x-data-grid-pro"
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro"
import React from "react"
import { useMemo } from "react"
import EntityPanelTable, { EntityPanelTableProps } from "../components/EntityPanelTable"
import { useCategories, useDepartments, useLaboratories, useReportTypes, useTests } from "../services/queries"
import { testTypes, testSubTypes } from "../services/util";

type TestPanelTableProps = Omit<EntityPanelTableProps, "columns" | "query">

export default function TestPanelTable(props: TestPanelTableProps) {
	const departments = useDepartments()
	const categories = useCategories()
	const reportTypes = useReportTypes()
	const laboratories = useLaboratories()


	const columns: GridColDef[] = useMemo(() => [
		{ field: "id", headerName: "ID", width: 45 },
		{ field: "code", headerName: "Code", width: 75 },
		{ field: "name", headerName: "Name", width: 150 },
		{ field: "description", headerName: "Description", width: 250 },
		// @ts-ignore
		{ field: "type", headerName: "Type", width: 150, valueGetter: (row) => testTypes[row.row.type] },
		// @ts-ignore
		{ field: "sub_type", headerName: "SubType", width: 150, valueGetter: (row) => testSubTypes[row.row.sub_type] },
		{ field: "loinc", headerName: "LOINC", width: 150 },
		{ field: "cpt_code", headerName: "CPT Code", width: 150 },
		{ field: "is_panel", headerName: "Panel", width: 100, type: "boolean" },
		{ field: "orderable", headerName: "Orderable", width: 100, type: "boolean" },
		{ field: "referenced", headerName: "Reference", width: 100, type: "boolean" },
		{ field: "internal", headerName: "Internal", width: 100, type: "boolean" },
		{ field: "control", headerName: "Control", width: 100, type: "boolean" },
		{ field: "department_id", headerName: "Department", width: 180, valueGetter: (params) => departments.data?.find((d) => d.id === params.value)?.name },
		{ field: "category_id", headerName: "Category", width: 180, valueGetter: (params) => categories.data?.find((d) => d.id === params.value)?.name },
		{ field: "max_tat", headerName: "Max TAT", width: 180 },
		{ field: "stability", headerName: "Stability", width: 180 },
		{ field: "report_type_id", headerName: "Report Type", width: 180, valueGetter: (params) => reportTypes.data?.find((d) => d.id === params.value)?.name },
		{ field: "laboratory_id", headerName: "Laboratory", width: 180, valueGetter: (params) => laboratories.data?.find((d) => d.id === params.value)?.name },
	], [departments.data, categories.data, reportTypes.data, laboratories.data])

	return (
		<EntityPanelTable columns={columns} query={useTests()} {...props} />

	)
}
