import { UseQueryResult } from "@tanstack/react-query";
import { DataGridPro, DataGridProProps, GridColDef, GridToolbar } from "@mui/x-data-grid-pro";
import { useField } from "formik";
import React from "react";
import { AxiosError } from "./AxiosError";

export type ReactQueryTableSelectorProps = Omit<DataGridProProps, "columns" | "rows"> & {
    name: string;
    query: UseQueryResult<any>
    columns: GridColDef[]
}

function ReactQueryTableSelector({ columns, name, query, ...props }: ReactQueryTableSelectorProps) {
    const [field, _, helper] = useField(name);

    return (
        query.isError ? (<AxiosError error={query.error} />)
            : (
                <DataGridPro
                    loading={query.isLoading}
                    density="compact"
                    getRowId={(row) => row.id}
                    rows={query.data || []}
                    columns={columns}
                    onRowSelectionModelChange={(v) => helper.setValue(v)}
                    rowSelectionModel={field.value ?? []}
                    checkboxSelection={true}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{

                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    {...props}
                />
            )
    )
}

export default ReactQueryTableSelector;
