import { Stack } from "@mui/system";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Box from "@mui/system/Box";
import TextField from "@mui/material/TextField";
import { Alert, Button, InputLabel } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DataGridPro, GridToolbar, GridRowSelectionModel, GridCallbackDetails, GridRowId } from "@mui/x-data-grid-pro";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";
import { Category } from "../constants/types";
import { apiFetch } from "../services/fetch";
import { usePermission } from "../hooks/usePermission";

export default function CategoriesPanel() {
    const initValues = {
        Code: "",
        Name: "",
    };

    const [id, setId] = useState<GridRowId[]>();
    const [categories, setCategories] = useState<Category[]>([]);
    const [initialValues, setInitialValues] = useState(initValues);
    const [create, setCreate] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const canEdit = usePermission("setup:write")

    useEffect(() => {
        if (!id || id.length < 1) {
            return
        }

        const init = async () => {
            try {
                const response = await apiFetch(`/categories/${id[0]}`)
                setInitialValues(response)
            } catch (e) {
                setError("Failed to load category")
            }
        }

        init()
    }, [id]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await apiFetch(`/categories`)
                setCategories(response)
            } catch (e) {
                setError("Failed to load categories")
            }
        }

        init()

    }, [refresh]);

    const handleSelected = (rowSelectionModel: GridRowSelectionModel, _: GridCallbackDetails<any>) => {
        setId(rowSelectionModel)
    }

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
        Code: Yup.string().required("Code is required"),
    });

    const handleSubmit = async (values: any) => {
        console.log(values);
        let url = ""
        if (id && id.length > 0) {
            url = `/categories/${id[0]}`
        } else {
            url = `/categories`
        }

        try {
            await apiFetch(url, 'POST', values)
            setError(null);
            setRefresh((prevValue) => !prevValue);
            setCreate(false);
        } catch (e) {
            console.log(e)
            setError("Failed to save category.")
        }
    };

    const myForm = () => {
        return (
            <>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            errors,
                            touched,
                        }) => <Form>
                                <Stack direction="column" spacing={2}>
                                    <InputLabel>General</InputLabel>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <TextField
                                            name="Code"
                                            value={values.Code}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Code &&
                                                Boolean(errors.Code)
                                            }
                                            label="Code"
                                            size="small"
                                            helperText={
                                                touched.Code && errors.Code
                                            }
                                            required
                                        />
                                        <TextField
                                            name="Name"
                                            value={values.Name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Name &&
                                                Boolean(errors.Name)
                                            }
                                            label="Name"
                                            size="small"
                                            helperText={
                                                touched.Name && errors.Name
                                            }
                                            required
                                        />
                                    </Stack>
                                    {error !== null ? (
                                        <Alert severity="error">{error}</Alert>
                                    ) : null}
                                    <Button type="submit">
                                        {id ? "Save" : "Create"}
                                    </Button>
                                </Stack>
                            </Form>}
                    </Formik>
                </Box>
            </>
        );
    };

    const columns = [
        { field: "Code", headerName: "Code", width: 175 },
        { field: "Name", headerName: "Name", width: 175 },
    ];

    if (create) {
        return myForm();
    }

    return <>
        <TableFrame>
            <DataTableContainer>
                <DataGridPro
                    getRowId={(row) => String(row.ID)}
                    density="compact"
                    sx={{
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                            display: "none",
                        },
                    }}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    keepNonExistentRowsSelected
                    onRowSelectionModelChange={handleSelected}
                    rowSelectionModel={id}
                    rows={categories}
                    columns={columns}
                />
            </DataTableContainer>
            <ButtonTray>
                <Button
                    variant="contained"
                    disabled={!canEdit}
                    color="success"
                    onClick={() => {
                        setId(undefined);
                        setInitialValues(initValues);
                        setCreate(true);
                    }}
                >
                    Create
                </Button>
                <Button
                    disabled={!canEdit || !id}
                    variant="contained"
                    color="primary"
                    onClick={() => setCreate(true)}
                >
                    Edit
                </Button>
            </ButtonTray>
        </TableFrame>
    </>;
}
