import { Stack } from "@mui/system";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Box from "@mui/system/Box";
import TextField from "@mui/material/TextField";
import { Button, InputLabel } from "@mui/material";
import { useState, useEffect } from "react";
import {
    getSpecimenTypes,
    getSpecimenType,
    addSpecimenType,
    updateSpecimenType,
} from "../services/specimentypes";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";
import ErrorAlert from "./ErrorAlert";
import TestsTable from "./TestsTable";
import { usePermission } from "../hooks/usePermission";

export default function SpecimenTypePanel() {
    const initValues = {
        Prefix: "",
        Name: "",
        QTY: 1,
        Directions: "",
        Specimen: "",
        Container: "",
        Tests: [],
    };

    const [id, setId] = useState();
    const [specimenTypes, setSpecimenTypes] = useState([]);
    const [initialValues, setInitialValues] = useState(initValues);
    const [create, setCreate] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState(null);
    const canEdit = usePermission("setup:write")

    useEffect(() => {
        if (id) {
            getSpecimenType(id)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to load specimenType");
                    }
                    return p.json();
                })
                .then((p) => {
                    setInitialValues(p);
                });
        }
    }, [id]);

    useEffect(() => {
        getSpecimenTypes()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load specimenTypes");
                }
                return p.json();
            })
            .then((p) => setSpecimenTypes(p));
    }, [refresh]);

    const validationSchema = Yup.object().shape({
        Prefix: Yup.string().max(3, "Prefix must be 3 characters or less"),
        Specimen: Yup.string().required("Specimen is required"),
        Container: Yup.string().required("Container is required"),
        Directions: Yup.string().required("Directions are required"),
        QTY: Yup.number().min(1).required("Quantity is required"),
        Tests: Yup.array().of(Yup.number()),
    });

    const CustomTextField = ({
        values,
        name,
        handleChange,
        label,
        onBlur,
        errors,
        touched,
        required,
        ...props
    }) => (
        <TextField
            required={required}
            name={name}
            label={label || name}
            helperText={touched?.[name] && errors?.[name]}
            error={touched?.[name] && Boolean(errors?.[name])}
            size="small"
            onBlur={onBlur}
            onChange={handleChange}
            value={values?.[name]}
            {...props}
        />
    );

    const handleSubmit = (values) => {
        console.log(values);

        let fn = () => addSpecimenType(values);
        if (id) {
            fn = () => updateSpecimenType(id, values);
        }

        fn()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to update specimen types");
                }
                setError(null);
                setRefresh((prevValue) => !prevValue);
                setCreate(false);
                return p.json();
            })
            .catch((e) => setError(e.message));
    };

    const myForm = () => {
        return (
            <>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({
                            values,
                            setFieldValue,
                            handleChange,
                            handleBlur,
                            errors,
                            touched,
                        }) => (
                            <Form>
                                <Stack direction="column" spacing={2}>
                                    <InputLabel>General</InputLabel>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <CustomTextField
                                            values={values}
                                            onBlur={handleBlur}
                                            name="Prefix"
                                            inputProps={{
                                                maxLength: 3,
                                            }}
                                            handleChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                        <CustomTextField
                                            values={values}
                                            onBlur={handleBlur}
                                            required={true}
                                            name="Specimen"
                                            handleChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                        <CustomTextField
                                            values={values}
                                            onBlur={handleBlur}
                                            required={true}
                                            name="Container"
                                            handleChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                        />
                                        <CustomTextField
                                            values={values}
                                            onBlur={handleBlur}
                                            required={true}
                                            name="QTY"
                                            label="Quantity"
                                            sx={{ maxWidth: "120px" }}
                                            handleChange={handleChange}
                                            touched={touched}
                                            errors={errors}
                                            inputProps={{ type: "number", min: 1 }}
                                        />
                                    </Stack>
                                    <CustomTextField
                                        multiline
                                        rows={4}
                                        values={values}
                                        onBlur={handleBlur}
                                        required={true}
                                        name="Directions"
                                        handleChange={handleChange}
                                        touched={touched}
                                        errors={errors}
                                    />
                                    <InputLabel>Tests</InputLabel>
                                    <Box height="600px">
                                        <TestsTable
                                            checkboxes={true}
                                            test={values.Tests}
                                            setTest={(v) => { setFieldValue("Tests", v) }}
                                        />
                                    </Box>

                                    <ErrorAlert error={error} />
                                    <Button type="submit">
                                        {id ? "Save" : "Create"}
                                    </Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Box>
            </>
        );
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 55 },
        { field: "Prefix", headerName: "Prefix", width: 55 },
        { field: "Specimen", headerName: "Specimen", width: 175 },
        { field: "Container", headerName: "Container", width: 175 },
        { field: "QTY", headerName: "Quantity", width: 70 },
        { field: "Directions", headerName: "Directions", width: 500 },
    ];

    if (create) {
        return myForm();
    }

    return <>
        <TableFrame>
            <DataTableContainer>
                <DataGridPro
                    getRowId={(row) => row.ID}
                    density="compact"
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "Specimen", sort: "asc" }],
                        },
                    }}
                    sx={{
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                            display: "none",
                        },
                    }}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    keepNonExistentRowsSelected
                    onRowSelectionModelChange={setId}
                    rowSelectionModel={id}
                    autoHeight={false}
                    rows={specimenTypes}
                    columns={columns}
                />
            </DataTableContainer>
            <ButtonTray>
                <Button
                    variant="contained"
                    disabled={!canEdit}
                    color="success"
                    onClick={() => {
                        setId(undefined);
                        setInitialValues(initValues);
                        setCreate(true);
                    }}
                >
                    Create
                </Button>
                <Button
                    disabled={!canEdit || !id}
                    variant="contained"
                    color="primary"
                    onClick={() => setCreate(true)}
                >
                    Edit
                </Button>
            </ButtonTray>
        </TableFrame>
    </>;
}
