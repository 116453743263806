import React from "react";
import { useTests } from "../../services/queries";
import ReactQueryAutocomplete, { ReactQueryAutocompleteProps } from "../ReactQueryAutocomplete";

type TestAutoCompleteProps = Omit<ReactQueryAutocompleteProps, "query">;

export default function TestAutoComplete(props: TestAutoCompleteProps) {
    return (
        <ReactQueryAutocomplete {...{ ...props, query: useTests }} />
    )
}
