import React from "react";
import { useCategories } from "../../services/queries";
import ReactQueryAutocomplete, { ReactQueryAutocompleteProps } from "../ReactQueryAutocomplete";

type CategoryAutoCompleteProps = Omit<ReactQueryAutocompleteProps, "query">;

export default function CategoryAutoComplete(props: CategoryAutoCompleteProps) {
    return (
        <ReactQueryAutocomplete {...{ ...props, query: useCategories }} />
    )
}
