import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { useField } from "formik";
import React from "react";
import { useSpecimenTypes } from "../../services/queries";
import { AxiosError } from "../AxiosError";

const columns = [
    { field: "id", headerName: "ID", width: 45 },
    { field: "prefix", headerName: "Prefix", width: 225 },
    { field: "specimen", headerName: "Specimen", width: 225 },
    { field: "container", headerName: "Container", width: 225 },
];

type SpecimenTypeTableSelectorProps = {
    name: string;
}

function SpecimenTypeTableSelector({ name }: SpecimenTypeTableSelectorProps) {
    const [field, _, helper] = useField(name);
    const specimenTypes = useSpecimenTypes();

    return (
        specimenTypes.isError ? (<AxiosError error={specimenTypes.error} />)
            : (
                <DataGridPro
                    loading={specimenTypes.isLoading}
                    density="compact"
                    rows={specimenTypes.data || []}
                    columns={columns}
                    onRowSelectionModelChange={(v) => helper.setValue(v)}
                    rowSelectionModel={field.value || []}
                    checkboxSelection={true}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{

                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            )
    )
}

export default SpecimenTypeTableSelector;
