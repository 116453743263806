
import { Button, Table, TableBody, TableCell, TableContainer, TableRow, useMediaQuery } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { Order } from '../../constants/go_types';
import { SessionContext } from '../../hooks/getContext';
import { usePermission } from '../../hooks/usePermission';
import { useCompleteOrder, useRejectOrder, useUser } from '../../services/queries';
import { FormatDateTime, formatFee, paymentMethodIndex } from '../../services/util';
import { AxiosError } from '../AxiosError';
import ButtonList from '../ButtonList';
import ChangePaymentMethodDialog from '../ChangePaymentMethodDialog';
import { RowStack } from '../Stacks';
import { GetOrderSTATChip, GetOrderStatusChip } from '../StatusChips';
import StyledPaper from '../StyledPaper';
import mdTheme from '../Theme';
import Title from '../Title';

export default function OrderMetadataV2({ order }: { order: Order }) {
    const addedBy = useUser(order.added_by_id)
    const canEdit = usePermission("orders:write")
    const session = useContext(SessionContext);
    const isLabUser = session.user.Type === "Lab"
    const completeOrder = useCompleteOrder(order.id)
    const rejectOrder = useRejectOrder(order.id)
    const [openPayment, setOpenPayment] = useState(false);
    const queryClient = useQueryClient()

    console.log(openPayment, order.id)

    const isMobile = useMediaQuery(mdTheme.breakpoints.down("lg"));

    const data = [
        {
            key: 'ID',
            value: order.id
        },
        {
            key: 'Status',
            value: <RowStack>
                <GetOrderStatusChip status={order.status as string} />
                {order.stat && <GetOrderSTATChip size="small" />}
            </RowStack>
        },
        {
            key: 'Submitted At',
            value: FormatDateTime(order.created_at)
        },
        {
            key: 'Submitted By',
            value: addedBy.data?.email
        },
        {
            key: 'Updated At',
            value: FormatDateTime(order.updated_at)
        },
        {
            key: 'Payment Method',
            // @ts-ignore
            value: <Button size="small" variant="contained" disabled={!canEdit} onClick={() => setOpenPayment(true)}>{paymentMethodIndex[order.payment_method]}</Button>,
        },
        {
            key: 'Price',
            value: formatFee.format(order?.price ? order.price / 100 : 0),
        },
    ]

    return (
        <>
            <ChangePaymentMethodDialog
                open={openPayment}
                setOpen={setOpenPayment}
                orderId={order.id}
                callback={() => queryClient.invalidateQueries({ queryKey: ['orders', order.id] })}
            />
            <StyledPaper>
                <RowStack alignItems={isMobile ? "center" : "center"} justifyContent="space-between">
                    <Title>Order</Title>
                    {isLabUser ?
                        <ButtonList
                            withHeaderBtn
                            headerBtn="Actions"
                            disabled={!canEdit || completeOrder.isPending || rejectOrder.isPending}
                            options={[
                                { Name: "Complete", Action: () => completeOrder.mutate(order.id!) },
                                { Name: "Reject", Action: () => rejectOrder.mutate(order.id!) },
                            ]} /> : <></>}
                </RowStack>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {data.map((row) => (
                                <TableRow key={row.key}>
                                    <TableCell
                                        component="th" // Semantically a header
                                        scope="row"
                                        sx={{
                                            fontWeight: 'bold', // Make it look like a header
                                            width: '35%', // Control column width
                                            // bgcolor: '#f5f5f5', // Light background for contrast
                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        }}
                                    >
                                        {row.key}
                                    </TableCell>
                                    <TableCell>
                                        {row.value}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {completeOrder.isError && <AxiosError error={completeOrder.error} />}
                {rejectOrder.isError && <AxiosError error={rejectOrder.error} />}
            </StyledPaper>
        </>
    )

}
