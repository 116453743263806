import { ModeEdit } from '@mui/icons-material';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useMediaQuery } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import ModalImage from 'react-modal-image';
import { Order } from '../../constants/go_types';
import { useDeleteOrderSignature, useFaciliy, useOrderSign, useOrderSignature, useOrderSignatureEligibility, useProvider } from '../../services/queries';
import { formatAddress, FormatDateTime } from '../../services/util';
import Loading from '../Loading';
import { ColumnStack, RowStack } from '../Stacks';
import StyledPaper from '../StyledPaper';
import mdTheme from '../Theme';
import Title from '../Title';

export default function OrderFacilityV2({ order }: { order: Order }) {
    const queryClient = useQueryClient()
    const { data: facility, isLoading: facilityIsLoading } = useFaciliy(order.facility_id)
    const { data: provider, isLoading: providerIsLoading } = useProvider(order.provider_id)
    const canSign = useOrderSignatureEligibility(order.id, order.provider_id)
    const getSignature = useOrderSignature(order.id, order.provider_signature_id)
    const signOrder = useOrderSign(order.id)
    const deleteSignature = useDeleteOrderSignature(order.id)

    const showSignatureDelete = canSign.data?.allowed && order.provider_signature_id
    const showSignatureSign = canSign.data?.allowed && !order.provider_signature_id
    const showSignature = getSignature.isSuccess && getSignature.data

    const isMobile = useMediaQuery(mdTheme.breakpoints.down("lg"));

    const handleViewSignature = () => {
        getSignature.refetch()
    }

    const handleDeleteSignature = () => {
        deleteSignature.mutate()
    }

    const handleSignSignature = () => {
        signOrder.mutate()
    }

    console.log('getSignature', getSignature)
    const handleHideSignature = () => {
        queryClient.setQueryData(['orders-signature', order.provider_signature_id], null)
    }

    const facilityData: any = [
        {
            key: 'ID',
            value: facility?.id
        },
        {
            key: 'Name',
            value: facility?.name
        },
        {
            key: 'Address',
            value: formatAddress(facility?.address_1, facility?.address_2, facility?.city, facility?.state, facility?.zip_code)
        },
        {
            key: 'Phone',
            value: facility?.phone
        },
        {
            key: 'Provider Name',
            value: provider?.last_name + ", " + provider?.first_name
        },
        {
            key: 'Provider NPI',
            value: facility?.npi
        },
    ]

    if (facilityIsLoading || providerIsLoading) {
        return (
            <StyledPaper>
                <Loading />
            </StyledPaper>
        )
    }


    if (order.provider_signature_id) {
        facilityData.push(...[{
            key: 'Signed At',
            value: <RowStack justifyContent={"space-between"} alignItems="center">
                {FormatDateTime(order.signed_at)}
                {showSignature ? <Button onClick={handleHideSignature} size="small" variant="contained">Hide</Button> : <Button onClick={handleViewSignature} size="small" variant="contained" color="success">View</Button>}
            </RowStack>
            ,
        },
        ])
    }

    return (
        <StyledPaper>
            <RowStack alignItems={isMobile ? "center" : "center"} justifyContent="space-between">
                <Title>Facility</Title>
            </RowStack>
            <ColumnStack>
                <TableContainer>
                    <Table size="small">
                        <TableBody>
                            {facilityData.map((row: any) => (
                                <TableRow key={row.key}>
                                    <TableCell
                                        component="th" // Semantically a header
                                        scope="row"
                                        sx={{
                                            fontWeight: 'bold', // Make it look like a header
                                            width: '35%', // Control column width
                                            // bgcolor: '#f5f5f5', // Light background for contrast
                                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                        }}
                                    >
                                        {row.key}
                                    </TableCell>
                                    <TableCell>
                                        {row.value}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {showSignature &&
                    <Box justifySelf="center" alignSelf="center" sx={{ display: 'flex' }}>
                        <ColumnStack>
                            <ModalImage small={getSignature.data.signature_img_string!} />
                            <Typography variant="body1">Signed By: {getSignature.data?.signature_name}</Typography>
                        </ColumnStack>
                    </Box>
                }
                {showSignatureDelete &&
                    <RowStack justifyContent="flex-end">
                        <Button
                            variant="outlined"
                            color="error"
                            startIcon={<ModeEdit />}
                            onClick={handleDeleteSignature}
                        >
                            Delete Signature
                        </Button>
                    </RowStack>
                }
                {showSignatureSign &&
                    <RowStack justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<ModeEdit />}
                            onClick={handleSignSignature}
                        >
                            Sign Order
                        </Button>
                    </RowStack>
                }
            </ColumnStack>
        </StyledPaper>


    )
}
