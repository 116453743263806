import React from "react";
import { useReportTypes } from "../../services/queries";
import ReactQueryAutocomplete, { ReactQueryAutocompleteProps } from "../ReactQueryAutocomplete";

type ReportTypeAutoCompleteProps = Omit<ReactQueryAutocompleteProps, "query">;

export default function ReportTypeAutoComplete(props: ReportTypeAutoCompleteProps) {
    return (
        <ReactQueryAutocomplete {...{ ...props, query: useReportTypes }} />
    )
}
