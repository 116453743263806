import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { OrderDiagnosisCodeRequest } from '../constants/go_types'
import { createPatient, deleteOrderSignature, deletePatient, getOrderDiagnosisCodes, getOrderSignature, getOrderSignatureEligibility, getOrderSignDepracted, getPatient, getPatients, signOrder, updateOrderDiagnosisCodes, updateOrderReject, updatePatient } from './api'
import { updateOrderComplete } from './api'
import {
	createCollector,
	createContact,
	createFacility,
	createIntegrationFacilityMapping,
	createIntegrationInsuranceMapping,
	createIntegrationTestCodeMapping,
	createReportGroup,
	createSalesRep,
	createTest,
	deleteContact,
	deleteFacility,
	deleteIntegrationFacilityMapping,
	deleteIntegrationInsuranceMapping,
	deleteIntegrationTestCodeMapping,
	deleteReportGroup,
	deleteTest,
	getCollector,
	getCollectors,
	getContact,
	getContacts,
	getFacility,
	getFacilities,
	getFacilitiesDeprecated,
	getIntegrationFacilityMapping,
	getIntegrationFacilityMappings,
	getIntegrationInstancesDepracted,
	getIntegrationInsuranceMapping,
	getIntegrationInsuranceMappings,
	getIntegrationTestCodeMapping,
	getIntegrationTestCodeMappings,
	getProvidersDeprecated,
	getReportGroup,
	getReportGroups,
	getSalesRep,
	getSalesReps,
	getTest,
	getTests,
	getTestsDeprecated,
	updateCollector,
	updateContact,
	updateIntegrationInsuranceMapping,
	updateIntegrationTestCodeMapping,
	updateReportGroup,
	updateSalesRep,
	updateTest,
	updateFacility,
	updateIntegrationFacilityMapping,
	getInsurances,
	getInsurance,
	deleteInsurance,
	createInsurance,
	updateInsurance,
	getReflexes,
	getReflex,
	deleteReflex,
	createReflex,
	updateReflex,
	getTestSubTests,
	getOrderNotes,
	deleteOrderNote,
	createOrderNote,
	updateOrderNote,
	getUsers,
	getUser,
	deleteUser,
	createUser,
	updateUser,
	getRoles,
	getRole,
	deleteRole,
	createRole,
	updateRole,
	getPermissions,
	updateLaboratory,
	createLaboratory,
	deleteLaboratory,
	getLaboratory,
	getLaboratories,
	getProviders,
	getProvider,
	deleteProvider,
	createProvider,
	updateProvider,
	getOrganizations,
	getOrganization,
	deleteOrganization,
	createOrganization,
	updateOrganization,
	sendUserActivationEmail,
	getUsersTableView,
	deactivateUser,
	activateUser,
	getCategories,
	getCategory,
	deleteCategory,
	createCategory,
	updateCategory,
	getDepartments,
	getDepartment,
	deleteDepartment,
	createDepartment,
	updateDepartment,
	getDrugClasses,
	getDrugClass,
	deleteDrugClass,
	createDrugClass,
	updateDrugClass,
	getReportTypes,
	getReportType,
	deleteReportType,
	createReportType,
	updateReportType,
	getSpecimenTypes,
	getSpecimenType,
	deleteSpecimenType,
	createSpecimenType,
	updateSpecimenType,
	getOrder,
} from './api'


export function useTestsDeprecated() {
	return useQuery({
		queryKey: ['tests_deprecated'],
		queryFn: () => getTestsDeprecated()
	})
}

export function useFacilitiesDeprecated() {
	return useQuery({
		queryKey: ['facilities_deprecated'],
		queryFn: () => getFacilitiesDeprecated()
	})
}

export function useProvidersDeprecated() {
	return useQuery({
		queryKey: ['providers_deprecated'],
		queryFn: () => getProvidersDeprecated()
	})
}

export function useIntegrationInstancesDeprecated() {
	return useQuery({
		queryKey: ['integration_instance_deprecated'],
		queryFn: () => getIntegrationInstancesDepracted()
	})
}

export function useOrderSignDeprecated(id?: number) {
	return useQuery({
		queryKey: ['orders_sign', id],
		queryFn: () => getOrderSignDepracted(id!),
		enabled: !!id
	})
}


export function useCategories() {
	return useQuery({
		queryKey: ['categories'],
		queryFn: () => getCategories()
	})
}

export function useCategory(id?: number) {
	return useQuery({
		queryKey: ['categories', id],
		queryFn: () => getCategory(id!),
		enabled: !!id,
	})
}

export function useDeleteCategory() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteCategory,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['categories'] })
		}
	})
}

export function useCreateCategory() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createCategory,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['categories'] })
		}
	})
}

export function useUpdateCategory() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateCategory,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['categories'] })
		}
	})
}

export function useCollectors() {
	return useQuery({
		queryKey: ['collectors'],
		queryFn: () => getCollectors()
	})
}

export function useCollector(id?: number) {
	return useQuery({
		queryKey: ['collector', id],
		queryFn: () => getCollector(id!),
		enabled: !!id,
	})
}


export function useCreateCollector() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createCollector,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['collectors'] })
		}
	})
}

export function useUpdateCollector() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateCollector,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['collectors'] })
		}
	})
}

export function useContacts() {
	return useQuery({
		queryKey: ['contacts'],
		queryFn: () => getContacts()
	})
}

export function useContact(id?: number) {
	return useQuery({
		queryKey: ['contact', id],
		queryFn: () => getContact(id!),
		enabled: !!id,
	})
}

export function useDeleteContact() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteContact,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['contacts'] })
		}
	})
}

export function useCreateContact() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createContact,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['contacts'] })
		}
	})
}

export function useUpdateContact() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateContact,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['contacts'] })
		}
	})
}

export function useDepartments() {
	return useQuery({
		queryKey: ['departments'],
		queryFn: () => getDepartments()
	})
}

export function useDepartment(id?: number) {
	return useQuery({
		queryKey: ['departments', id],
		queryFn: () => getDepartment(id!),
		enabled: !!id,
	})
}

export function useDeleteDepartment() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteDepartment,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['departments'] })
		}
	})
}

export function useCreateDepartment() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createDepartment,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['departments'] })
		}
	})
}

export function useUpdateDepartment() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateDepartment,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['departments'] })
		}
	})
}

export function useDrugClasses() {
	return useQuery({
		queryKey: ['drugClasses'],
		queryFn: () => getDrugClasses()
	})
}

export function useDrugClass(id?: number) {
	return useQuery({
		queryKey: ['drugClasses', id],
		queryFn: () => getDrugClass(id!),
		enabled: !!id,
	})
}

export function useDeleteDrugClass() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteDrugClass,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['drugClasses'] })
		}
	})
}

export function useCreateDrugClass() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createDrugClass,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['drugClasses'] })
		}
	})
}

export function useUpdateDrugClass() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateDrugClass,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['drugClasses'] })
		}
	})
}

export function useFacilities() {
	return useQuery({
		queryKey: ['facilities'],
		queryFn: () => getFacilities()
	})
}

export function useFaciliy(id?: number) {
	return useQuery({
		queryKey: ['facilities', id],
		queryFn: () => getFacility(id!),
		enabled: !!id,
	})
}

export function useDeleteFaciliy() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteFacility,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['facilities'] })
		}
	})
}

export function useCreateFaciliy() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createFacility,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['facilities'] })
		}
	})
}

export function useUpdateFaciliy() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateFacility,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['facilities'] })
		}
	})
}

export function useInsurances() {
	return useQuery({
		queryKey: ['insurances'],
		queryFn: () => getInsurances()
	})
}

export function useInsurance(id?: number) {
	return useQuery({
		queryKey: ['insurances', id],
		queryFn: () => getInsurance(id!),
		enabled: !!id,
	})
}

export function useDeleteInsurance() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteInsurance,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['insurances'] })
		}
	})
}

export function useCreateInsurance() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createInsurance,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['insurances'] })
		}
	})
}

export function useUpdateInsurance() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateInsurance,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['insurances'] })
		}
	})
}

export function useIntegrationFacilityMappings() {
	return useQuery({
		queryKey: ['IntegrationFacilityMappings'],
		queryFn: () => getIntegrationFacilityMappings()
	})
}

export function useIntegrationFacilityMapping(id?: number) {
	return useQuery({
		queryKey: ['IntegrationFacilityMappings', id],
		queryFn: () => getIntegrationFacilityMapping(id!),
		enabled: !!id,
	})
}

export function useDeleteIntegrationFacilityMapping() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteIntegrationFacilityMapping,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['IntegrationFacilityMappings'] })
		}
	})
}

export function useCreateIntegrationFacilityMapping() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createIntegrationFacilityMapping,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['IntegrationFacilityMappings'] })
		}
	})
}

export function useUpdateIntegrationFacilityMapping() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateIntegrationFacilityMapping,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['IntegrationFacilityMappings'] })
		}
	})
}

export function useIntegrationInsuranceMappings() {
	return useQuery({
		queryKey: ['IntegrationInsuranceMappings'],
		queryFn: () => getIntegrationInsuranceMappings()
	})
}

export function useIntegrationInsuranceMapping(id?: number) {
	return useQuery({
		queryKey: ['IntegrationInsuranceMappings', id],
		queryFn: () => getIntegrationInsuranceMapping(id!),
		enabled: !!id,
	})
}

export function useDeleteIntegrationInsuranceMapping() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteIntegrationInsuranceMapping,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['IntegrationInsuranceMappings'] })
		}
	})
}

export function useCreateIntegrationInsuranceMapping() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createIntegrationInsuranceMapping,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['IntegrationInsuranceMappings'] })
		}
	})
}

export function useUpdateIntegrationInsuranceMapping() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateIntegrationInsuranceMapping,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['IntegrationInsuranceMappings'] })
		}
	})
}

export function useIntegrationTestCodeMappings() {
	return useQuery({
		queryKey: ['integrationTestCodeMappings'],
		queryFn: () => getIntegrationTestCodeMappings()
	})
}

export function useIntegrationTestCodeMapping(id?: number) {
	return useQuery({
		queryKey: ['integrationTestCodeMappings', id],
		queryFn: () => getIntegrationTestCodeMapping(id!),
		enabled: !!id,
	})
}

export function useDeleteIntegrationTestCodeMapping() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteIntegrationTestCodeMapping,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['integrationTestCodeMappings'] })
		}
	})
}

export function useCreateIntegrationTestCodeMapping() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createIntegrationTestCodeMapping,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['integrationTestCodeMappings'] })
		}
	})
}

export function useUpdateIntegrationTestCodeMapping() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateIntegrationTestCodeMapping,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['integrationTestCodeMappings'] })
		}
	})
}

export function useLaboratories() {
	return useQuery({
		queryKey: ['laboratories'],
		queryFn: () => getLaboratories()
	})
}

export function useLaboratory(id?: number) {
	return useQuery({
		queryKey: ['laboratories', id],
		queryFn: () => getLaboratory(id!),
		enabled: !!id,
	})
}

export function useDeleteLaboratory() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteLaboratory,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['laboratories'] })
		}
	})
}

export function useCreateLaboratory() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createLaboratory,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['laboratories'] })
		}
	})
}

export function useUpdateLaboratory() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateLaboratory,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['laboratories'] })
		}
	})
}

export function useOrganizations() {
	return useQuery({
		queryKey: ['organizations'],
		queryFn: () => getOrganizations()
	})
}

export function useOrganization(id?: number) {
	return useQuery({
		queryKey: ['organizations', id],
		queryFn: () => getOrganization(id!),
		enabled: !!id,
	})
}

export function useDeleteOrganization() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteOrganization,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['organizations'] })
		}
	})
}

export function useCreateOrganization() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createOrganization,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['organizations'] })
		}
	})
}

export function useUpdateOrganization() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateOrganization,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['organizations'] })
		}
	})
}

export function usePatients() {
	return useQuery({
		queryKey: ['patients'],
		queryFn: () => getPatients()
	})
}

export function usePatient(id?: number) {
	return useQuery({
		queryKey: ['patients', id],
		queryFn: () => getPatient(id!),
		enabled: !!id,
	})
}

export function useDeletePatient() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deletePatient,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['patients'] })
		}
	})
}

export function useCreatePatient() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createPatient,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['patients'] })
		}
	})
}

export function useUpdatePatient() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updatePatient,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['patients'] })
		}
	})
}

export function usePermissions(isLabOnly: boolean) {
	return useQuery({
		queryKey: ['permissions', isLabOnly],
		queryFn: () => getPermissions(isLabOnly)
	})
}

export function useProviders() {
	return useQuery({
		queryKey: ['providers'],
		queryFn: () => getProviders()
	})
}

export function useProvider(id?: number) {
	return useQuery({
		queryKey: ['providers', id],
		queryFn: () => getProvider(id!),
		enabled: !!id,
	})
}

export function useDeleteProvider() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteProvider,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['providers'] })
		}
	})
}

export function useCreateProvider() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createProvider,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['providers'] })
		}
	})
}

export function useUpdateProvider() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateProvider,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['providers'] })
		}
	})
}

export function useOrder(id?: number | string) {
	const orderIdNum = id ? Number(id) : undefined;

	return useQuery({
		queryKey: ['orders', id],
		queryFn: () => getOrder(orderIdNum!),
		staleTime: 0,
		enabled: !!orderIdNum
	})
}

export function useOrderDiagnosisCodes(id?: number | string) {
	const orderIdNum = id ? Number(id) : undefined;

	return useQuery({
		select: (data) => {
			return data.sort((a, b) => a.order_number! - b.order_number!);
		},
		queryKey: ['order-diagnosis-codes', id],
		queryFn: () => getOrderDiagnosisCodes(orderIdNum!),
		staleTime: 0,
		enabled: !!orderIdNum
	})
}

export function useUpdateOrderDiagnosisCodes(id?: number | string) {
	const orderIdNum = id ? Number(id) : undefined;
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: (d: OrderDiagnosisCodeRequest) => updateOrderDiagnosisCodes(orderIdNum!, d),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['order-diagnosis-codes', id] })
		}
	})
}

export function useOrderSignature(orderId?: number, providerSignatureId?: number) {
	return useQuery({
		queryKey: ['orders-signature', providerSignatureId],
		queryFn: () => getOrderSignature(orderId!, providerSignatureId!),
		staleTime: 0,
		enabled: false, // we only want manual trigger

	})
}

export function useOrderSign(orderId?: number) {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: () => signOrder(orderId!),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['orders', orderId] })
		},
	})
}


export function useDeleteOrderSignature(orderId?: number) {
	const queryClient = useQueryClient()

	return useMutation({
		mutationFn: () => deleteOrderSignature(orderId!),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['orders', orderId] })
		},
	})
}

export function useOrderSignatureEligibility(orderId?: number, providerId?: number) {

	return useQuery({
		queryKey: ['orders-signature-eligibility', orderId, providerId],
		queryFn: () => getOrderSignatureEligibility(orderId!, providerId!),
		staleTime: 0,
		enabled: !!orderId && !!providerId
	})
}

export function useCompleteOrder(id?: number) {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateOrderComplete,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['orders', id] })
		}
	})
}

export function useRejectOrder(id?: number) {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateOrderReject,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['orders', id] })
		}
	})
}


export function useOrderNotes(orderId?: number) {
	return useQuery({
		queryKey: ['orderNotes', orderId],
		queryFn: () => getOrderNotes(orderId!),
		enabled: !!orderId,
	})
}

export function useDeleteOrderNote(orderId?: number) {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteOrderNote,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['orderNotes', orderId] })
		}
	})
}

export function useCreateOrderNote(orderId?: number) {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createOrderNote,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['orderNotes', orderId] })
		}
	})
}

export function useUpdateOrderNote(orderId?: number) {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateOrderNote,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['orderNotes', orderId] })
		}
	})
}

export function useReflexes() {
	return useQuery({
		queryKey: ['reflexes'],
		queryFn: () => getReflexes()
	})
}

export function useReflex(id?: number) {
	return useQuery({
		queryKey: ['reflexes', id],
		queryFn: () => getReflex(id!),
		enabled: !!id,
	})
}

export function useDeleteReflex() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteReflex,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['reflexes'] })
		}
	})
}

export function useCreateReflex() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createReflex,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['reflexes'] })
		}
	})
}

export function useUpdateReflex() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateReflex,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['reflexes'] })
		}
	})
}

export function useReportGroups() {
	return useQuery({
		queryKey: ['reportGroups'],
		queryFn: () => getReportGroups()
	})
}

export function useReportGroup(id?: number) {
	return useQuery({
		queryKey: ['reportGroup', id],
		queryFn: () => getReportGroup(id!),
		enabled: !!id
	})
}


export function useCreateReportGroup() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createReportGroup,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['reportGroups'] })
		}
	})
}

export function useDeleteReportGroup() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteReportGroup,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['reportGroups'] })
		}
	})
}

export function useUpdateReportGroup() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateReportGroup,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['reportGroups'] })
		}
	})
}

export function useReportTypes() {
	return useQuery({
		queryKey: ['reportTypes'],
		queryFn: () => getReportTypes()
	})
}

export function useReportType(id?: number) {
	return useQuery({
		queryKey: ['reportTypes', id],
		queryFn: () => getReportType(id!),
		enabled: !!id,
	})
}

export function useDeleteReportType() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteReportType,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['reportTypes'] })
		}
	})
}

export function useCreateReportType() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createReportType,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['reportTypes'] })
		}
	})
}

export function useUpdateReportType() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateReportType,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['reportTypes'] })
		}
	})
}

export function useRoles() {
	return useQuery({
		queryKey: ['roles'],
		queryFn: () => getRoles()
	})
}

export function useRolesLab() {
	return useQuery({
		queryKey: ['roles'],
		queryFn: () => getRoles(),
		select: (data) => data.filter((r) => r.type === "Lab")
	})
}

export function useRolesFacility() {
	return useQuery({
		queryKey: ['roles'],
		queryFn: () => getRoles(),
		select: (data) => data.filter((r) => r.type === "Facility")
	})
}

export function useRole(id?: number) {
	return useQuery({
		queryKey: ['roles', id],
		queryFn: () => getRole(id!),
		enabled: !!id,
	})
}

export function useDeleteRole() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteRole,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['roles'] })
		}
	})
}

export function useCreateRole() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createRole,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['roles'] })
		}
	})
}

export function useUpdateRole() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateRole,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['roles'] })
		}
	})
}

export function useSalesReps() {
	return useQuery({
		queryKey: ['salesreps'],
		queryFn: () => getSalesReps()
	})
}

export function useSalesRep(id?: number) {
	return useQuery({
		queryKey: ['salesrep', id],
		queryFn: () => getSalesRep(id!),
		enabled: !!id
	})
}


export function useCreateSalesRep() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createSalesRep,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['salesreps'] })
		}
	})
}

export function useUpdateSalesRep() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateSalesRep,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['salesreps'] })
		}
	})
}

export function useSpecimenTypes() {
	return useQuery({
		queryKey: ['specimenTypes'],
		queryFn: () => getSpecimenTypes()
	})
}

export function useSpecimenType(id?: number) {
	return useQuery({
		queryKey: ['specimenTypes', id],
		queryFn: () => getSpecimenType(id!),
		enabled: !!id,
	})
}

export function useDeleteSpecimenType() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteSpecimenType,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['specimenTypes'] })
		}
	})
}

export function useCreateSpecimenType() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createSpecimenType,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['specimenTypes'] })
		}
	})
}

export function useUpdateSpecimenType() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateSpecimenType,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['specimenTypes'] })
		}
	})
}

export function useSubTests() {
	return useQuery({
		queryKey: ['tests'],
		queryFn: () => getTests(),
		select: (data) => data.filter((t) => !t.is_panel)
	})
}

export function useTests() {
	return useQuery({
		queryKey: ['tests'],
		queryFn: () => getTests()
	})
}

export function useTest(id?: number) {
	return useQuery({
		queryKey: ['tests', id],
		queryFn: () => getTest(id!),
		enabled: !!id,
	})
}

export function useTestSubTests(id?: number) {
	return useQuery({
		queryKey: ['tests_subtests', id],
		queryFn: () => getTestSubTests(id!),
		enabled: !!id,
	})
}

export function useDeleteTest() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteTest,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['tests'] })
		}
	})
}

export function useCreateTest() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createTest,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['tests'] })
		}
	})
}

export function useUpdateTest() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateTest,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['tests'] })
		}
	})
}

export function useUsers() {
	return useQuery({
		queryKey: ['users'],
		queryFn: () => getUsers()
	})
}

export function useUsersTableView() {
	return useQuery({
		queryKey: ['users-table-view'],
		queryFn: () => getUsersTableView()
	})
}

export function useUser(id?: number) {
	return useQuery({
		queryKey: ['users', id],
		queryFn: () => getUser(id!),
		enabled: !!id,
	})
}
export function useActivateUser() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: activateUser,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['users', 'users-table-view'] })
		}
	})
}

export function useDeactivateUser() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deactivateUser,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['users', 'users-table-view'] })
		}
	})
}


export function useDeleteUser() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: deleteUser,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['users', 'users-table-view'] })
		}
	})
}

export function useCreateUser() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: createUser,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['users', 'users-table-view'] })
		}
	})
}

export function useSendUserActivationEmail() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: sendUserActivationEmail,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['users-table-view'] })
		}
	})

}

export function useUpdateUser() {
	const queryClient = useQueryClient()
	return useMutation({
		mutationFn: updateUser,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['users'] })
		}
	})
}
