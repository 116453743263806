
import { ModeEdit, Pageview } from '@mui/icons-material';
import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableRow, useMediaQuery } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { Order } from '../../constants/go_types';
import { usePermission } from '../../hooks/usePermission';
import { usePatient } from '../../services/queries';
import { formatAddress, FormatDate, FormatDateUTC } from '../../services/util';
import Loading from '../Loading';
import { RowStack } from '../Stacks';
import StyledPaper from '../StyledPaper';
import mdTheme from '../Theme';
import Title from '../Title';

export default function OrderPatientV2({ order }: { order: Order }) {
    const { data: patient, isLoading } = usePatient(order.patient_id)
    const navigate = useNavigate();
    const canEdit = usePermission("patients:write")
    const isMobile = useMediaQuery(mdTheme.breakpoints.down("lg"));

    const data = [
        {
            key: 'ID',
            value: patient?.id
        },
        {
            key: 'EMR ID',
            value: patient?.emrid
        },
        {
            key: 'Name',
            value: patient?.last_name + ", " + patient?.first_name
        },
        {
            key: 'Date of Birth',
            value: FormatDateUTC(patient?.dob)
        },
        {
            key: 'Sex',
            value: patient?.sex
        },
        {
            key: 'Address',
            value: formatAddress(patient?.address_1, patient?.address_2, patient?.city, patient?.state, patient?.zip_code)
        },
        {
            key: 'Ethnicity',
            value: patient?.ethnicity
        },
    ]

    if (isLoading) {
        return (
            <StyledPaper>
                <Loading />
            </StyledPaper>
        )
    }

    return (
        <StyledPaper>
            <RowStack alignItems={isMobile ? "center" : "center"} justifyContent="space-between">
                <Title>Patient</Title>
                <RowStack direction="row" divider={<Divider orientation='vertical' />}>
                    <Button onClick={() => navigate("/patients/" + order.patient_id)} startIcon={<Pageview />}>
                        View
                    </Button>
                    <Button disabled={!canEdit} onClick={() => navigate("/patients/" + order.patient_id + "/edit")}
                        startIcon={<ModeEdit />}
                    >
                        Edit
                    </Button>
                </RowStack>
            </RowStack>
            <TableContainer>
                <Table size="small">
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.key}>
                                <TableCell
                                    component="th" // Semantically a header
                                    scope="row"
                                    sx={{
                                        fontWeight: 'bold', // Make it look like a header
                                        width: '35%', // Control column width
                                        // bgcolor: '#f5f5f5', // Light background for contrast
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    }}
                                >
                                    {row.key}
                                </TableCell>
                                <TableCell>
                                    {row.value}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </StyledPaper>


    )
}
