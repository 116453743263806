import { Stack } from "@mui/system";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Box from "@mui/system/Box";
import TextField from "@mui/material/TextField";
import { Alert, Autocomplete, Button, InputLabel } from "@mui/material";
import React, { useState, useEffect } from "react";
import { DataGridPro, GridToolbar, GridRowSelectionModel, GridCallbackDetails, GridRowId } from "@mui/x-data-grid-pro";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";
import { DiagnosisCode, DrugClass, Medication, MedicationBrand } from "../constants/types";
import { apiFetch } from "../services/fetch";
import { CustomCheckboxField } from "./CustomTextField";
import DiagnosisCodeSearch from "./DiagnosisCodeSelect";
import { usePermission } from "../hooks/usePermission";

export default function MedicationsPanel() {
    const initValues = {
        Active: true,
        Name: "",
        RxNormID: "",
        DrugClasses: [],
        AdverseDrugClasses: [],
        DiagnosisCodes: [],
        Brands: [],
    };

    const [id, setId] = useState<GridRowId[]>();
    const [medications, setMedications] = useState<Medication[]>([]);
    const [drugClasses, setDrugClasses] = useState<DrugClass[]>([]);
    const [brands, setBrands] = useState<MedicationBrand[]>([]);
    const [initialValues, setInitialValues] = useState(initValues);
    const [create, setCreate] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const canEdit = usePermission("setup:write")

    useEffect(() => {
        if (!id || id.length < 1) {
            return
        }

        const init = async () => {
            try {
                const response = await apiFetch(`/medications/${id[0]}`)
                setInitialValues(response)
            } catch (e) {
                setError("Failed to load medication")
            }
        }

        init()
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [medicationsResponse, drugClassesResponse, brandsResponse] = await Promise.all([
                    apiFetch(`/medications`),
                    apiFetch(`/drugclasses`),
                    apiFetch(`/medications/brands`)
                ]);
                setMedications(medicationsResponse);
                setDrugClasses(drugClassesResponse);
                setBrands(brandsResponse);
            } catch (e) {
                setError("Failed to load data");
            }
        };

        fetchData();
    }, [refresh]);


    const handleSelected = (rowSelectionModel: GridRowSelectionModel, _: GridCallbackDetails<any>) => {
        setId(rowSelectionModel)
    }

    const validationSchema = Yup.object().shape({
        Name: Yup.string().required("Name is required"),
    });

    const handleSubmit = async (values: any) => {
        console.log(values);
        let url = ""
        if (id && id.length > 0) {
            url = `/medications`
        } else {
            url = `/medications`
        }

        try {
            await apiFetch(url, 'POST', values)
            setError(null);
            setRefresh((prevValue) => !prevValue);
            setCreate(false);
        } catch (e) {
            console.log(e)
            setError("Failed to save medication.")
        }
    };

    const myForm = () => {
        return (
            <>
                <Box>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({
                            values,
                            setFieldValue,
                            handleChange,
                            handleBlur,
                            errors,
                            touched,
                        }) => <Form>
                                <Stack direction="column" spacing={2}>
                                    <InputLabel>General</InputLabel>
                                    <Stack
                                        direction={{ sm: "column", md: "row" }}
                                        spacing={{ xs: 1, sm: 2, md: 4 }}
                                    >
                                        <CustomCheckboxField
                                            name="Active"
                                            loabel="Active" />
                                        <TextField
                                            name="Name"
                                            value={values.Name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.Name &&
                                                Boolean(errors.Name)
                                            }
                                            label="Name"
                                            size="small"
                                            helperText={
                                                touched.Name && errors.Name
                                            }
                                            required
                                        />
                                        <TextField
                                            name="RxNormID"
                                            value={values.RxNormID}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={
                                                touched.RxNormID &&
                                                Boolean(errors.RxNormID)
                                            }
                                            label="RxNorm ID"
                                            size="small"
                                            helperText={
                                                touched.RxNormID && errors.RxNormID
                                            }
                                        />
                                        <Autocomplete
                                            multiple
                                            options={drugClasses}
                                            sx={{
                                                minWidth: "223px",
                                                maxWidth: "100%",
                                            }}
                                            getOptionLabel={(option) => option.Name}
                                            isOptionEqualToValue={(option, value) =>
                                                option.ID === value.ID
                                            }
                                            value={values.DrugClasses}
                                            onChange={(_, value) => {
                                                setFieldValue("DrugClasses", value);
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        label="Drug Classes"
                                                    />
                                                );
                                            }}
                                        />
                                        <Autocomplete
                                            multiple
                                            options={drugClasses}
                                            sx={{
                                                minWidth: "223px",
                                                maxWidth: "100%",
                                            }}
                                            getOptionLabel={(option) => option.Name}
                                            isOptionEqualToValue={(option, value) =>
                                                option.ID === value.ID
                                            }
                                            value={values.AdverseDrugClasses}
                                            onChange={(_, value) => {
                                                setFieldValue("AdverseDrugClasses", value);
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        label="Adverse Drug Classes"
                                                    />
                                                );
                                            }}
                                        />
                                        <Autocomplete
                                            multiple
                                            options={brands}
                                            sx={{
                                                minWidth: "223px",
                                                maxWidth: "100%",
                                            }}
                                            getOptionLabel={(option) => option.Name}
                                            isOptionEqualToValue={(option, value) =>
                                                option.ID === value.ID
                                            }
                                            value={values.Brands}
                                            onChange={(_, value) => {
                                                setFieldValue("Brands", value);
                                            }}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        size="small"
                                                        label="Brands"
                                                    />
                                                );
                                            }}
                                        />
                                    </Stack>
                                    <InputLabel>Associated Diagnosis Codes</InputLabel>
                                    <DiagnosisCodeSearch
                                        diagnosisCodes={values.DiagnosisCodes}
                                        setDiagnosis={(_: any, v: any) => setFieldValue("DiagnosisCodes", v)}
                                        hideText={true}
                                        notRequired={true} />
                                    {error !== null ? (
                                        <Alert severity="error">{error}</Alert>
                                    ) : null}
                                    <Button type="submit">
                                        {id ? "Save" : "Create"}
                                    </Button>
                                </Stack>
                            </Form>}
                    </Formik>
                </Box>
            </>
        );
    };

    const columns = [
        { field: "Active", headerName: "Active", width: 75, type: "boolean" },
        { field: "Name", headerName: "Name", width: 175 },
        { field: "RxNormID", headerName: "RxNorm ID", width: 175 },
        { field: "Drug Classes", headerName: "Drug Classes", width: 175, valueGetter: (params: any) => params.row.DrugClasses.map((dc: DrugClass) => dc.Name) },
        { field: "Adverse Drug Classes", headerName: "Adverse Drug Classes", width: 175, valueGetter: (params: any) => params.row.AdverseDrugClasses.map((dc: DrugClass) => dc.Name) },
        { field: "Diagnosis Codes", headerName: "Diagnosis Codes", width: 175, valueGetter: (params: any) => params.row.DiagnosisCodes.map((dc: DiagnosisCode) => dc.Code) },
    ];

    if (create) {
        return myForm();
    }

    return <>
        <TableFrame>
            <DataTableContainer>
                <DataGridPro
                    getRowId={(row) => String(row.ID)}
                    density="compact"
                    sx={{
                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                        {
                            display: "none",
                        },
                    }}
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    keepNonExistentRowsSelected
                    onRowSelectionModelChange={handleSelected}
                    rowSelectionModel={id}
                    rows={medications}
                    columns={columns}
                />
            </DataTableContainer>
            <ButtonTray>
                <Button
                    variant="contained"
                    disabled={!canEdit}
                    color="success"
                    onClick={() => {
                        setId(undefined);
                        setInitialValues(initValues);
                        setCreate(true);
                    }}
                >
                    Create
                </Button>
                <Button
                    disabled={!canEdit || !id}
                    variant="contained"
                    color="primary"
                    onClick={() => setCreate(true)}
                >
                    Edit
                </Button>
            </ButtonTray>
        </TableFrame>
    </>;
}
