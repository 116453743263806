import * as Yup from 'yup'
import { ReflexMethodAbnormal, Test2Test } from '../constants/go_types';

export const reflexInitialValues = {
	"is_active": true,
	"reflex_type": Test2Test,
	"auto_order": false,
	"force_order": false,
	"description": "",
	"dest_test_id": undefined,
	"facilities": [],
	"source_test_id": undefined,
	"source_subtests": [],
	"dest_profile_id": undefined,
	"reflex_range_type": ReflexMethodAbnormal
}

const reflexValidation = Yup.object().shape({
	"id": Yup.number().nullable(),
	"is_active": Yup.bool(),
	"auto_order": Yup.bool(),
	"force_order": Yup.bool(),
	"description": Yup.string(),
	"dest_test_id": Yup.number(),
	"facilities": Yup.array().of(Yup.number()).nullable(),
	"source_test_id": Yup.number(),
})

export default reflexValidation;
