import { Box, InputLabel } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useFormikContext } from "formik";
import React from "react";
import { CustomCheckboxField, CustomListFieldV2, CustomTextField } from "../components/CustomTextField";
import CollectorAutoComplete from "../components/fields/Collectors";
import GenericTableSelector from "../components/fields/GenericTableSelector";
import LaboratoriesAutoComplete from "../components/fields/Laboratories";
import ProviderAutoComplete from "../components/fields/Providers";
import PanelForm from "../components/PanelForm";
import { ColumnStack, RowStack } from "../components/Stacks";
import { UserCreateRequest } from "../constants/go_types";
import { useCreateUser, useUser, useUpdateUser, useFacilities, useRolesLab, useRolesFacility, useOrganizations } from "../services/queries";
import { userInitialValues, userValidation } from "../validations/users";

type UserPanelFormProps = {
	onBack: () => void;
	onSave: () => void;
	id?: number;
}

export function UserPanelForm({ id, onBack, onSave }: UserPanelFormProps) {
	const user = useUser(id)

	return (
		<PanelForm
			validationSchema={userValidation}
			form={<UserForm />}
			createMutation={useCreateUser()}
			updateMutation={useUpdateUser()}
			initialValues={user.isSuccess ? user.data : userInitialValues}
			onBack={onBack}
			onSave={onSave}
		/>
	)
}

const roleColumns: GridColDef[] = [
	{ field: "id", headerName: "ID", width: 50 },
	{ field: "type", headerName: "Type", width: 100 },
	{ field: "name", headerName: "Name", width: 250 },
]

const facilityColumns: GridColDef[] = [
	{ field: "id", headerName: "ID", width: 150 },
	{ field: "name", headerName: "Name", width: 350 },
]

const orgColumns: GridColDef[] = [
	{ field: "id", headerName: "ID", width: 150 },
	{ field: "name", headerName: "Name", width: 350 },
]

export function UserForm() {
	const { values, errors, touched, setFieldValue } = useFormikContext<UserCreateRequest>()
	const facilities = useFacilities()
	const orgs = useOrganizations()
	const labRoles = useRolesLab()
	const facilityRoles = useRolesFacility()
	const roles = React.useMemo(() => values.type === "Lab" ? labRoles : facilityRoles,
		[values.type, labRoles, facilityRoles])
	console.log('errors', errors)
	console.log('values', values)

	return (
		<ColumnStack>
			<RowStack>
				<CustomTextField
					required
					name="first_name"
					label="First Name"
					sx={{ width: "100%" }} />
				<CustomTextField
					required
					name="last_name"
					label="Last Name"
					sx={{ width: "100%" }} />
				<CustomTextField
					required
					name="email"
					label="Email"
					type="email"
					sx={{ width: "100%" }} />
				<CustomListFieldV2 required
					name="type"
					label="Type"
					options={["Lab", "Facility"]}
					onChange={(e) => {
						setFieldValue("type", e.target.value);
						setFieldValue("roles", []);
						setFieldValue("organizations", []);
						setFieldValue("facilities", []);
					}}
				/>
			</RowStack>
			<RowStack>
				<CustomCheckboxField
					name="create_collector"
					label="Create Collector"
					disabled={!!values.collector_id || !!values.id}
				/>
				<CustomCheckboxField
					name="create_contact"
					label="Create Contact"
					disabled={!!values.id}
				/>
			</RowStack>

			<RowStack>
				<LaboratoriesAutoComplete
					sx={{ width: "100%" }}
					name="laboratory_id"
					label="Laboratory"
					field="id"
					labelField="name"
					required />
				<ProviderAutoComplete
					sx={{ width: "100%" }}
					name="provider_id"
					label="Link to Provider"
					field="id"
					labelField="first_name"
				/>
				<CollectorAutoComplete
					sx={{ width: "100%" }}
					name="collector_id"
					label="Link to Collector"
					field="id"
					labelField="first_name"
					disabled={values.create_collector}
				/>

			</RowStack>
			<InputLabel>Roles</InputLabel>
			<Box sx={{ height: "400px" }}>
				<GenericTableSelector name="roles" columns={roleColumns} queryResult={roles} />
			</Box>
			{errors.roles && touched.roles && <InputLabel sx={{ color: "red" }}>{errors.roles}</InputLabel>}
			{values.type === "Facility" &&
				<>
					<InputLabel>Organizations</InputLabel>
					<Box height="500px">
						<GenericTableSelector name="organizations" columns={orgColumns} queryResult={orgs} />
					</Box>
					<InputLabel>Facilities</InputLabel>
					<Box height="500px">
						<GenericTableSelector name="facilities" columns={facilityColumns} queryResult={facilities} />
					</Box>
				</>
			}
		</ColumnStack>
	)
}
