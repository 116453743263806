import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import mdTheme from "../components/Theme";

const GLOBAL_OFFSET = 230;

type DataTableContainerProps = {
    children: React.ReactNode;
}

// NEED TO DEPRECATE. FRAMEWORK NEEDS TO BE REWORKED
function DataTableContainer({ children, ...props }: DataTableContainerProps) {
    const isMobile = useMediaQuery(mdTheme.breakpoints.down("sm"));
    let offset = 53;

    if (isMobile) {
        offset = 0
    }

    return (
        <Box height={`calc(100vh - ${GLOBAL_OFFSET + offset}px)`} {...props}>
            {children}
        </Box>
    )

}

export default DataTableContainer;
