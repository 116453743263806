import ProfilesTable from "./ProfilesTable";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";
import { useState } from "react";
import ProfileForm from "./ProfileEdit";
import TableFrame from "./TableFrame";
import { DataTableContainer } from "./TableContainer";
import ButtonTray from "./ButtonTray";
import { apiFetch } from "../services/fetch";
import ErrorAlert from "./ErrorAlert";
import { usePermission } from "../hooks/usePermission";

export default function ProfilesPanel({ }) {
    const [view, setView] = useState("table");
    const [profile, setProfile] = useState();
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [profileDetails, setProfileDetails] = useState({});
    const canEdit = usePermission("setup:write")
    console.log(profile)

    const deleteProfile = async () => {
        try {
            await apiFetch(`/profiles/${profile[0]}`, "DELETE");
            setRefresh(!refresh);
            setError(null);
        } catch (e) {
            setError("Failed to delete profile.");
        }
    }

    if (view === "table") {
        return (
            <>
                <TableFrame>
                    <DataTableContainer>
                        <ProfilesTable
                            profiles={profile}
                            setProfiles={setProfile}
                            checkboxes={false}
                            refresh={refresh}
                        />
                    </DataTableContainer>
                    <ButtonTray>
                        <Button
                            disabled={!canEdit}
                            variant="contained"
                            color="success"
                            onClick={() => {
                                setProfile(undefined);
                                setProfileDetails({});
                                setView("create");
                            }}
                        >
                            Create
                        </Button>
                        <Button
                            disabled={!canEdit || profile === undefined}
                            variant="contained"
                            color="primary"
                            onClick={() => setView("edit")}
                        >
                            Edit
                        </Button>
                        <Button
                            disabled={!canEdit || profile === undefined}
                            variant="contained"
                            color="error"
                            onClick={deleteProfile}
                        >
                            Delete
                        </Button>
                    </ButtonTray>
                    <ErrorAlert error={error} />
                </TableFrame>
            </>
        );
    } else if (view === "create") {
        const saved = () => {
            setView("table");
            setRefresh(!refresh);
        };
        return (
            <ProfileForm
                profileDetails={profileDetails}
                setProfileDetails={setProfileDetails}
                saved={saved}
            />
        );
    } else if (view === "edit") {
        const saved = () => {
            setView("table");
            setRefresh(!refresh);
        };
        return (
            <ProfileForm
                profileDetails={profileDetails}
                setProfileDetails={setProfileDetails}
                saved={saved}
                profileID={profile[0]}
            />
        );
    }
}
