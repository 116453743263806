import { InputLabel } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { CustomListFieldV2, CustomTextField } from "../components/CustomTextField";
import GenericTableSelector from "../components/fields/GenericTableSelector";
import PanelForm from "../components/PanelForm";
import { ColumnStack, RowStack } from "../components/Stacks";
import { Role } from "../constants/go_types";
import { useCreateRole, usePermissions, useRole, useUpdateRole } from "../services/queries";
import { roleInitialValues, roleValidation } from "../validations/roles";

type RolePanelFormProps = {
	onBack: () => void;
	onSave: () => void;
	id?: number;
}

export function RolePanelForm({ id, onBack, onSave }: RolePanelFormProps) {
	const role = useRole(id)

	return (
		<PanelForm
			validationSchema={roleValidation}
			form={<RoleForm />}
			createMutation={useCreateRole()}
			updateMutation={useUpdateRole()}
			initialValues={role.isSuccess ? role.data : roleInitialValues}
			onBack={onBack}
			onSave={onSave}
		/>
	)
}

const permissionColumns: GridColDef[] = [
	{ field: "resource", headerName: "Resource", width: 150 },
	{ field: "action", headerName: "Action", width: 150 },
	{ field: "name", headerName: "Name", width: 150 },
]

export function RoleForm() {
	const { values, setFieldValue } = useFormikContext<Role>()

	return (
		<ColumnStack>
			<RowStack>
				<CustomTextField required name="name" label="Name" sx={{ width: "100%" }} />
				<CustomListFieldV2 required name="type" label="Type" options={["Lab", "Facility"]}
					onChange={(e) => { setFieldValue("type", e.target.value); setFieldValue("permissions", []) }}
				/>
			</RowStack>
			<InputLabel>Permissions</InputLabel>
			<GenericTableSelector name="permissions" columns={permissionColumns} queryResult={usePermissions(values.type !== "Lab")} />
		</ColumnStack>
	)
}
