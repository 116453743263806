import React from "react";
import Box from "@mui/system/Box";
import { Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel, { a11yProps } from "../components/TabPanel";
import InstrumentInterfacesTable from "../tables/InstrumentInterfacesTable";
import NotificationsTable from "../tables/NotificationsTable";
import EligibilityTable from "../tables/EligibilityTable";
import BillingOutputsTable from "../tables/BillingOutputTable";
import Framework from "../components/Framework";
import { useTabState } from "../components/useTabState";
import { DataTableContainerNoButton } from "../components/TableContainer";
import CoriellPage from "./Coriell";
import EMROrders from "../tables/EMROrders";
import EMRResults from "../tables/EMRResults";
import MappingTabs from "./MappingTabs";

export default function Interfaces() {
    const [value, setValue] = useTabState(0);

    const tabs = [
        { label: "Instruments", component: <InstrumentInterfacesTable /> },
        { label: "Billing", component: <BillingOutputsTable /> },
        { label: "Eligibility", component: <EligibilityTable /> },
        { label: "Orders", component: <EMROrders /> },
        { label: "Results", component: <EMRResults /> },
        { label: "Mappings", component: <MappingTabs /> },
        { label: "Notifications", component: <NotificationsTable /> },
        { label: "Coriell", component: <CoriellPage /> }
    ]

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    return (
        <Framework table>
            <Box sx={{ pl: 2, pr: 2, pt: 1 }}>
                <Typography variant="h5">Interfaces</Typography>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                    >
                        {tabs.map((tab, index) => (
                            <Tab label={tab.label} {...a11yProps(index)} />
                        )
                        )}
                    </Tabs>
                </Box>
            </Box>
            {tabs.map((tab, index) => (
                <TabPanel value={value} index={index}
                >
                    <DataTableContainerNoButton>
                        {tab.component}
                    </DataTableContainerNoButton>
                </TabPanel>
            ))}
        </Framework>
    );
}
