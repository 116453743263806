
import { GridColDef } from "@mui/x-data-grid-pro";
import { UseQueryResult } from "@tanstack/react-query";
import React from "react";
import { useTests } from "../../services/queries";
import ReactQueryTableSelector, { ReactQueryTableSelectorProps } from "../ReactQueryTableSelector";

type TestTableSelectorProps = Omit<ReactQueryTableSelectorProps, "query" | "columns"> & {
    columns?: GridColDef[]
    query?: UseQueryResult<any>
    name: string
}

const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 75 },
    { field: "code", headerName: "Code", width: 100 },
    { field: "name", headerName: "Name", width: 250 },
]

export default function TestTableSelector(props: TestTableSelectorProps) {
    const tests = useTests()
    return <ReactQueryTableSelector query={props.query ? props.query : tests} columns={props.columns ? props.columns : columns} name={props.name} />

}
